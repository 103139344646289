import API from '@/http/http-common'
import { IError, IParams, IUpdateConfirmationTracking } from '@/models'
import { ServiceResponse } from '@/services/service-response'
import { getAttachmentErrorMessage } from '@/utils/global-setting'
import { customizeParams } from '@/utils/service-helper'
import { AxiosRequestConfig } from 'axios'

export class ShipmentTrackingService {
  async getShipmentTrackingLegs<T>(params: IParams = {}): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        '/shipment-tracking-route-leg',
        queryParams
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async getConfirmationShipmentTrackingDetail<T>(
    id: string,
    token: string
  ): ServiceResponse<T> {
    const config: AxiosRequestConfig = {}
    config.headers = {
      'X-ConfirmationToken': token
    }
    try {
      const res = await API.externalInstance.get(
        `/shipment-tracking-route-leg/${id}/pickup-delivery-confirm-shipment`,
        config
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError
      return { status: false, data: {} as T, errorMsg: error.data.title }
    }
  }

  async updateShipmentTracking<T>(
    id: string,
    routeLegConfirmation: IUpdateConfirmationTracking
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/shipment-tracking-route-leg/${id}/pickup-delivery-confirm-shipment`,
        routeLegConfirmation
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError
      return { status: false, data: {} as T, errorMsg: error.data.title }
    }
  }

  async addPickUpDeliveryAttachment<T>(
    id: string,
    pickupDeliveryAttachment: any
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/shipment-tracking-route-leg/${id}/pickup-delivery-attachment`,
        pickupDeliveryAttachment,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError
      return {
        status: false,
        data: {} as T,
        errorMsg: getAttachmentErrorMessage(error)
      }
    }
  }

  async getShipmentTracking<T>(params: IParams = {}): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        '/shipment-tracking-route',
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new ShipmentTrackingService()
