import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { IFreightModeSubType } from '@/models'
import freightModeService from '@/services/settings/freight-mode'

@Module({ dynamic: true, store, name: 'freightMode', namespaced: true })
class FreightMode extends VuexModule {
  private freightModeSubTypes: IFreightModeSubType[] = []

  @Mutation
  SET_SUBTYPE_IDS(ids: IFreightModeSubType[]) {
    this.freightModeSubTypes = ids
  }

  @Action
  public async getFreightModesAndSubTypes() {
    if (this.freightModeSubTypes.length === 0) {
      const {
        data,
        status
      } = await freightModeService.getAllFreightModeSubTypes<
        IFreightModeSubType[]
      >()
      if (status) {
        this.SET_SUBTYPE_IDS(data)
      }
    }
  }

  get getFreightModeSubTypes() {
    return this.freightModeSubTypes
  }
}

export const FreightModeModule = getModule(FreightMode)
