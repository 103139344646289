import { InquiryItem, IUnit, IContainerUnit } from '@/models'
import { ItemTypes } from '@/utils/constants'
import { uniqueId } from 'lodash'

import * as defaults from './constants'

export class ItemFactory {
  private itemType: ItemTypes
  private inquiryValidations: any = {}

  constructor(itemType: ItemTypes, inquiryValidations: any) {
    this.itemType = itemType
    this.inquiryValidations = { ...inquiryValidations }
  }

  public getItemBasedOnItemType() {
    return this.itemHandlersBasedOnType[this.itemType](this.inquiryValidations)
  }

  private itemHandlersBasedOnType = {
    [ItemTypes.NOT_SELECTED]: this.constructNotSelectedItem,
    [ItemTypes.BOX_CRATES]: this.constructBoxItem,
    [ItemTypes.EUR_PALLET]: this.constructEurPallet,
    [ItemTypes.EUR_2_PALLET]: this.constructEur2Pallet,
    [ItemTypes.OTHER_PALLET]: this.constructOtherPallet,
    [ItemTypes.FULL_CONTAINER]: this.constructContainer,
    [ItemTypes.ENVELOPE]: this.constructEnvelope
  }

  private constructNotSelectedItem(): InquiryItem {
    return {
      id: uniqueId(),
      itemType: ItemTypes.NOT_SELECTED,
      data: {} as IUnit | IContainerUnit
    }
  }

  private constructEnvelope(inquiryValidations: any): Partial<InquiryItem> {
    const envelope = inquiryValidations.envelope
    return {
      itemType: ItemTypes.ENVELOPE,
      data: defaults.getEnvelope(
        envelope.heightdefault,
        envelope.lengthdefault,
        envelope.widthdefault
      )
    }
  }

  private constructBoxItem(): Partial<InquiryItem> {
    return {
      itemType: ItemTypes.BOX_CRATES,
      data: {
        ...defaults.getDefaultBox()
      }
    }
  }

  private constructEurPallet(): Partial<InquiryItem> {
    return {
      itemType: ItemTypes.EUR_PALLET,
      data: defaults.getPallet(120, 80)
    }
  }

  private constructEur2Pallet(): Partial<InquiryItem> {
    return {
      itemType: ItemTypes.EUR_2_PALLET,
      data: defaults.getPallet(120, 100)
    }
  }

  private constructOtherPallet(): Partial<InquiryItem> {
    return {
      itemType: ItemTypes.OTHER_PALLET,
      data: defaults.getPallet()
    }
  }

  private constructContainer(): Partial<InquiryItem> {
    return {
      itemType: ItemTypes.FULL_CONTAINER,
      data: {
        quantity: 0,
        type: '',
        containerEdges: '',
        isOverweight: false
      }
    }
  }
}
