import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'template', namespaced: true })
class Template extends VuexModule {
  public showLoader = false

  @Mutation
  private SET_LOADER(flag: boolean) {
    this.showLoader = flag
  }

  @Action
  public toggleLoader(loader: boolean) {
    this.SET_LOADER(loader)
  }
}

export const TemplateModule = getModule(Template)
