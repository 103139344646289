<template>
  <div>
    <b-button id="copied-url-btn" variant="outline-dark" @click="getUrl">{{
      name
    }}</b-button>
    <b-popover target="copied-url-btn" :show.sync="show" variant="warning">
      Copied
    </b-popover>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class CopiedUrl extends Vue {
  @Prop({ required: true }) referenceId: any
  @Prop({ type: String, default: 'Copy tracking link' }) name!: string

  show = false

  getUrl(): void {
    this.show = true
    navigator.clipboard.writeText(
      process.env.NODE_ENV === 'production'
        ? `https://mydello.com/shipment-tracking/?tracking-code=${encodeURIComponent(
            this.referenceId
          )}`
        : `https://mydello.com/staging/shipment-tracker/?tracking-code=${encodeURIComponent(
            this.referenceId
          )}`
    )
    setTimeout(() => {
      this.show = false
    }, 3000)
  }
}
</script>
<style lang="scss" scoped></style>
