import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { EnumsService } from '@/services/settings/enums'
import { IEnum } from '@/models/settings'

@Module({ dynamic: true, store, name: 'enums', namespaced: true })
class Enums extends VuexModule {
  public enums: IEnum[] = []

  @Mutation
  private SET_ENUMS(enums: IEnum[]) {
    this.enums = enums
  }

  @Action
  async fetchEnums() {
    try {
      const { status, data } = await new EnumsService().getAllEnums<IEnum[]>()
      if (status) {
        this.SET_ENUMS(data)
      } else {
        this.SET_ENUMS([])
      }
    } catch (err) {
      console.log('Error: ', err)
    }
  }

  get getQuoteStatusEnums() {
    const quoteStatusEnum = this.enums.find(e => e.name === 'QuoteStatus')
    return quoteStatusEnum ? quoteStatusEnum.values : null
  }
}

export const EnumsModule = getModule(Enums)
