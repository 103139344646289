import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'
import { getAttachmentErrorMessage } from '@/utils/global-setting'

export class InquiryAttachmentService {
  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async getInquiryAttachments<T>(inquiryId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `inquiry/${inquiryId}/attachments`
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @param {FormData} attachment
   * @param {string} uploadType
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async uploadInquiryAttachment<T>(
    inquiryId: string,
    attachment: FormData,
    uploadType: string // post | put
  ): ServiceResponse<T> {
    const methods = {
      post: 'post',
      put: 'put'
    }
    try {
      const res = await API.externalInstance[methods[uploadType]](
        `inquiry/${inquiryId}/attachments`,
        attachment,
        methods[uploadType] === methods.post
          ? {
              headers: {
                'content-type': 'multipart/form-data'
              }
            }
          : {}
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as any
      return {
        status: false,
        data: {} as T,
        errorMsg: getAttachmentErrorMessage(error)
      }
    }
  }

  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @param {string} inquiryAttachmentId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async deleteInquiryAttachment<T>(
    inquiryId: string,
    inquiryAttachmentId: string
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.delete(
        `/inquiry/${inquiryId}/attachments/${inquiryAttachmentId}`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @param {string} inquiryAttachmentId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async getAttachmentDownloadLink<T>(
    inquiryId: string,
    inquiryAttachmentId: string
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/inquiry/${inquiryId}/attachments/${inquiryAttachmentId}/download-link`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}
export default new InquiryAttachmentService()
