import { RouteConfig } from 'vue-router/types/router'

export const shipmentDetailsRoute: Array<RouteConfig> = [
  {
    path: 'details',
    name: 'Details',
    component: () => import('../../views/shipment-detail/Details.vue')
  },
  {
    path: 'messages',
    name: 'Messages',
    component: () => import('../../views/shipment-detail/Messages.vue')
  },
  {
    path: 'attachments',
    name: 'Attachments',
    component: () => import('../../views/shipment-detail/Attachments.vue')
  },
  {
    path: 'quotes-invoices',
    name: 'Quotes Invoices',
    component: () => import('../../views/shipment-detail/QuotesInvoices.vue')
  }
]
