import { extend } from 'vee-validate'

export const validText = extend('valid-text', {
  message: field => `The ${field} field is invalid`,
  validate: (value: string) => {
    const isValueMatch = value.match(
      /^(?!.*\S+\.\S+)(?!.*<html>)(?!.*<\/html>)(?!.*<script>)(?!.*<\/script>).*$/gi
    )
    if (isValueMatch && isValueMatch.length > 0) {
      return true
    } else {
      return false
    }
  },
  params: ['data']
})
