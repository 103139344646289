import { IShipment } from '@/models'
import { AuthModule } from '@/store/modules/auth'
import { AccessibilityType } from '@/utils/constants'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export class ShipmentMixin extends Vue {
  isShipmentCompanyIsAGroupCompany = (shipment: IShipment) => {
    return shipment.vendorCompanyId &&
      AuthModule.user &&
      AuthModule.user.companies &&
      AuthModule.user.companies
        .filter(x => x.accessibilityType === AccessibilityType.Group)
        .findIndex(x => x.id === shipment.companyId) !== -1
      ? true
      : false
  }
}
