import { ICompany, IFilter, IParams } from '@/models'
import { ICompanyAddressBook } from '@/models/address-book'
import { AddressBookService } from '@/services/address-book/address-book'
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import { mapState } from 'vuex'

export interface ICompanyAddressBookDataTable {
  id: string
  isPinned: boolean
  companyId: string
  companyName: string
  companyCustomerReferenceId: string | null
  addressId: string
  addressCode: string | null
  address: string
  loadingNotes: string | null
  defaultContact: string
  contactEmail: string
  contactPhone: string
  numberOfOtherContacts: number
  customClass: string
  otherContacts: Array<ICompanyAddressBookAddressContactDataTable>
}

export interface ICompanyAddressBookAddressContactDataTable {
  id: string
  name: string
  email: string
  phone: string
  socialId: string
  edit: boolean
}

@Component({
  computed: {
    ...mapState('company', ['companies', 'userCompanyId'])
  }
})
export class AddressBookMixin extends Vue {
  companyAddressBookDataTable: Array<ICompanyAddressBookDataTable> = []
  userCompanyId!: string
  companies!: Array<ICompany>

  filter: Array<IFilter> = []
  sortBy!: string
  sortDesc!: boolean
  params: IParams = {} as IParams
  currentPage = 1
  perPage = 20
  totalRows = 0

  addressBookService = new AddressBookService()
  companyAddressBooks: Array<ICompanyAddressBook> = []

  public readonly getCustomClassWithWhiteBg = (
    value: string,
    key: string,
    item: ICompanyAddressBookDataTable
  ) => {
    return ['group-white-bg', item.customClass]
  }

  public readonly getCustomClassWithGrayBorderRight = (
    value: string,
    key: string,
    item: ICompanyAddressBookDataTable
  ) => {
    return ['border-right-gray', item.customClass]
  }

  public readonly getCustomClassWithGrayBorderLeft = (
    value: string,
    key: string,
    item: ICompanyAddressBookDataTable
  ) => {
    return ['border-left-gray', item.customClass]
  }

  public readonly getCustomClass = (
    value: string,
    key: string,
    item: ICompanyAddressBookDataTable
  ) => {
    return [item.customClass]
  }

  setParams(ctx: any) {
    this.params = {} as IParams
    const params: IParams = {
      filters: [
        {
          data: {
            key: 'companyId',
            value: this.userCompanyId
          }
        }
      ],
      page: ctx.currentPage,
      pageSize: ctx.perPage,
      sorts: this.sortBy ? [this.sortBy] : []
    }
    if (ctx.sortBy) {
      const sort = ctx.sortDesc ? `-${ctx.sortBy}` : ctx.sortBy
      params.sorts = []
      params.sorts.push(sort)
    }
    if (this.filter && this.filter.length) {
      this.filter.forEach(elem => {
        params.filters?.push(elem)
      })
    }
    this.params = params
  }

  private makeItRowGroup(attrName: string, id: string) {
    const htmlElements = document.querySelectorAll(`[${attrName}="${id}"]`)
    if (htmlElements && htmlElements.length > 1) {
      htmlElements[0].setAttribute('rowSpan', htmlElements.length.toString())
      ;(htmlElements[0] as HTMLElement).style['border-bottom'] =
        '2px solid #dee2e6'
      htmlElements.forEach((elem: any, ind: number) => {
        if (ind > 0) {
          htmlElements[ind].remove()
        }
      })
    }
  }

  async items(ctx) {
    //set parameters for query params
    this.setParams(ctx)
    this.companyAddressBookDataTable = []
    this.companyAddressBooks = []

    const {
      status,
      data,
      totalCount
    } = await this.addressBookService.getCompanyAddressBook<
      Array<ICompanyAddressBook>
    >(this.params)
    if (status) {
      this.totalRows = totalCount ? totalCount : 0
      this.companyAddressBooks = [...data]
      this.companyAddressBooks.forEach(companyAddressBook => {
        const companyAddressBookDataTable: ICompanyAddressBookDataTable = {} as ICompanyAddressBookDataTable
        companyAddressBookDataTable.id = companyAddressBook.id
          ? companyAddressBook.id
          : ''
        companyAddressBookDataTable.companyId = companyAddressBook.companyId
        companyAddressBookDataTable.isPinned = companyAddressBook.isPinned
        companyAddressBookDataTable.companyName = companyAddressBook.companyName
        companyAddressBookDataTable.companyCustomerReferenceId =
          companyAddressBook.companyCustomerReferenceId

        companyAddressBook.addresses.forEach((address, index, array) => {
          const totalAddress = array.length

          if (index === totalAddress - 1)
            companyAddressBookDataTable.customClass = 'border-bottom-gray'
          companyAddressBookDataTable.addressId = address.id
            ? address.id
            : uuidv4()
          companyAddressBookDataTable.address = address.formattedAddress
          companyAddressBookDataTable.addressCode = address.code
          companyAddressBookDataTable.loadingNotes = address.loadingNotes
          companyAddressBookDataTable.otherContacts = []
          const defaultContact = address.contacts.find(x => x.isDefault)
          if (defaultContact) {
            companyAddressBookDataTable.defaultContact = defaultContact.name
            companyAddressBookDataTable.contactPhone = defaultContact.phone
              ? defaultContact.phone
              : ''
            companyAddressBookDataTable.contactEmail = defaultContact.email
          }
          companyAddressBookDataTable.numberOfOtherContacts = 0
          address.contacts.forEach(x => {
            if (!x.isDefault) {
              companyAddressBookDataTable.numberOfOtherContacts++
              companyAddressBookDataTable.otherContacts.push({
                id: x.id,
                name: x.name,
                email: x.email,
                phone: x.phone ? x.phone : '',
                socialId: x.socialId ? x.socialId : '',
                edit: false
              })
            }
          })

          this.companyAddressBookDataTable.push({
            ...companyAddressBookDataTable
          })
        })
      })

      const searchValue = this.filter.find(
        x => x.data.key.toLowerCase() === 'search' && x.data.value
      )
      if (this.filter && this.filter.length > 0 && searchValue) {
        return this.searchForFlatData(searchValue)
      } else {
        return this.companyAddressBookDataTable
      }
    }
  }

  private searchForFlatData(searchValue: IFilter) {
    const regex = new RegExp(searchValue.data.value, 'gi')
    return this.companyAddressBookDataTable.filter(
      x =>
        x.companyName.match(regex) ||
        (x.companyCustomerReferenceId &&
          x.companyCustomerReferenceId.match(regex)) ||
        (x.addressCode && x.addressCode.match(regex)) ||
        x.address.match(regex) ||
        (x.loadingNotes && x.loadingNotes.match(regex)) ||
        (x.defaultContact && x.defaultContact.match(regex)) ||
        x.contactEmail.match(regex) ||
        x.contactPhone.match(regex)
    )
  }

  @Watch('companyAddressBookDataTable') companyAddressBookDataTableChanged(
    companyAddressBookDataTable: Array<ICompanyAddressBookDataTable>
  ) {
    if (companyAddressBookDataTable && companyAddressBookDataTable.length > 0) {
      setTimeout(() => {
        companyAddressBookDataTable
          .reduce((acc: Array<string>, currentValue) => {
            if (!acc.includes(currentValue.id)) {
              acc.push(currentValue.id)
            }
            return acc
          }, [])
          .forEach(e => {
            this.makeItRowGroup('aria-for-pin', e)
            this.makeItRowGroup('aria-for-company-name', e)
            this.makeItRowGroup('aria-for-reference', e)
            this.makeItRowGroup('aria-for-edit-delete', e)
          })
      })
    }
  }
}
