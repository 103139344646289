import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'

export class QuoteAttachmentService {
  /**
   * get all attachments of a specific quote
   * @param {string} quoteId
   */
  async getQuoteAttachment<T>(quoteId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/quote/${quoteId}/attachments`
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   * fetch the download link object for attachment
   * @param {string} attachmentId
   */
  async getQuotePdfDownloadLink<T>(attachmentId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/quote-attachment/${attachmentId}/get-download-link`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
  /**
   * fetch the download link object for attachment
   * @param {string} attachmentId
   */
  async getQuoteAttachmentDownloadLink<T>(
    attachmentId: string
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/quote-attachment/${attachmentId}/get-download-link`
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new QuoteAttachmentService()
