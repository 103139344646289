<template>
  <div>
    <a
      class="side_menu_toogle"
      :class="{ active: active }"
      @click="sliderToggle"
      href="javascript:;"
    >
      <span></span>
    </a>
    <div class="mobile_menu" :style="{ display: active ? 'block' : '' }">
      <img src="../../assets/images/logo-white.svg" class="mobile_full_logo" />
      <app-nav @onRouteChange="routeChange"></app-nav>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Nav from './Nav.vue'

@Component({
  components: {
    appNav: Nav
  }
})
export default class MobileNav extends Vue {
  private active = false

  sliderToggle() {
    this.active = !this.active
    this.toggleBodyClass()
  }

  private toggleBodyClass() {
    const body = document.querySelector('body')
    const html = document.querySelector('html')
    if (this.active) {
      body?.classList.add('overflow-hidden')
      html?.classList.add('overflow-hidden')
    } else {
      body?.classList.remove('overflow-hidden')
      html?.classList.remove('overflow-hidden')
    }
  }

  routeChange() {
    this.active = false
    this.toggleBodyClass()
  }
}
</script>
