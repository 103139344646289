import API from '@/http/http-common'
import { IParams } from '@/models'
import { ServiceResponse } from '@/services/service-response'
import { customizeParams } from '@/utils/service-helper'
export class FreightModeService {
  async getFreightModes<T>(
    params = { pageSize: 1000 } as IParams
  ): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        '/settings/freightmode',
        queryParams
      )
      return {
        status: true,
        data: res.data,
        totalCount: res.headers['x-total-count']
          ? res.headers['x-total-count']
          : ''
      }
    } catch (e) {
      return { status: false, data: {} as T, totalCount: 0 }
    }
  }

  async getFreightModeSubsType<T>(freightModeId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `settings/freightmode/${freightModeId}/subtypes`
      )
      return {
        status: true,
        data: res.data,
        totalCount: res.headers['x-total-count']
          ? res.headers['x-total-count']
          : ''
      }
    } catch (e) {
      return { status: false, data: {} as T, totalCount: 0 }
    }
  }

  async getAllFreightModeSubTypes<T>(
    params = { pageSize: 1000 } as IParams
  ): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        `settings/freightmode-subtype`,
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T, totalCount: 0 }
    }
  }
}

export default new FreightModeService()
