import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { IContact, IParams } from '@/models'
import { ContactService } from '@/services/company/contact'

@Module({ dynamic: true, store, name: 'companyContact', namespaced: true })
class CompanyContact extends VuexModule {
  private companyContacts: Array<IContact> = []
  private contactService = new ContactService()

  @Mutation
  SET_COMPANY_CONTACT(companyContacts: Array<IContact>) {
    this.companyContacts = companyContacts
  }

  @Action
  public async getCompanyContact(params: IParams = {}) {
    const { data, status } = await this.contactService.getCompanyContacts<
      Array<IContact>
    >(params)
    if (status) {
      this.SET_COMPANY_CONTACT(data)
    }
  }

  get getCompanyContacts() {
    return this.companyContacts
  }
}

export const CompanyContactModule = getModule(CompanyContact)
