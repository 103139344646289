import API from '@/http/http-common'
import { ServiceResponse } from '../service-response'

export class ValidationsService {
  async getInquiryValidationRules<T>(): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        '/settings/common-configuration?filters=category==InquiryExternal&pagesize=2000'
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new ValidationsService()
