import API from '@/http/http-common'
import { customizeParams } from '@/utils/service-helper'
import { IParams } from '@/models/params'
import { ICompanyContactCreateUser, IContact } from '@/models'
import { ServiceResponse } from '@/services/service-response'
import { Operation } from 'rfc6902'
import { IError } from '@/models/error'

export class ContactService {
  async getCompanyContacts<T>(params: IParams): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        `/company-contact`,
        queryParams
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async getCompanyContact<T>(contactId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/company-contact/${contactId}`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async addCompanyContact<T>(companyContact: IContact): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        '/company-contact',
        companyContact
      )
      return {
        status: true,
        data: res.data
      }
    } catch (err) {
      const error = err as IError
      return {
        status: false,
        data: {} as T,
        totalCount: 0,
        errorMsg: error.data.title
      }
    }
  }

  async updateCompanyContacts<T>(obj: IContact): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(`/company-contact`, obj)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async patchCompanyContact<T>(
    id: string | undefined,
    patch: Operation[]
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.patch(
        `company-contact/${id}`,
        patch
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async createCompanyContactUser<T>(
    companyContactId: string,
    obj: ICompanyContactCreateUser
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/company-contact/${companyContactId}/create-user`,
        obj
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError
      return { status: false, data: {} as T, errorMsg: error.data.title }
    }
  }

  async sendActivationLink<T>(companyContactId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/company-contact/${companyContactId}/send-user-activationlink`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new ContactService()
