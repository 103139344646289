<template>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="pageTitle font-light text-gray-darker">My inquiries</h1>
    <b-button class="greenBtn" to="/new-inquiry">
      <b-icon-plus small></b-icon-plus>
      New inquiry
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'InquiryHeader'
}
</script>

<style></style>
