import API from '@/http/http-common'
import { IParams } from '@/models'
import { ServiceResponse } from '@/services/service-response'
import { customizeParams } from '@/utils/service-helper'

export class IncotermService {
  async getIncoterms<T>(
    params = { pageSize: 1000 } as IParams
  ): ServiceResponse<T> {
    try {
      const queryParams = customizeParams(params)
      const res = await API.externalInstance.get(
        '/settings/incoterm',
        queryParams
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new IncotermService()
