import {
  VuexModule,
  Module,
  getModule,
  Mutation,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import {
  ICompanyAddressBook,
  ICompanyAddressBookContact
} from '@/models/address-book'
import { IInquiryContact, ISelectOption } from '@/models'
import { ContactType } from '@/utils/constants'

@Module({
  dynamic: true,
  store,
  name: 'address-book-inquiry',
  namespaced: true
})
class AddressBookInquiry extends VuexModule {
  private originAddressBook: ICompanyAddressBook = {} as ICompanyAddressBook
  private destinationAddressBook: ICompanyAddressBook = {} as ICompanyAddressBook
  private shipper: IInquiryContact = {} as IInquiryContact
  private consignee: IInquiryContact = {} as IInquiryContact

  @Mutation
  SET_ORIGIN_ADDRESS_BOOK(addressBook: ICompanyAddressBook) {
    this.originAddressBook = { ...addressBook }
  }

  @Mutation
  SET_DESTINATION_ADDRESS_BOOK(addressBook: ICompanyAddressBook) {
    this.destinationAddressBook = { ...addressBook }
  }

  @Mutation
  private SET_SHIPPER_INQUIRY_CONTACT(inquiryContact: IInquiryContact) {
    this.shipper = { ...inquiryContact }
  }

  @Mutation
  private SET_CONSIGNEE_INQUIRY_CONTACT(inquiryContact: IInquiryContact) {
    this.consignee = { ...inquiryContact }
  }

  @Action
  public resetFormData() {
    this.SET_SHIPPER_INQUIRY_CONTACT({} as IInquiryContact)
    this.SET_CONSIGNEE_INQUIRY_CONTACT({} as IInquiryContact)
    this.SET_ORIGIN_ADDRESS_BOOK({} as ICompanyAddressBook)
    this.SET_DESTINATION_ADDRESS_BOOK({} as ICompanyAddressBook)
  }

  @Action
  public setShipperInquiryContact(companyAddressBook: ICompanyAddressBook) {
    if (companyAddressBook && Object.keys(companyAddressBook).length > 0) {
      const defaultContact = companyAddressBook.addresses[0].contacts.find(
        x => x.isDefault
      )
      const shipper: IInquiryContact = {
        id: ContactType.Shipper,
        type: ContactType.Shipper,
        companyName: companyAddressBook.companyName,
        companyContactName: defaultContact ? defaultContact.name : '',
        companyCustomerReferenceId:
          companyAddressBook.companyCustomerReferenceId,
        phone:
          defaultContact && defaultContact.phone ? defaultContact.phone : '',
        email:
          defaultContact && defaultContact.email ? defaultContact.email : '',
        socialId:
          defaultContact && defaultContact.socialId
            ? defaultContact.socialId
            : '',
        isTailLiftNeeded: companyAddressBook.addresses[0].isTailLiftNeeded,
        isLoadAdviceNeeded: companyAddressBook.addresses[0].isLoadAdviceNeeded,
        loadingNotes: companyAddressBook.addresses[0].loadingNotes
      } as IInquiryContact
      this.SET_SHIPPER_INQUIRY_CONTACT(shipper)
    } else {
      this.SET_SHIPPER_INQUIRY_CONTACT({} as IInquiryContact)
    }
    this.SET_ORIGIN_ADDRESS_BOOK(companyAddressBook)
  }

  @Action
  public setConsigneeInquiryContact(companyAddressBook: ICompanyAddressBook) {
    if (companyAddressBook && Object.keys(companyAddressBook).length > 0) {
      const defaultContact = companyAddressBook.addresses[0].contacts.find(
        x => x.isDefault
      )
      const consignee: IInquiryContact = {
        id: ContactType.Consignee,
        type: ContactType.Consignee,
        companyName: companyAddressBook.companyName,
        companyContactName: defaultContact ? defaultContact.name : '',
        companyCustomerReferenceId:
          companyAddressBook.companyCustomerReferenceId,
        phone:
          defaultContact && defaultContact.phone ? defaultContact.phone : '',
        email:
          defaultContact && defaultContact.email ? defaultContact.email : '',
        socialId:
          defaultContact && defaultContact.socialId
            ? defaultContact.socialId
            : '',
        isTailLiftNeeded: companyAddressBook.addresses[0].isTailLiftNeeded,
        isLoadAdviceNeeded: companyAddressBook.addresses[0].isLoadAdviceNeeded,
        loadingNotes: companyAddressBook.addresses[0].loadingNotes
      } as IInquiryContact
      this.SET_CONSIGNEE_INQUIRY_CONTACT(consignee)
    } else {
      this.SET_CONSIGNEE_INQUIRY_CONTACT({} as IInquiryContact)
    }
    this.SET_DESTINATION_ADDRESS_BOOK(companyAddressBook)
  }

  get getShipper(): IInquiryContact {
    return this.shipper
  }

  get getConsignee(): IInquiryContact {
    return this.consignee
  }

  get getOriginAddress(): ICompanyAddressBook {
    return this.originAddressBook
  }

  get getDestinationAddress(): ICompanyAddressBook {
    return this.destinationAddressBook
  }

  get getOriginAddressBookDefaultContact(): ICompanyAddressBookContact {
    const originAddressBookDefaultContact = this.originAddressBook.addresses[0].contacts.find(
      x => x.isDefault
    )
    return originAddressBookDefaultContact
      ? originAddressBookDefaultContact
      : ({} as ICompanyAddressBookContact)
  }

  get getDestinationAddressBookDefaultContact(): ICompanyAddressBookContact {
    const destinationAddressBookDefaultContact = this.destinationAddressBook.addresses[0].contacts.find(
      x => x.isDefault
    )
    return destinationAddressBookDefaultContact
      ? destinationAddressBookDefaultContact
      : ({} as ICompanyAddressBookContact)
  }

  get getOriginAddressBookContacts(): Array<ICompanyAddressBookContact> {
    return this.originAddressBook.addresses[0].contacts
  }

  get getDestinationAddressBookContacts(): Array<ICompanyAddressBookContact> {
    return this.destinationAddressBook.addresses[0].contacts
  }

  get getOriginContactSelectOptions(): Array<ISelectOption> {
    const options: Array<ISelectOption> = [] as Array<ISelectOption>
    this.getOriginAddressBookContacts.forEach(element => {
      options.push({
        value: element.id,
        label: element.name,
        text: element.name
      })
    })
    return options
  }

  get getDestinationContactSelectOptions(): Array<ISelectOption> {
    const options: Array<ISelectOption> = [] as Array<ISelectOption>
    this.getDestinationAddressBookContacts.forEach(element => {
      options.push({
        value: element.id,
        label: element.name,
        text: element.name
      })
    })
    return options
  }
}

export const AddressBookInquiryModule = getModule(AddressBookInquiry)
