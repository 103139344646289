import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'google-map', namespaced: true })
class GoogleMap extends VuexModule {
  public showGoogleMap = true

  @Mutation
  private SET_GOOGLE_MAP(flag: boolean) {
    this.showGoogleMap = flag
  }

  @Action({ rawError: true })
  public toggleGoogleMap(loader: boolean) {
    this.SET_GOOGLE_MAP(loader)
  }

  @Action
  public Reset() {
    this.showGoogleMap = true
  }
}

export const GoogleMapModule = getModule(GoogleMap)
