import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators'
import store from '@/store'
import { IIncoterm, ISelectOption } from '@/models'
import { IncotermService } from '@/services/settings/incoterm'

const allowedIncoterms = [
  {
    code: 'EXW',
    priorityOrder: 1
  },
  {
    code: 'FCA',
    priorityOrder: 2
  },
  {
    code: 'FOB',
    priorityOrder: 3
  },
  {
    code: 'DAP',
    priorityOrder: 5
  },
  {
    code: 'CPT',
    priorityOrder: 4
  }
]

/**
 *
 *
 * @param {IIncoterm} incoterm
 * @return {*}  {IIncoterm}
 */
const setPriorityOrderOnIncoterms = (incoterm: IIncoterm): IIncoterm => {
  return {
    ...incoterm,
    priorityOrder:
      allowedIncoterms.find(
        allowedIncoterm => allowedIncoterm.code === incoterm.code
      )?.priorityOrder || null
  }
}

/**
 *
 *
 * @param {IIncoterm} incoterm
 */
const filterAllowedIncoterms = (incoterm: IIncoterm) =>
  allowedIncoterms.find(
    allowedIncoterm => allowedIncoterm.code === incoterm.code
  )

/**
 *
 *
 * @param {IIncoterm} firstElement
 * @param {IIncoterm} secondElement
 * @return {*}
 */
const sortByPriorityOrder = (
  firstElement: IIncoterm,
  secondElement: IIncoterm
) => {
  if (firstElement.priorityOrder && secondElement.priorityOrder) {
    return firstElement.priorityOrder - secondElement.priorityOrder
  } else {
    return 0
  }
}

@Module({ dynamic: true, store, name: 'incoterms', namespaced: true })
class Incoterms extends VuexModule {
  private incoterms: IIncoterm[] = []
  private incotermService = new IncotermService()

  @Mutation
  SET_INCOTERMS(incoterms: IIncoterm[]): void {
    this.incoterms = incoterms
  }

  @Action
  async getAllIncoterms(): Promise<void> {
    const { data, status } = await this.incotermService.getIncoterms()
    if (status) {
      // filter incoterms
      let incoterms = data as IIncoterm[]
      incoterms = incoterms
        .map(setPriorityOrderOnIncoterms)
        .filter(filterAllowedIncoterms)
        .sort(sortByPriorityOrder)
      this.SET_INCOTERMS(incoterms)
    }
  }

  get getIncoterms(): IIncoterm[] {
    return this.incoterms
  }

  get getIncotermsOptions(): ISelectOption[] {
    return [
      {
        label: 'Please select incoterm',
        value: '',
        text: 'Please select incoterm'
      },
      ...this.incoterms.map(incoterm => ({
        label: incoterm.code,
        value: incoterm.id,
        text: incoterm.code
      }))
    ]
  }

  get getIncotermsOptionsForFilter(): ISelectOption[] {
    return [
      {
        label: 'Please select incoterm',
        value: '',
        text: 'Please select incoterm',
        disabled: true
      },
      ...this.incoterms.map(incoterm => ({
        label: incoterm.code,
        value: incoterm.id,
        text: incoterm.code
      }))
    ]
  }
}

export const IncotermsModule = getModule(Incoterms)
