import API from '@/http/http-common'
import { ServiceResponse } from '../service-response'

export enum LoginErrorMsg {
  UnAuthorized = 'Incorrect username or password',
  EmailNotConfirmed = 'User email is not confirmed',
  DisabledAccount = 'Account is disabled'
}

export interface IErrorData {
  detail: LoginErrorMsg
  status: number
  title: string
  traceId: string
  type: string
}

interface IChangePasswordPayload {
  currentPassword: string
  newPassword: string
  reTypedNewPassword: string
}

export interface IError {
  data: IErrorData
}
export class AuthService {
  async login<T>(data: {
    username: string
    password: string
  }): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        '/identity/authentication/password-login',
        data
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError

      const errorData = error.data as IErrorData
      return { status: false, data: {} as T, errorMsg: errorData.detail }
    }
  }

  async changePassword<T>({
    currentPassword,
    newPassword,
    reTypedNewPassword
  }: IChangePasswordPayload): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        '/identity/user/change-password',
        {
          currentPassword,
          newPassword,
          reTypedNewPassword
        }
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new AuthService()
