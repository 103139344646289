import { RouteConfig } from 'vue-router/types/router'

export const termsAndServiceRoutes: Array<RouteConfig> = [
  {
    path: 'faq',
    name: 'FAQ',
    component: () => import('../../views/terms-and-service/faq/index.vue')
  },
  {
    path: 'terms-and-condition',
    name: 'Terms And Condition',
    component: () =>
      import('../../views/terms-and-service/TermsAndCondition.vue')
  },
  {
    path: 'tutorial',
    name: 'Tutorial',
    component: () => import('../../views/terms-and-service/Tutorial.vue')
  },
  {
    path: 'about-us',
    name: 'About Us',
    component: () => import('../../views/terms-and-service/AboutUs.vue')
  },
  {
    path: 'shipping-to-amazon',
    name: 'Shipping to Amazon',
    component: () =>
      import('../../views/terms-and-service/ShippingToAmazon.vue')
  }
]
