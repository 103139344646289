import {
  IShipmentRouteLeg,
  IShipmentTrackingRoute
} from '../../../models/shipment'
import moment from 'moment'
import { RouteType, TrackingRouteLegType } from '@/utils/constants'

export class ShipmentTrackingHelper {
  getLegName(leg: IShipmentRouteLeg) {
    if (leg.type === TrackingRouteLegType.MainFreight) {
      return (
        leg.toGatewayShortName ||
        leg.legShortTitle ||
        `${leg.fromAddressString} - ${leg.toAddressString}`
      )
    }
    return leg.legShortTitle
  }

  getLegTitle(
    legs: IShipmentRouteLeg[],
    leg: IShipmentRouteLeg,
    isPointToPoint: boolean,
    isBubbleSplit: boolean,
    index: number
  ) {
    const nextLeg = legs && legs[index + 1]
    switch (leg.type) {
      // PickUp Leg
      case TrackingRouteLegType.PickUp:
        if (leg.endActualAt) {
          return `PU done ${this.formatDate(leg.endActualAt)}`
        }
        return `PU date ${this.formatDate(leg.endPlannedAt)}`

      // OriginGW Leg
      case TrackingRouteLegType.OriginGw:
        if (
          leg.startActualAt &&
          !this.isLegDone(leg, isPointToPoint, isBubbleSplit, legs)
        ) {
          return `Arrived ${this.formatDate(leg.startActualAt)}` // Actual arrival to OriginGW
        }
        if (
          this.isLegDone(leg, isPointToPoint, isBubbleSplit, legs) &&
          this.isDepartedFromLeg(
            legs,
            leg,
            isPointToPoint,
            isBubbleSplit,
            index
          )
        ) {
          return `Departed ${this.formatDate(nextLeg?.startActualAt)}` // Actual departure from OriginGW
        }
        if (this.isLegDone(leg, isPointToPoint, isBubbleSplit, legs)) {
          return `Estimated departure ${this.formatDate(
            nextLeg?.startPlannedAt
          )}` // Estimated departure from OriginGw
        }
        return `Arrival ${this.formatDate(leg.startPlannedAt)}` // Estimated arrival to OriginGw

      // MainFreight leg
      case TrackingRouteLegType.MainFreight:
        if (isBubbleSplit && leg.endActualAt) {
          return `Departed ${this.formatDate(leg.endActualAt)}`
        }
        if (isBubbleSplit && leg.endPlannedAt) {
          return `Arrival ${this.formatDate(leg.endPlannedAt)}`
        }

        if (isPointToPoint) {
          const legIndex = legs.indexOf(leg)
          if (
            (legIndex === 1 && leg.startActualAt) ||
            (legIndex === 2 && leg.endActualAt)
          ) {
            return `Arrived ${this.formatDate(
              legIndex === 1 ? leg.startActualAt : leg.endActualAt
            )}`
          } else {
            return `Arrival ${this.formatDate(
              legIndex === 1 ? leg.startPlannedAt : leg.endPlannedAt
            )}`
          }
        }

        if (
          nextLeg?.type !== TrackingRouteLegType.MainFreight &&
          leg.endActualAt
        ) {
          return `Arrived ${this.formatDate(leg?.endActualAt)}` // Actual arrival to gateway (is shown for the last Mainfreight leg)
        }

        if (
          nextLeg &&
          nextLeg?.type === TrackingRouteLegType.MainFreight &&
          this.isDepartedFromLeg(
            legs,
            nextLeg,
            isPointToPoint,
            isBubbleSplit,
            index
          )
        ) {
          return `Departed ${this.formatDate(nextLeg.startActualAt)}` // Actual departure from gateway if next leg is also Mainfreight type
        }

        if (
          this.isDepartedFromLeg(
            legs,
            leg,
            isPointToPoint,
            isBubbleSplit,
            index
          ) &&
          this.isLegDone(leg, isPointToPoint, isBubbleSplit, legs)
        ) {
          return `Departed ${this.formatDate(leg.startActualAt)}` // Actual departure from gateway
        }

        if (leg.endActualAt) {
          return `Arrived ${this.formatDate(leg?.endActualAt)}` // Actual arrival to gateway
        }

        return `Arrival ${this.formatDate(leg.endPlannedAt)}` // Estimated arrival to gateway

      // DestinationGW leg
      case TrackingRouteLegType.DestinationGw:
        if (leg.startActualAt && !leg.endActualAt) {
          return `Done ${this.formatDate(leg.startActualAt)}`
        }
        if (this.isLegDone(leg, isPointToPoint, isBubbleSplit, legs)) {
          return `Done ${this.formatDate(leg.startActualAt)}`
        }
        return `Planned ${this.formatDate(leg.startPlannedAt)}`

      // Delivery leg
      case TrackingRouteLegType.Delivery:
        if (!leg.endActualAt) {
          return `Arrival ${this.formatDate(leg.endPlannedAt)}`
        }
        if (leg.endActualAt) {
          return `Delivered ${this.formatDate(leg.endActualAt)}`
        }
        return 'N/A'

      default:
        return 'N/A'
    }
  }

  isDepartedFromLeg(
    legs: IShipmentRouteLeg[],
    leg: IShipmentRouteLeg,
    isPointToPoint: boolean,
    isBubbleSplit: boolean,
    index: number
  ): boolean {
    const nextLeg = legs && legs[index + 1]

    switch (leg.type) {
      case TrackingRouteLegType.PickUp:
        return !!leg.endActualAt
      case TrackingRouteLegType.OriginGw:
        return !!(
          this.isLegDone(leg, isPointToPoint, isBubbleSplit, legs) &&
          nextLeg?.startActualAt
        )
      case TrackingRouteLegType.DestinationGw:
        return !!(
          this.isLegReached(leg, isPointToPoint, isBubbleSplit, legs) &&
          leg.endActualAt
        )
      case TrackingRouteLegType.MainFreight:
        if (isBubbleSplit && leg.endActualAt) {
          return !!leg.endActualAt
        }
        if (isPointToPoint) {
          return this.isLegDone(leg, isPointToPoint, isBubbleSplit, legs)
        }
        if (
          nextLeg?.type === TrackingRouteLegType.MainFreight ||
          nextLeg?.type === TrackingRouteLegType.DestinationGw
        ) {
          return !!(
            this.isLegReached(nextLeg, isPointToPoint, isBubbleSplit, legs) &&
            nextLeg.startActualAt
          )
        }
        return !!(
          this.isLegReached(leg, isPointToPoint, isBubbleSplit, legs) &&
          leg.endActualAt
        )
      default:
        return !!(
          this.isLegReached(leg, isPointToPoint, isBubbleSplit, legs) &&
          leg.isPointerAtEnd
        )
    }
  }

  isLegDone(
    leg: IShipmentRouteLeg,
    isPointToPoint: boolean,
    isBubbleSplit: boolean,
    legs: Array<IShipmentRouteLeg>
  ): boolean {
    switch (leg.type) {
      case TrackingRouteLegType.OriginGw:
        return !!(
          this.isLegReached(leg, isPointToPoint, isBubbleSplit, legs) &&
          leg.endActualAt
        )
      case TrackingRouteLegType.DestinationGw:
        return !!(
          this.isLegReached(leg, isPointToPoint, isBubbleSplit, legs) &&
          leg.startActualAt
        )
      case TrackingRouteLegType.MainFreight:
        if (isBubbleSplit) {
          return !!leg.endActualAt
        }
        if (isPointToPoint) {
          const legIndex = legs.indexOf(leg)
          if (legIndex === 1) {
            return !!leg.startActualAt
          } else {
            return !!leg.endActualAt
          }
        }
        return !!(
          this.isLegReached(leg, isPointToPoint, isBubbleSplit, legs) &&
          leg.endActualAt
        )
      default:
        return this.isLegReached(leg, isPointToPoint, isBubbleSplit, legs)
    }
  }

  isLegReached(
    leg: IShipmentRouteLeg,
    isPointToPoint: boolean,
    isBubbleSplit: boolean,
    legs: Array<IShipmentRouteLeg>
  ): boolean {
    switch (leg.type) {
      case TrackingRouteLegType.PickUp:
        return !!leg.endActualAt
      case TrackingRouteLegType.OriginGw:
        return !!leg.startActualAt
      case TrackingRouteLegType.Delivery:
        return !!leg.endActualAt
      case TrackingRouteLegType.MainFreight:
        if (isPointToPoint) {
          return this.isLegDone(leg, isPointToPoint, isBubbleSplit, legs)
        }
        return !!leg.startActualAt
      default:
        return !!(leg.startActualAt || leg.isPointerAtStart)
    }
  }

  isPointToPoint(route: IShipmentTrackingRoute) {
    return route?.shipmentRouteType === RouteType.PointToPoint
  }

  formatDate(dateString?: string | null) {
    if (!dateString) {
      return 'N/A'
    }
    const dateFormat = 'DD.MM'

    return moment(dateString).format(dateFormat)
  }
}
