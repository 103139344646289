<template>
  <div id="app">
    <b-overlay :show="showLoader" rounded="sm">
      <router-view />
    </b-overlay>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { TemplateModule } from '@/store/modules/template'
import { error, success } from '@/utils/global-setting'
import Watcher from '@/utils/watcher'
import { AuthModule, GoogleMapModule } from './store/modules'
import { GoogleStaticMapService } from './services/google-static-map'

@Component
export default class App extends Vue {
  constructor() {
    super()
    new Watcher()
  }
  onLine = navigator.onLine
  get showLoader() {
    return TemplateModule.showLoader
  }

  updateOnlineStatus(e) {
    const { type } = e
    this.onLine = type === 'online'
    if (this.onLine) {
      success(this, 'Back online')
    } else {
      error(this, 'You are offline!')
    }
  }

  // close all the pop up if they are open by pressing 'ESC' button
  closePopOver(e) {
    if (e.keyCode == 27) {
      this.$root.$emit('bv::hide::popover')
    }
  }

  localStorageChange(event: StorageEvent) {
    if (event.key === 'isLoggedIn' && event.newValue === null) {
      AuthModule.Logout()
    }
  }

  async mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    window.addEventListener('keyup', this.closePopOver)
    window.addEventListener('storage', this.localStorageChange)
    await new GoogleStaticMapService().getAndSetGoogleMapAllowOrNot()
  }

  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
    window.removeEventListener('keyup', this.closePopOver)
    window.removeEventListener('storage', this.localStorageChange)
    GoogleMapModule.Reset()
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'ReplicaPro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000027;
  height: 100%;
}

#nav {
  padding: 30px;
  font-family: 'ReplicaPro';
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
