import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'

export class CargoReadyToLoadService {
  async getCargoReadyToLoadTimePeriod<T>(): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/settings/cargo-readytoload-timeperiod`
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new CargoReadyToLoadService()
