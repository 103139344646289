import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'
import { customizeParams } from '@/utils/service-helper'

export class InquirySolutionService {
  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async getInquirySolutions<T>(inquiryId: string): ServiceResponse<T> {
    const queryParams = customizeParams({ pageSize: 100000 })
    try {
      const res = await API.externalInstance.get(
        `/inquiry/${inquiryId}/solutions`,
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async createInquirySolutions<T>(inquiryId: string): ServiceResponse<T> {
    const queryParams = customizeParams({ pageSize: 100000 })
    try {
      const res = await API.externalInstance.post(
        `/inquiry/${inquiryId}/solutions`,
        queryParams
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new InquirySolutionService()
