import { ShipmentModule } from '@/store/modules/shipment/shipment'
import { EventBus } from '@/event-bus/event-bus'

export const beforeEnter = async (_to: any, _from: any, next: any) => {
  // Get all shipments and inquiry
  if (ShipmentModule.totalShipment == 0) await ShipmentModule.GetInquiry()
  // Get specific shipments based on statuses
  if (ShipmentModule.totalShipment === 0 || ShipmentModule.activeShipment === 0)
    await ShipmentModule.GetAllTypeOfShipment()
  EventBus.$emit('loginBtnDisabled', false)

  if (ShipmentModule.totalShipment === 0 && ShipmentModule.totalInquiry === 0) {
    next('/new-inquiry')
  } else if (ShipmentModule.totalShipment === 0) {
    next('/inquiry')
  } else {
    next()
  }
}
