import {
  AuthModule,
  FreightModeModule,
  IncotermsModule,
  InquirySolutionsModule,
  QuoteModule,
  TemplateModule,
  UnitConversionModule,
  CommonConfigurationModule,
  EnumsModule,
  NewInquiryFormModule,
  ShipmentModule,
  CurrencyModule,
  ExtraFieldTypesModule,
  ShipmentDetailModule,
  CountryModule,
  MobileViewModule,
  ContactInfoFormModule,
  CompanyModule,
  CompanyContactModule,
  VendorModule,
  NavWaitingForApprovalShipmentModule,
  GoogleMapModule,
  CustomerAPIAccountModule,
  AddressBookInquiryModule
} from '@/store/modules/'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface RootState {
  auth: typeof AuthModule
  template: typeof TemplateModule
  googleMap: typeof GoogleMapModule
  commonConfiguration: typeof CommonConfigurationModule
  unitConversion: typeof UnitConversionModule
  inquirySolutions: typeof InquirySolutionsModule
  quote: typeof QuoteModule
  newInquiryForm: typeof NewInquiryFormModule
  inquiryMobileView: typeof MobileViewModule
  addressBookInquiryModule: typeof AddressBookInquiryModule
  settings: {
    freightMode: typeof FreightModeModule
    incoterms: typeof IncotermsModule
    enums: typeof EnumsModule
    currency: typeof CurrencyModule
    extraFieldTypes: typeof ExtraFieldTypesModule
    country: typeof CountryModule
    companyContact: typeof CompanyContactModule
    vendor: typeof VendorModule
    navWaitingForApprovalShipment: typeof NavWaitingForApprovalShipmentModule
    customerAPIAccount: typeof CustomerAPIAccountModule
  }
  shipmentModule: typeof ShipmentModule
  shipmentDetailModule: typeof ShipmentDetailModule
  contactformModule: typeof ContactInfoFormModule
  companyModule: typeof CompanyModule
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<RootState>({})
