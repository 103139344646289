import API from '@/http/http-common'
import { IContactFormEntity } from '@/models'
import { ServiceResponse } from '@/services/service-response'

export class InquiryContactService {
  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @param {string} contactId
   * @param {IContactFormEntity} payload
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async updateInquiryContact<T>(
    inquiryId: string,
    contactId: string,
    payload: IContactFormEntity
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(
        `/inquiry/${inquiryId}/contacts/${contactId}`,
        payload
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async getInquiryContacts<T>(inquiryId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/inquiry/${inquiryId}/contacts`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new InquiryContactService()
