var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header"},[_c('app-mobile-nav'),_c('b-link',{staticClass:"mobile_logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/images/menu-logo.svg")}})]),_c('div',{staticClass:"container"},[(_vm.$route.name === 'Home' || _vm.$route.name === 'My Shipment')?_c('app-home-header'):_vm._e(),(_vm.$route.name === 'Invoices')?_c('app-invoice-header'):_vm._e(),(_vm.isRouteSettings)?_c('app-settings-header'):_vm._e(),(
        _vm.$route.name === 'NewInquiry' ||
          _vm.$route.name === 'CreateInquiry' ||
          _vm.$route.name === 'UpdateInquiry' ||
          _vm.$route.name === 'ShipmentInquiry'
      )?_c('app-new-inquery-header'):_vm._e(),(_vm.isRouteInformation)?_c('app-information-header'):_vm._e(),(_vm.$route.name === 'Inquiry')?_c('app-inquiry-header'):_vm._e(),(
        _vm.$route.name === 'Details' ||
          _vm.$route.name === 'Messages' ||
          _vm.$route.name === 'Attachments' ||
          _vm.$route.name === 'Quotes Invoices'
      )?_c('app-shipment-details'):_vm._e(),(_vm.$route.name === 'WaitingForApproval')?_c('app-waiting-for-approval'):_vm._e(),(
        _vm.$route.name === 'Company Registration' ||
          _vm.$route.name === 'Confirm Email'
      )?_c('h1',{staticClass:"pageTitle font-light text-gray-darker"},[_vm._v(" Sign Up ")]):_vm._e(),(_vm.$route.name === 'Information')?_c('app-terms-and-condition'):_vm._e(),(_vm.$route.name === 'My Reports')?_c('app-my-report-header'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }