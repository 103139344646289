<template>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="pageTitle font-light text-gray-darker">
      <strong> Shipment #{{ shipment.referenceId }}</strong>
      from
      <strong>
        {{ shipmentOriginAddress }}
      </strong>
      to
      <strong>
        {{ shipmentDestinationAddress }}
      </strong>
    </h1>
    <b-button class="greenBtn" to="/new-inquiry">
      <b-icon-plus small></b-icon-plus>
      New inquiry
    </b-button>
  </div>
</template>

<script lang="ts">
import { IDetailAddress, IShipment, IShipmentAddress } from '@/models'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('shipment-detail', ['shipment']),
    ...mapGetters('shipment-detail', {
      address: 'getShipmentDetailAddress'
    })
  }
})
export default class ShipmentDetailsHeader extends Vue {
  /** Vue store binding start */
  private address!: IDetailAddress
  private shipment!: IShipment
  /** Vue store binding start */

  get shipmentOriginAddress() {
    if (this.address.shipmentOriginAddress) {
      return this.getAddress(this.address.shipmentOriginAddress)
    }
    return ''
  }

  get shipmentDestinationAddress() {
    if (this.address.shipmentDestinationAddress) {
      return this.getAddress(this.address.shipmentDestinationAddress)
    }
    return ''
  }

  private getAddress(shipmentAddress: IShipmentAddress) {
    let address = ''
    if (shipmentAddress.city) address += shipmentAddress.city
    else if (shipmentAddress.countryName) address += shipmentAddress.countryName
    return address
  }
}
</script>

<style></style>
