declare global {
  interface Window {
    SI_API: {
      showPopup: () => void
      hidePopup: () => void
      addParams<T>(params: T): void
      onChatEnded(): void
      onChatClosed(): void
    }
  }
}
/**
 * SI_API.setChatInfo sets the chat info in session storage,
 * since we don't know when SI_API will be availabe we are setting the chat info by ourselves after the login
 */
export const setChatInfo = (email: string, name: string) => {
  sessionStorage.setItem('si_email', email)
  sessionStorage.setItem('si_name', name)
}

export const removeChatInfo = () => {
  sessionStorage.removeItem('si_email')
  sessionStorage.removeItem('si_name')
  sessionStorage.removeItem('si_params')
}

export const showPopup = () => {
  window.SI_API && window.SI_API.showPopup()
}

export const hidePopup = () => {
  window.SI_API && window.SI_API.hidePopup()
}
