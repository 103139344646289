<template>
  <div>
    <span :class="labelClass">
      Arrival
    </span>
    <span>
      {{ (shipment.deliveredAt || shipment.plannedDeliveryAt) | formatDate }}
    </span>
  </div>
</template>

<script lang="ts">
import { IShipment } from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'shipment-delivered-at'
})
export default class ShipmentDeliveredAt extends Vue {
  @Prop({
    type: Object,
    required: true,
    default: () => {
      return {} as IShipment
    }
  })
  shipment!: IShipment

  @Prop({ type: String, default: '' }) labelClass!: string
}
</script>
