import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'

export class QuoteContactService {
  /**
   * get consignee and shipper contacts of a quote
   * @param {string} quoteId
   */
  async getQuoteContacts<T>(quoteId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(`/quote/${quoteId}/contacts`)
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}
export default new QuoteContactService()
