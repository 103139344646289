import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'
import { customizeParams } from '@/utils/service-helper'
import { IParams } from '@/models'

export class CountryService {
  async getAllCountries<T>(
    params = { pageSize: 1000 } as IParams
  ): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        `/settings/country`,
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new CountryService()
