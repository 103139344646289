import {
  FreightMode,
  AccessibilityType,
  INotificationOptionsEnum,
  RegionCountryCode
} from './constants'
import {
  ICurrency,
  INotify,
  IPriceBreakdown,
  IQuotePrice,
  ISelectOption,
  IUser
} from '@/models'
import { getBaseURL, getToken, getUserData } from '@/utils/cookies'
import jwtDecode from 'jwt-decode'
import { createPatch } from 'rfc6902'
import moment from 'moment'
import { parsePhoneNumber } from 'libphonenumber-js'
import { apiOptions } from './select-options'
import { IMultiSelect } from '@/models/multi-select'
type HSL = [number, number, number]

export enum PriceGroup {
  Export = 'Export',
  Main = 'Main',
  Import = 'Import'
}

export interface ITransitDuration {
  transitDurationUnit: string
}
export const dateFormat = () => {
  return {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  }
}

export const momentSetting = {
  date: 'DD.MM.YYYY',
  dateTime12Hours: 'DD.MM.YYYY hh:mm a',
  dateTime24Hours: 'DD.MM.YYYY HH:mm',
  time: 'HH:mm',
  DDMM: 'D. MMM'
}

export const mysqlFormat = {
  date: 'YYYY-MM-DD',
  dateTime24Hours: 'YYYY-MM-DD HH:mm:ss'
}

export const getTransitTime = (data: any) => {
  if (data) {
    if (data.transitDurationUnit === 'CalendarDays') {
      return 'Days'
    } else if (data.transitDurationUnit === 'WorkingDays') {
      return 'Working Days'
    }
  } else {
    return ''
  }
}

export function parsePriceBreakdowns(
  priceBreakdowns: IQuotePrice[]
): IPriceBreakdown {
  const enumMap = {
    [PriceGroup.Export]: 'Origin',
    [PriceGroup.Import]: 'Destination',
    [PriceGroup.Main]: 'Main'
  }
  const groupedData: IPriceBreakdown = priceBreakdowns.reduce((acc, curr) => {
    const currentKey = enumMap[curr.priceGroup]
    if (curr.companyCostPriceTotal) {
      return { ...acc, [currentKey]: [...(acc[currentKey] || []), curr] }
    } else {
      return { ...acc }
    }
  }, {})
  return groupedData
}

function getTypeBaseValue(previousValue: number) {
  let value1: string | number | moment.Moment | null

  if (isNaN(previousValue) && moment(previousValue).isValid()) {
    value1 = moment(previousValue)
  } else if (isNaN(previousValue)) {
    value1 = previousValue
  } else {
    value1 = Number(previousValue)
  }
  return value1
}

export const customSort = (
  data: any,
  event: { field: string },
  option = 'sort'
) => {
  let previousValue: any
  let currentValue: any
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const comparer = function(a, b) {
    previousValue = eval(`a.${event.field}`)
    currentValue = eval(`b.${event.field}`)

    const value1: string | number | moment.Moment | null = getTypeBaseValue(
      previousValue
    )
    const value2: string | number | moment.Moment | null = getTypeBaseValue(
      currentValue
    )

    let result: any = null

    if (value1 == null && value2 != null) result = -1
    else if (value1 != null && value2 == null) result = 1
    else if (value1 == null && value2 == null) result = 0
    else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = (value1 as string).localeCompare(value2)
    } else if (
      value1 &&
      value2 &&
      typeof value1 === 'object' &&
      typeof value2 === 'object'
    ) {
      result =
        value1?.diff(value2, 'ms') < 0
          ? -1
          : value1?.diff(value2, 'ms') > 0
          ? 1
          : 0
    } else if (typeof value1 === 'number' && typeof value2 === 'number') {
      result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0
    }

    return result
  }

  return data[option](comparer)
}

export function getFreightModeIcon(freightModeCode: string) {
  switch (freightModeCode) {
    case FreightMode.Airplane:
      return require('../assets/images/Icon-freight-plane.svg')
    case FreightMode.Courier:
      return require('../assets/images/Icon-freight-courier.svg')
    case FreightMode.Ocean:
      return require('../assets/images/Icon-freight-ocean.svg')
    case FreightMode.Railway:
      return require('../assets/images/Icon-freight-rail.svg')
    case FreightMode.Road:
      return require('../assets/images/Icon-freight-road.svg')
    case FreightMode.LOCAL:
      return require('../assets/images/passport-solid.svg')
    case FreightMode.LOGISTICS:
    case FreightMode.WAREHOUSE:
      return require('../assets/images/warehouse-solid.svg')
    default:
      return ''
  }
}

export const getCurrencySymbol = (
  currencies: ICurrency[],
  currencyIsoName: string
) => {
  const currency = currencies.find(
    (currency: ICurrency) => currency.isoName === currencyIsoName
  )
  if (currency) {
    return currency.symbol
  }
}

const volumeUnitSymbols = {
  cbm: 'm³'
}

export const getVolumeUnitSymbol = (unitName: string | undefined): string =>
  unitName ? volumeUnitSymbols[unitName] : ''

export const success = (instant: Vue, message: string) => {
  const msg =
    typeof message === 'string' ? message.toCapitalizeFirstLetter() : message
  instant.$bvToast.toast(msg, {
    title: `Success`,
    variant: 'success',
    solid: true
  })
}

export const error = (
  componentInstance: Vue,
  message: string,
  autoHideDelay?: number
) => {
  const msg =
    typeof message === 'string' ? message.toCapitalizeFirstLetter() : message
  componentInstance.$bvToast.toast(msg, {
    title: `Error`,
    variant: 'danger',
    solid: true,
    autoHideDelay: autoHideDelay ? autoHideDelay : 5000
  })
}

export const warn = (componentInstance: Vue, message: string) => {
  const msg =
    typeof message === 'string' ? message.toCapitalizeFirstLetter() : message
  componentInstance.$bvToast.toast(msg, {
    title: `Warning`,
    variant: 'warning',
    solid: true
  })
}

export const getConfirmModalConfig = (id: string) => ({
  id,
  title: 'Please Confirm',
  buttonSize: 'sm',
  okVariant: 'dark',
  cancelVariant: 'outline-dark',
  okTitle: 'Yes',
  cancelTitle: 'No',
  bodyClass: 'validation-modal',
  headerClass: 'no-border validation-modal__header',
  footerClass: 'p-2 no-border',
  hideHeaderClose: false,
  noCloseOnEsc: true,
  noCloseOnBackdrop: true,
  centered: true
})

export const notificationAllOptions: Array<INotify> = [
  {
    value: INotificationOptionsEnum.IsSubscribedToTrackingOnlyDelay,
    text: 'Send notification only when there is a delay',
    isDisabled: false,
    isSelected: false
  },
  {
    value: INotificationOptionsEnum.All,
    text: 'Send notification for every update',
    isDisabled: false,
    isSelected: false
  }
]

export const notificationOptions: Array<INotify> = [
  {
    value: INotificationOptionsEnum.IsSubscribedToTrackingScheduleConfirmed,
    text: 'Pickup plan is planned',
    isDisabled: false,
    isSelected: false
  },
  {
    value: INotificationOptionsEnum.IsSubscribedToTrackingPuDone,
    text: 'Pick-up is completed',
    isDisabled: false,
    isSelected: false
  },
  {
    value: INotificationOptionsEnum.IsSubscribedToTrackingDepartedOrigin,
    text: 'Departure from origin terminal',
    isDisabled: false,
    isSelected: false
  },
  {
    value: INotificationOptionsEnum.IsSubscribedToTrackingArrivedTsp,
    text: 'Arrived to midway terminal',
    isDisabled: false,
    isSelected: false
  },
  {
    value: INotificationOptionsEnum.IsSubscribedToTrackingArrivedDestination,
    text: 'Arrival to destination terminal',
    isDisabled: false,
    isSelected: false
  },
  {
    value: INotificationOptionsEnum.IsSubscribedToTrackingCustomsDone,
    text: 'Customs cleared',
    isDisabled: false,
    isSelected: false
  },
  {
    value: INotificationOptionsEnum.IsSubscribedToTrackingDelivered,
    text: 'Delivery completed',
    isDisabled: false,
    isSelected: false
  }
]

export const parseValue = (value: number): number => {
  return parseFloat(value.toFixed(3))
}

export const getMinMaxFromArray = (array, key): [number, number] => {
  // eslint-disable-next-line prefer-spread
  const max = Math.max.apply(
    Math,
    array.map(a => a[key])
  )
  // eslint-disable-next-line prefer-spread
  const min = Math.min.apply(
    Math,
    array.map(a => a[key])
  )
  return [min, max]
}

export const getMinFromArray = (array, key): number => {
  // eslint-disable-next-line prefer-spread
  return Math.min.apply(
    Math,
    array.map(a => a[key])
  )
}

export const getMaxFromArray = (array, key): number => {
  // eslint-disable-next-line prefer-spread
  return Math.max.apply(
    Math,
    array.map(a => a[key])
  )
}

export const checkTokenExpire = () => {
  const token = getToken() || ''
  if (token !== '') {
    const decode: any = jwtDecode(token)
    if (decode.exp) {
      const exp = decode.exp
      if (Date.now() >= exp * 1000) {
        return true
      } else {
        return false
      }
    }
  }
  return false
}

/**
 * Get a set of operations for `PATCH` using `rfc6902`
 */
export function getPatch<T>(oldValue: T, newValue: T) {
  return createPatch(oldValue, newValue)
}

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
export function b64toBlob(
  b64Data: string,
  contentType: string,
  sliceSize?: number
) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  const byteCharacters = atob(b64Data)
  const byteArrays: any = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

/**
 * Parse download link from BE response. Replaces http to https protocol and removes port.
 * @link original download link value from be response.
 */
export function parseDownloadLink(link: string): string {
  return link.replace(/http:\/\//, 'https://').replace(/:\d+/, '')
}

/**
 * Download file.
 * @data data can be in any format.
 * @fileName file name
 */
export function downloadFile(data: any, fileName: string, href?: string) {
  const link = document.createElement('a')
  if (href) {
    link.href = href
  } else {
    const FILE = window.URL.createObjectURL(data)
    link.href = FILE
  }
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

/**
 * Convert a base64 string in a Blob by providing image source.
 *
 * @param src {String} image source
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @return Blob
 */
export function imageSrcToBlob(src: string) {
  // Split the base64 string in data and contentType
  const block = src.split(';')
  // Get the content type of the image
  const contentType = block[0].split(':')[1] // In this case "image/gif"
  // get the real base64 content of the file
  const realData = block[1].split(',')[1] // In this case "R0lGODlhPQBEAPeoAJosM...."

  return b64toBlob(realData, contentType)
}

export function isEuropeonNumber(n: string) {
  return n.includes(',')
}

export function convertEuroToActualNumber(n: string) {
  return Number(n.replace(/\./g, '').replace(',', '.'))
}

export function extractCountryCode(value: string): string {
  if (value) {
    try {
      const parsedPhoneNumber = parsePhoneNumber(value)
      return parsedPhoneNumber.country ? parsedPhoneNumber.country : 'EE'
    } catch (error) {
      console.log('--- error ---', error)
      return 'EE'
    }
  }
  return 'EE'
}

export function getRegionCountryCode() {
  const regionOptions = apiOptions() as Array<ISelectOption>
  const baseUrl = getBaseURL()

  const urlOption = regionOptions.find(x => x.value.api == baseUrl)
  const region = urlOption?.value.region
  const regionCountryCode = RegionCountryCode[region].value
  return regionCountryCode
}

export function getUserCompanies(
  excludeVendorCustomerCompany = false
): Array<IMultiSelect> {
  const user = JSON.parse(getUserData() as string) as IUser
  const companies = !excludeVendorCustomerCompany
    ? user.companies
    : user.companies.filter(
        x => x.accessibilityType === AccessibilityType.Group
      )

  return mapGroupAndVendorCompanies(companies, user)
}

function mapGroupAndVendorCompanies(
  companies: {
    id: string
    name: string
    accessibilityType: AccessibilityType
  }[],
  user: IUser
): Array<IMultiSelect> {
  const groupCompanies: Array<IMultiSelect> = []
  const vendorCustomerCompanies: Array<IMultiSelect> = []
  const userOwnCompany: IMultiSelect = {} as IMultiSelect

  companies.forEach(element => {
    // For own company
    if (element.id === user.contact.companyId) {
      userOwnCompany.value = element.id
      userOwnCompany.text = element.name
    }
    // For group companies
    if (
      element.accessibilityType === AccessibilityType.Group &&
      user.contact.companyId !== element.id
    ) {
      groupCompanies.push({
        value: element.id,
        text: `${element.name} (Group company)`
      })
    }
    // For vendor customer companies
    if (element.accessibilityType === AccessibilityType.VendorCustomer) {
      vendorCustomerCompanies.push({
        value: element.id,
        text: `${element.name} (Customer)`
      })
    }
  })

  return [
    ...[userOwnCompany],
    ...customSort(groupCompanies, { field: 'text' }),
    ...customSort(vendorCustomerCompanies, { field: 'text' })
  ]
}

export function getAvatarTextBGColor(text: string): string {
  const hRange = [0, 360]
  const sRange = [50, 75]
  const lRange = [25, 60]

  const generateHSL = (name: string): HSL => {
    const normalizeHash = (hash: number, min: number, max: number) => {
      return Math.floor((hash % (max - min)) + min)
    }

    const getHashOfString = (str: string) => {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      hash = Math.abs(hash)
      return hash
    }

    const hash = getHashOfString(name)
    const h = normalizeHash(hash, hRange[0], hRange[1])
    const s = normalizeHash(hash, sRange[0], sRange[1])
    const l = normalizeHash(hash, lRange[0], lRange[1])
    return [h, s, l]
  }

  const HSLtoString = (hsl: HSL) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`
  }

  return HSLtoString(generateHSL(text))
}

export const getAttachmentErrorMessage = (error: any) => {
  return error &&
    error.data &&
    error.data.errors &&
    error.data.errors['AttachmentFile'] &&
    error.data.errors['AttachmentFile'].length > 0
    ? error.data.errors['AttachmentFile'][0]
    : 'Something went wrong, Please contact MyDello support team.'
}
