import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'

export class EnumsService {
  async getAllEnums<T>(): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(`/settings/enum`)
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new EnumsService()
