import { IAddress, IContainerUnit, ISelectOption, IUnit } from '@/models'
import { v4 as uuidv4 } from 'uuid'

export const DEFAULT_ADDRESS: IAddress = {
  label: '',
  point: '',
  street: '',
  city: '',
  county: '',
  countryIsoNameAlpha2: '',
  countryName: '',
  postalCode: '',
  latitude: 0,
  longitude: 0,
  googleAddressString: '',
  timeZoneIana: ''
}

export const WEIGHT_UNIT_OPTIONS: Array<ISelectOption> = [
  {
    label: 'kg',
    value: 'kg'
  },
  {
    label: 'g',
    value: 'g'
  },
  {
    label: 'oz',
    value: 'oz'
  },
  {
    label: 'lb',
    value: 'lb'
  }
]

export const VOLUME_UNIT_OPTIONS: Array<ISelectOption> = [
  {
    label: 'm³',
    value: 'cbm'
  },
  {
    label: 'cm3',
    value: 'ccm'
  },
  {
    label: 'ft3',
    value: 'cft'
  },
  {
    label: 'in3',
    value: 'cin'
  }
]

export const DEFAULT_WEIGHT_UNIT = 'kg'
export const DEFAULT_VOLUME_UNIT = 'cbm'
export const DEFAULT_DIMENSION_UNIT = 'cm'

export const DEFAULT_DIMENSION = {
  length: { value: 0, isValid: true, validationMsg: '' },
  width: { value: 0, isValid: true, validationMsg: '' },
  height: { value: 0, isValid: true, validationMsg: '' },
  unit: DEFAULT_DIMENSION_UNIT
}
export const DEFAULT_ITEM_WEIGHT = {
  value: 0,
  unit: 'kg',
  totalWeight: 0
}

export const getDefaultBox = () => ({
  id: uuidv4(),
  quantity: 0,
  dimensions: { ...DEFAULT_DIMENSION },
  totalVolume: 0,
  weight: { ...DEFAULT_ITEM_WEIGHT },
  showSumModal: true,
  isStackable: true,
  isPallet: false
})

export const getPallet = (length = 0, width = 0) => ({
  id: uuidv4(),
  quantity: 0,
  dimensions: {
    height: { value: 0, isValid: true, validationMsg: '' },
    length: { value: length, isValid: true, validationMsg: '' },
    width: { value: width, isValid: true, validationMsg: '' },
    unit: DEFAULT_DIMENSION_UNIT
  },
  totalVolume: 0,
  weight: { ...DEFAULT_ITEM_WEIGHT },
  showSumModal: true,
  isStackable: true,
  isPallet: true
})

export const getEnvelope = (
  defaultHeight: number,
  defaultLength: number,
  defaultWidth: number
) => ({
  id: uuidv4(),
  quantity: 0,
  dimensions: {
    height: { value: defaultHeight, isValid: true, validationMsg: '' },
    length: { value: defaultLength, isValid: true, validationMsg: '' },
    width: { value: defaultWidth, isValid: true, validationMsg: '' },
    unit: 'm'
  },
  totalVolume: defaultHeight * defaultLength * defaultWidth,
  weight: { ...DEFAULT_ITEM_WEIGHT },
  showSumModal: true,
  isStackable: true,
  isPallet: false
})

export const getDefaultContainer = () => ({
  id: uuidv4(),
  quantity: 0,
  type: '',
  containerEdges: '',
  isOverweight: false
})

export const INITIAL_BOXES: IUnit[] = [{ ...getDefaultBox() }]

export const INITIAL_PALLETS: IUnit[] = [{ ...getPallet() }]

export const INITIAL_ENVELOPE: IUnit[] = [{ ...getEnvelope(0, 0, 0) }]

export const INITIAL_CONTAINERS: IContainerUnit[] = [
  { ...getDefaultContainer() }
]
