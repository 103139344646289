<template>
  <!-- class="form-wrapper-right container mr-auto my-auto" -->
  <div class="welcome_contet_wrap">
    <h4>
      Welcome to the new era<br />
      of freight forwarding
    </h4>
    <ul class="no-bullets">
      <li>
        <b-img :src="require('../../assets/images/logo-small.svg')"></b-img>
        <span>
          MyDello helps you to find best global transport options instantly.
        </span>
      </li>
      <li>
        <b-img :src="require('../../assets/images/logo-small.svg')"></b-img>
        <span>
          Just log in and start browsing. No limitations.
        </span>
      </li>
      <li>
        <b-img :src="require('../../assets/images/logo-small.svg')"></b-img>
        <span>
          MyDello logistics experts are always here to support you.
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'ConfirmEmailOtherHalf'
}
</script>
<style scoped lang="scss">
ul {
  li {
    padding: 5px 0px;
    color: #000027;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    position: relative;
    display: flex;
    align-content: center;
    padding-left: 35px;

    img {
      position: absolute;
      left: 0;
    }
  }
}
ul.no-bullets {
  list-style-type: none; /* Remov bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.welcome_contet_wrap {
  position: relative;
  z-index: 250;
  h4 {
    color: #000027;
    font-size: 28px;
    line-height: 38px;
  }
}
</style>
