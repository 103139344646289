import { RouteConfig } from 'vue-router/types/router'
import ConfirmEmailRegistration from '@/views/confirm-email/index.vue'
import LoginRegistration from '@/views/landing-page/loginAndRegistration/index.vue'
import { loginRegistrationRoute } from '@/views/landing-page/loginAndRegistration/route'
import { ConfirmRegistrationRoute } from '@/views/confirm-email/route'

export const landingPageRoute: Array<RouteConfig> = [
  {
    path: '',
    component: LoginRegistration,
    children: loginRegistrationRoute
  },
  {
    path: 'forgot-password',
    name: 'Forgot Password',
    component: () => import('../../views/landing-page/ForgotPassword.vue')
  },
  {
    path: 'reset-password',
    name: 'Reset Password',
    component: () => import('../../views/landing-page/ResetPassword.vue')
  },
  {
    path: 'sign-up/register-successfully',
    name: 'Sign Up Successfully',
    component: () =>
      import(
        '../../views/landing-page/loginAndRegistration/registration/RegisterSuccessfully.vue'
      )
  },
  {
    path: 'create-user',
    name: 'Create User',
    component: () => import('../../views/CreateCompanyContactUser.vue')
  },
  {
    path: 'confirm-email',
    component: ConfirmEmailRegistration,
    children: ConfirmRegistrationRoute
  }
]
