import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators'
import store from '@/store'
import { ExtraFieldTypesService } from '@/services/settings/extra-field-types'
import { IExtraFieldType } from '@/models'

@Module({ dynamic: true, store, name: 'extra-field-types', namespaced: true })
class ExtraFieldTypes extends VuexModule {
  public extraFieldTypes: IExtraFieldType[] = []

  @Mutation
  SET_EXTRA_FIELD_TYPES(extraFieldTypes: IExtraFieldType[]) {
    this.extraFieldTypes = extraFieldTypes
  }

  @Action
  public async getExtraFieldTypes() {
    const {
      status,
      data: extraFieldTypes
    } = await new ExtraFieldTypesService().getExtraFieldType<
      IExtraFieldType[]
    >()

    if (status) {
      this.SET_EXTRA_FIELD_TYPES(extraFieldTypes)
    }
  }
}

export const ExtraFieldTypesModule = getModule(ExtraFieldTypes)
