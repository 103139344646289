import { AxiosRequestConfig } from 'axios'
import { IParams } from '@/models/params'

export const customizeParams = (params: IParams): AxiosRequestConfig => {
  const obj: AxiosRequestConfig = {}
  if (params) {
    obj.params = new URLSearchParams()
    // for filter
    if (params.filters && params.filters.length > 0) {
      const values: Array<string> = []
      params.filters.forEach(elem => {
        const operator = elem.operator ? elem.operator : '=='
        values.push(`${elem.data.key}${operator}${elem.data.value}`)
      })
      obj.params.append('filters', values.join(','))
    }
    // for sorting
    if (params.sorts && params.sorts.length > 0) {
      const values: Array<string> = []
      params.sorts.forEach(elem => {
        values.push(`${elem}`)
      })
      obj.params.append('sorts', values.join(','))
    }
    // for page
    if (params.page) {
      obj.params.append('page', params.page)
    }
    // for page size
    if (params.pageSize) {
      obj.params.append('pageSize', params.pageSize)
    }
  }
  return obj
}
