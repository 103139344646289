import { extend } from 'vee-validate'
import validator from 'validator'

export const email = extend('email_allow_uni_code', {
  message: field => `The ${field} field must be a valid email`,
  validate: (value: string | Array<string>) => {
    const emailAddress = Array.isArray(value) ? value[value.length - 1] : value
    return validator.isEmail(emailAddress)
  },
  params: ['data']
})
