import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'
import { IParams } from '@/models'
import { customizeParams } from '@/utils/service-helper'

export class ExtraFieldTypesService {
  async getExtraFieldType<T>(
    params: IParams = { pageSize: 1000 }
  ): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        `/settings/extra-field-type`,
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new ExtraFieldTypesService()
