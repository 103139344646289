import { extend } from 'vee-validate'

export const fileSize = extend('maxFileSize', {
  message: (field, params) => {
    return `File size exceeds the maximum limit of ${params.length} MB`
  },
  validate: (file: File, args: any) => {
    // prettier-ignore
    /* eslint-disable */
    /** Converting bytes into MB */
    if (file.size / 1024 / 1024 > args.length) return false
    else return true
  },
  params: ['length']
})
