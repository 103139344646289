import API from '@/http/http-common'
import { IError, IParams } from '@/models'
import { ServiceResponse } from '@/services/service-response'
import { customizeParams } from '@/utils/service-helper'
export class ShipmentService {
  async getAllShipment<T>(params: IParams = {}): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get('/shipment', queryParams)
      return {
        status: true,
        data: res.data,
        totalCount: res.headers['x-total-count']
          ? res.headers['x-total-count']
          : ''
      }
    } catch (e) {
      return {
        status: false,
        data: {} as T,
        totalCount: 0
      }
    }
  }

  async getSingleShipment<T>(shipmentId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(`/shipment/${shipmentId}`)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async getGroupedDataWithCoordinates<T>(
    params: IParams = {}
  ): ServiceResponse<T> {
    try {
      const queryParams = customizeParams(params)
      const res = await API.externalInstance.get(
        `shipment/group-data-with-coordinates`,
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return {
        status: false,
        data: {} as T
      }
    }
  }

  async getDistinctShipperContact<T>(params: IParams = {}): ServiceResponse<T> {
    try {
      let queryParams = customizeParams(params)
      if (Object.keys(params).length === 0) {
        params = {
          pageSize: 10000,
          sorts: ['-count']
        }
        queryParams = customizeParams(params)
      }
      const res = await API.externalInstance.get(
        `/shipment/shipper/distinct`,
        queryParams
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async getDistinctConsigneeContact<T>(
    params: IParams = {}
  ): ServiceResponse<T> {
    try {
      let queryParams = customizeParams(params)
      if (Object.keys(params).length === 0) {
        params = {
          pageSize: 10000,
          sorts: ['-count']
        }
        queryParams = customizeParams(params)
      }
      const res = await API.externalInstance.get(
        `/shipment/consignee/distinct`,
        queryParams
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async downloadCSVFile<T>(params: IParams = {}): ServiceResponse<T> {
    try {
      const queryParams = customizeParams(params)
      queryParams.responseType = 'blob'
      const res = await API.externalInstance.get(
        `/shipment/download-csv-file`,
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async downloadExcelFile<T>(params: IParams = {}): ServiceResponse<T> {
    try {
      const queryParams = customizeParams(params)
      queryParams.responseType = 'blob'
      const res = await API.externalInstance.get(
        `/shipment/download-excel-file`,
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async createShipmentFromQuote<T>(quoteId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/shipment/create-from-quote/${quoteId}`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   * Approve shipment
   * @param {string} shipmentId
   */
  async approveShipment<T>(shipmentId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(
        `/shipment/${shipmentId}/approve`
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError
      return { status: false, data: {} as T, errorMsg: error.data.title }
    }
  }

  /**
   * Approve other solution
   * @param {string} shipmentId
   */
  async approveOtherSolution<T>(
    shipmentId: string,
    solutionId: string
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(
        `/shipment/${shipmentId}/approve-other-solution`,
        solutionId
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError
      return { status: false, data: {} as T, errorMsg: error.data.title }
    }
  }

  /**
   * Reject shipment
   * @param {string} shipmentId
   */
  async rejectShipment<T>(
    shipmentId: string,
    reason: string
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.delete(
        `/shipment/${shipmentId}/reject`,
        {
          data: reason
        }
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError
      return { status: false, data: {} as T, errorMsg: error.data.title }
    }
  }

  /**
   * get shipment prices
   * @param {string} shipmentId
   */
  async getShipmentPrices<T>(shipmentId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/shipment/${shipmentId}/prices`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new ShipmentService()
