import { IContactFormEntity } from '../../../models/inquiry'
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action
} from 'vuex-module-decorators'
import store from '@/store'

import { initialContact, UserTypeEnum } from '@/utils/constants'
import { extractCountryCode } from '@/utils/global-setting'
import { ISelectOption } from '@/models'

/**
  * @module ContactInfo

 */
@Module({ dynamic: true, store, name: 'contact-info', namespaced: true })
class ContactInfo extends VuexModule {
  private shipperCountryCode = 'EE'
  private consigneeCountryCode = 'EE'

  private shipperUserType = UserTypeEnum.Company
  private consigneeUserType = UserTypeEnum.Company
  private consignee: IContactFormEntity = { ...initialContact }
  private shipper: IContactFormEntity = { ...initialContact }
  private shipperIndex = 0
  private consigneeIndex = 0
  private shipperCompanyContactName: ISelectOption = {} as ISelectOption
  private consigneeCompanyContactName: ISelectOption = {} as ISelectOption

  private shipperCheckBox = false
  private consigneeCheckBox = false
  private shipperAddressBookCheckBox = false
  private consigneeAddressBookCheckBox = false

  @Mutation
  SET_CONSIGNEE(consignee: IContactFormEntity) {
    this.consignee = { ...consignee }
  }

  @Mutation
  SET_SHIPPER(shipper: IContactFormEntity) {
    this.shipper = { ...shipper }
  }

  @Mutation
  SET_SHIPPER_USER_TYPE(userType: UserTypeEnum) {
    this.shipperUserType = userType
  }

  @Mutation
  SET_CONSIGNEE_USER_TYPE(userType: UserTypeEnum) {
    this.consigneeUserType = userType
  }

  @Mutation
  SET_SHIPPER_COMPANY_CONTACT_NAME(option: ISelectOption) {
    this.shipperCompanyContactName = { ...option }
  }

  @Mutation
  SET_CONSIGNEE_COMPANY_CONTACT_NAME(option: ISelectOption) {
    this.consigneeCompanyContactName = { ...option }
  }

  @Mutation
  SET_SHIPPER_CHECKBOX(checked: boolean) {
    this.shipperCheckBox = checked
  }

  @Mutation
  SET_CONSIGNEE_CHECKBOX(checked: boolean) {
    this.consigneeCheckBox = checked
  }

  @Mutation
  SET_SHIPPER_ADDRESS_BOOK_CHECKBOX(checked: boolean) {
    this.shipperAddressBookCheckBox = checked
  }

  @Mutation
  SET_CONSIGNEE_ADDRESS_BOOK_CHECKBOX(checked: boolean) {
    this.consigneeAddressBookCheckBox = checked
  }

  @Mutation
  SET_SHIPPER_INDEX() {
    this.shipperIndex++
  }

  @Mutation
  SET_CONSIGNEE_INDEX() {
    this.consigneeIndex++
  }

  @Mutation
  SET_COUNTRY_CODE_OF_SHIPPER(originCountryCode: string) {
    if (this.shipper && this.shipper.phone) {
      this.shipperCountryCode = extractCountryCode(this.shipper.phone)
    } else {
      this.shipperCountryCode = originCountryCode
    }
  }

  @Mutation
  SET_COUNTRY_CODE_OF_CONSIGNEE(destinationCountryCode: string) {
    if (this.consignee && this.consignee.phone) {
      this.consigneeCountryCode = extractCountryCode(this.consignee.phone)
    } else {
      this.consigneeCountryCode = destinationCountryCode
    }
  }

  @Mutation
  RESET_FORM_STATE() {
    this.shipperCountryCode = 'EE'
    this.consigneeCountryCode = 'EE'

    this.shipperUserType = UserTypeEnum.Company
    this.consigneeUserType = UserTypeEnum.Company
    this.consignee = { ...initialContact }
    this.shipper = { ...initialContact }
    this.shipperIndex = 0
    this.consigneeIndex = 0

    this.shipperCheckBox = false
    this.consigneeCheckBox = false
    this.shipperAddressBookCheckBox = false
    this.consigneeAddressBookCheckBox = false
    this.shipperCompanyContactName = {}
    this.consigneeCompanyContactName = {}
  }

  @Action({ rawError: true })
  resetFormData() {
    this.RESET_FORM_STATE()
  }
}
export const ContactInfoFormModule = getModule(ContactInfo)
