import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators'
import store from '@/store'
import { ICustomerAPIAccount } from '@/models'
import { CustomerAPIAccountService } from '@/services/settings/customer-api-account'

@Module({
  dynamic: true,
  store,
  name: 'customer-api-account',
  namespaced: true
})
class CustomerAPIAccount extends VuexModule {
  private customerAPIAccountService = new CustomerAPIAccountService()
  public customerAPIAccount: ICustomerAPIAccount = {} as ICustomerAPIAccount
  public isAllowToAccess: boolean = false

  @Mutation
  SET_CUSTOMER_API_ACCOUNT(customerAPIAccount: ICustomerAPIAccount) {
    this.customerAPIAccount = customerAPIAccount
  }

  @Mutation
  SET_IS_ALLOW_TO_ACCESS(flag: boolean) {
    this.isAllowToAccess = flag
  }

  @Action({ rawError: true })
  public async getCustomerAPIAccount(companyId: string) {
    const {
      status,
      data
    } = await this.customerAPIAccountService.getCustomerAPIAccount<
      ICustomerAPIAccount
    >(companyId)
    if (status) {
      this.SET_IS_ALLOW_TO_ACCESS(true)
      this.SET_CUSTOMER_API_ACCOUNT(data)
    } else {
      this.SET_IS_ALLOW_TO_ACCESS(false)
      this.SET_CUSTOMER_API_ACCOUNT({} as ICustomerAPIAccount)
    }
  }
}

export const CustomerAPIAccountModule = getModule(CustomerAPIAccount)
