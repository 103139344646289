import API from '@/http/http-common'
import { IError, IParams } from '@/models'
import { ServiceResponse } from '@/services/service-response'
import { customizeParams } from '@/utils/service-helper'
import { Operation } from 'rfc6902'

/**
 *
 *
 * @export
 * @class InquiryService
 */
export class InquiryService {
  /**
   *
   *
   * @template T
   * @param {string} id
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async getInquiryById<T>(id: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(`/inquiry/${id}`)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {IParams} [params={}]
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async getInquiry<T>(params: IParams = {}): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(`/inquiry`, queryParams)
      return {
        status: true,
        data: res.data,
        totalCount: res.headers['x-total-count']
          ? res.headers['x-total-count']
          : ''
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async patchInquiry<T>(
    id: string | undefined,
    patch: Operation[]
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.patch(`inquiry/${id}`, patch)
      return { status: true, data: res.data.value }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {*} inquiryPayload
   * @param {*} requestMethod
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async postInquiry<T>(
    inquiryPayload: any,
    requestMethod: string | number
  ): Promise<ServiceResponse<T>> {
    try {
      const res = await API.externalInstance[requestMethod](
        '/inquiry',
        inquiryPayload
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      const error = e as IError
      return { status: false, data: {} as T, errorMsg: error.data.title }
    }
  }

  /**
   *
   *
   * @template T
   * @param {*} updatedInquiry
   * @return {*}
   * @memberof InquiryService
   */
  async updateInquiry<T>(updatedInquiry) {
    try {
      const res = await API.externalInstance.put('/inquiry', updatedInquiry)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}
export default new InquiryService()
