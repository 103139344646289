var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sideMenu d-flex h-100"},[_c('aside',[_c('div',{staticClass:"sidebar left",class:{ fliph: _vm.active }},[_c('ul',{staticClass:"list-sidebar"},[_c('b-nav-item',{staticClass:"top-logo",attrs:{"id":"home-btn","to":"/","active":_vm.mainNavActive},on:{"click":function($event){return _vm.onMainLogoClick()}}},[_c('span',{staticClass:"logo-icon"},[_c('i',[_c('img',{attrs:{"src":require("../../assets/images/menu-logo.svg")}})])]),_c('span',{staticClass:"nav-label"}),_c('span',{staticClass:"side-logo"},[_c('i',[_c('img',{attrs:{"src":require("../../assets/images/logo-white.svg")}})])]),(_vm.active)?_c('b-tooltip',{attrs:{"target":"home-btn","title":"Home","placement":"right","triggers":"hover"}}):_vm._e()],1),_vm._l((_vm.navs),function(nav){return _c('b-nav-item',{directives:[{name:"show",rawName:"v-show",value:(
            nav.name == 'Waiting for approval' ? _vm.showWaitingForApproval : true
          ),expression:"\n            nav.name == 'Waiting for approval' ? showWaitingForApproval : true\n          "}],key:nav.name,class:{
            badge_pointer:
              nav.name == `Waiting for approval` &&
              _vm.totalCountWaitingForApproval
          },attrs:{"active":nav.active,"id":nav.tooltipText},on:{"click":function($event){return _vm.onClick(nav.name, nav.link)}}},[_c('i',[_c('img',{attrs:{"src":_vm.getImageUrl(nav.imageName)}}),(
                nav.name == 'Waiting for approval' &&
                  _vm.totalCountWaitingForApproval
              )?_c('b-badge',{staticClass:"badge_red",attrs:{"variant":"danger"}},[_vm._v(_vm._s(_vm.totalCountWaitingForApproval)+" ")]):_vm._e()],1),_c('span',{staticClass:"nav-label",class:nav.class},[_vm._v(_vm._s(nav.name)+" ")]),(_vm.active)?_c('b-tooltip',{attrs:{"target":nav.tooltipText,"title":nav.tooltipText,"placement":"right","triggers":"hover"}}):_vm._e()],1)}),_c('li',{staticClass:"nav-item logout",attrs:{"role":"button"},on:{"click":function($event){return _vm.logout()}}},[_c('span',{staticClass:"nav-label"},[_c('figure',{staticClass:"user_thumb"},[_c('svg',{staticClass:"bi-person-fill mr-2 b-icon bi",attrs:{"data-v-1db47772":"","viewBox":"0 0 16 16","width":"1em","height":"1em","focusable":"false","role":"img","aria-label":"person fill","aria-hidden":"true","xmlns":"http://www.w3.org/2000/svg","fill":"currentColor"}},[_c('g',{attrs:{"data-v-1db47772":""}},[_c('path',{attrs:{"d":"M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"}})])])]),_c('span',{staticClass:"userName"},[_vm._v(_vm._s(_vm.user && _vm.user.contact && _vm.user.contact.fullName ? _vm.user.contact.fullName : ''))]),_c('b-nav-item',[_c('span',{staticClass:"nav-label"},[_vm._v("Log out")])])],1)])],2)])]),_c('span',{staticClass:"sidemenu-btn",attrs:{"aria-pressed":_vm.active ? 'true' : 'false'},on:{"click":_vm.onClickSideMenuBtn}},[(_vm.active)?_c('div',{staticClass:"h5 mb-2"},[_c('b-icon-chevron-right')],1):_vm._e(),(!_vm.active)?_c('div',{staticClass:"h5 mb-2"},[_c('b-icon-chevron-left')],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }