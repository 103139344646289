import router from '@/router'
import { AuthModule } from '@/store/modules/auth'
import { TemplateModule } from '@/store/modules/template'

export const requestConfig = (config: any) => {
  const token = AuthModule.token
  if (token && config.url !== '/identity/authentication/password-login') {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}

export const requestError = (error: any) => {
  TemplateModule.toggleLoader(false)
  return Promise.reject(error)
}

export const responseConfig = (config: any) => {
  if (config.status === 200 || config.status === 201 || config.status === 204) {
    return Promise.resolve(config)
  } else {
    TemplateModule.toggleLoader(false)
    return Promise.reject(config)
  }
}

export const responseError = async (err: any) => {
  TemplateModule.toggleLoader(false)
  if (!window.navigator.onLine) {
    alert('You are offline')
  }
  if (err.response.status) {
    switch (err.response.status) {
      case 400:
        //do something
        break

      case 401:
        //unauthorized token when token expires
        await AuthModule.Logout()
        break
      case 403:
        if (
          typeof err.response.config.headers.AllowForbidden === 'undefined' &&
          !err.response.config.headers.AllowForbidden
        ) {
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          })
        }
        break
      case 429:
        alert(
          'Requests limit reached, please wait 15 minutes or contact with MyDello support'
        )
        location.reload()
        break
      case 404:
        //do something
        break
      case 504:
        alert('something went wrong!')
        router.replace({ path: '/login' })
        break
      case 502:
        setTimeout(() => {
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
        }, 1000)
    }
    return Promise.reject(err.response)
  }
}
