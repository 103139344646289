import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Main from '@/views/Main.vue'
import TermsAndService from '@/views/terms-and-service/index.vue'
import { termsAndServiceRoutes } from '@/views/terms-and-service/routes'
import Settings from '@/views/settings/index.vue'
import { settingsRoute } from '@/views/settings/routes'
import LandingPage from '@/views/landing-page/index.vue'
import { landingPageRoute } from '@/views/landing-page/routes'
import ShipmentDetail from '@/views/shipment-detail/index.vue'
import { shipmentDetailsRoute } from '@/views/shipment-detail/routes'
import { beforeEnter as homeBeforeEnter } from '@/guards/home'
import { beforeEnter as shipmentBeforeEnter } from '@/guards/shipment'
import { beforeEnter as shipmentDetailBeforeEnter } from '@/guards/shipmentDetail'
import { Component } from 'vue-property-decorator'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: Main,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        beforeEnter: homeBeforeEnter
      },
      {
        path: '/my-shipment',
        name: 'My Shipment',
        component: () => import('../views/Home.vue'),
        beforeEnter: shipmentBeforeEnter
      },
      {
        path: '/waiting-for-approval',
        name: 'WaitingForApproval',
        component: () => import('../views/WaitingForApproval.vue')
      },
      {
        path: '/shipment/:id',
        name: 'Shipment',
        component: ShipmentDetail,
        children: shipmentDetailsRoute,
        beforeEnter: shipmentDetailBeforeEnter,
        redirect: '/shipment/:id/details'
      },
      {
        path: '/invoices',
        name: 'Invoices',
        component: () => import('../views/Invoices.vue')
      },
      {
        path: '/my-reports',
        name: 'My Reports',
        component: () => import('../views/MyReports.vue')
      },
      {
        path: '/inquiry',
        name: 'Inquiry',
        component: () => import('../views/inquiry/index.vue')
      },
      {
        path: '/information',
        component: TermsAndService,
        children: termsAndServiceRoutes
      },
      {
        path: '/settings',
        component: Settings,
        children: settingsRoute
      },
      {
        path: '/new-inquiry',
        name: 'NewInquiry',
        component: () => import('../views/inquiry/new-inquiry.vue')
      },
      {
        path: '/inquiry/create/:inquiryId',
        name: 'CreateInquiry',
        component: () => import('../views/inquiry/new-inquiry.vue')
      },
      {
        path: '/inquiry/update/:inquiryId',
        name: 'UpdateInquiry',
        component: () => import('../views/inquiry/new-inquiry.vue')
      },
      {
        path: '/inquiry/shipment/:shipmentId',
        name: 'ShipmentInquiry',
        component: () => import('../views/inquiry/new-inquiry.vue')
      }
    ]
  },
  {
    path: '/confirm-tracking',
    name: 'ConfirmTracking',
    component: () => import('../views/UpdateShipmentTracking.vue')
  },
  {
    path: '/terms-and-condition',
    name: 'Information',
    component: () => import('../views/terms-and-service/Information.vue')
  },

  {
    path: '/company-registration',
    name: 'Company Registration',
    component: () => import('../views/CompanyRegistration.vue')
  },
  // login, forgot password and reset password
  {
    path: '',
    component: LandingPage,
    children: landingPageRoute
  },
  // no path is match redirect to the home page
  {
    path: '*',
    redirect: '/'
  }
]

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
])

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      const position: any = {}
      if (document.querySelector(to.hash)) {
        position.selector = to.hash
        if (to.name === 'Information' || to.name === 'Terms And Condition') {
          position.offset = { y: 85 }
        }
        return position
      }
      return false
    }
    return { x: 0, y: 0 }
  }
})

export default router
