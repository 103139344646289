import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators'
import store from '@/store'
import { CurrencyService } from '@/services/settings/currency'
import { ICurrency, IParams } from '@/models'

@Module({ dynamic: true, store, name: 'currency', namespaced: true })
class Currency extends VuexModule {
  private currencies: ICurrency[] = []

  @Mutation
  SET_CURRENCIES(currencies: ICurrency[]) {
    this.currencies = currencies
  }

  @Action
  public async getAllCurrencies() {
    const { status, data } = await new CurrencyService().getAllCurrency<
      ICurrency[]
    >({ pageSize: 1000 } as IParams)
    if (status) {
      this.SET_CURRENCIES(data)
    }
  }
}

export const CurrencyModule = getModule(Currency)
