<template>
  <div class="container bg-white shipping_wrap">
    <b-row v-if="isMobile()" class="shipment_mobile_top_bar">
      <b-col class="s_col">
        <span>Shipment #</span><span>{{ shipment.referenceId }}</span>
      </b-col>
      <b-col class="s_col">
        <span>{{
          shipmentStatus[shipment.trackingStatus]
            ? shipmentStatus[shipment.trackingStatus]
            : 'New'
        }}</span>
      </b-col>
      <b-col class="s_col">
        <app-shipment-delivered-at :shipment="shipment" />
      </b-col>
    </b-row>
    <b-row class="m-0 p-3 shippment_details_view">
      <b-col class="d-flex shipping_icon">
        <div>
          <app-freight-mode-icon
            :freightModeCode="shipment.freightModeCode"
          ></app-freight-mode-icon>
        </div>
        <b-col>
          <div>
            <app-freight-mode-description
              :data="{
                freightModeCode: shipment.freightModeCode,
                freightModeSubTypeName: shipment.freightModeSubtypeName
              }"
            ></app-freight-mode-description>
          </div>
          <div v-if="!isMobile()" class="transit-time bg-secondary my-2">
            {{
              shipment.transitTimePrognosedMin
                ? shipment.transitTimePrognosedMin
                : 'N/A'
            }}
            -
            {{
              shipment.transitTimePrognosedMdn
                ? shipment.transitTimePrognosedMdn
                : 'N/A'
            }}
            {{ transitTime }}
          </div>
          <div v-if="isMobile()">
            <span>
              {{
                address.shipmentOriginAddress
                  ? address.shipmentOriginAddress.city
                  : ''
              }}
            </span>
            to
            <span>
              {{
                address.shipmentDestinationAddress
                  ? address.shipmentDestinationAddress.city
                  : ''
              }}
            </span>
          </div>
        </b-col>
      </b-col>
      <b-col class="d-flex justify-content-center shipStatus_wrap">
        <div class="shipStatus">
          <span class="bTitle">Status</span>
          <span class="statusType bg-cl-in-transit"
            >{{
              shipmentStatus[shipment.trackingStatus]
                ? shipmentStatus[shipment.trackingStatus]
                : 'New'
            }}
          </span>
        </div>
      </b-col>
      <b-col class="d-flex justify-content-end estimates_cost">
        <div class="border rounded" v-if="!shipmentCompanyIsAVendorCompany">
          <b-row>
            <div class="m-2 px-4">
              <span class="bTitle pt-3">
                {{
                  shipmentStatus[shipment.status] === shipmentStatus.Invoiced ||
                  shipmentStatus[shipment.status] ===
                    shipmentStatus.InvoiceIssued
                    ? 'Total cost'
                    : 'Estimated cost'
                }}</span
              >
            </div>
            <div class="m-2 px-4">
              <b-row class="justify-content-around">
                <span :style="{ 'font-size': '1.5em' }"
                  >{{ shipment.companyCostTotal }}
                  {{ shipment.currencySymbol }}</span
                >
              </b-row>
              <b-row>
                <span class="bTitle"
                  >+ VAT
                  {{ shipment.companyCostVatTotal }}
                  {{ shipment.currencySymbol }}
                </span>
              </b-row>
            </div>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="showTracking && !isMobile()" class="b-bottom pb-0">
      <b-col>
        <app-copied-url :referenceId="shipment.referenceId" />
        <app-shipment-tracking
          :shipmentRoute="route"
          :shipmentRouteLegs="routeLegs"
        ></app-shipment-tracking>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import ShipmentTracking from '@/components/shipment/ShipmentTracking.vue'
import FreightModeIcon from '@/components/freight-mode/FreightModeIcon.vue'
import FreightModeDescription from '@/components/freight-mode/FreightModeDescription.vue'
import {
  IDetailAddress,
  IShipment,
  IShipmentRouteLeg,
  IShipmentTrackingRoute
} from '@/models'
import { ShipmentStatus } from '@/utils/constants'
import { getTransitTime } from '@/utils/global-setting'
import { Component, Mixins } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { MobileDetectingMixin } from '@/mixins'
import ShipmentDeliveredAt from './ShipmentDeliveredAt.vue'
import CopiedUrl from '../shared/CopiedUrl.vue'
import { AuthModule } from '@/store/modules'

@Component({
  name: 'shipment-core-info',
  components: {
    appShipmentTracking: ShipmentTracking,
    appFreightModeIcon: FreightModeIcon,
    appFreightModeDescription: FreightModeDescription,
    appShipmentDeliveredAt: ShipmentDeliveredAt,
    appCopiedUrl: CopiedUrl
  },
  computed: {
    ...mapState('shipment-detail', [
      'shipment',
      'route',
      'routeLegs',
      'showTracking'
    ]),
    ...mapGetters('shipment-detail', {
      address: 'getShipmentDetailAddress'
    })
  }
})
export default class ShipmentCoreInfo extends Mixins(MobileDetectingMixin) {
  /** Vue store binding start */
  private address!: IDetailAddress
  private shipment!: IShipment
  private route!: IShipmentTrackingRoute
  private routeLegs!: IShipmentRouteLeg[] | null
  private showTracking!: boolean
  /** Vue store binding start */

  //#region computed properties region start
  /**
   * Computed Properties
   */
  get shipmentStatus() {
    return ShipmentStatus
  }

  get transitTime() {
    return getTransitTime(this.shipment)
  }

  get shipmentCompanyIsAVendorCompany() {
    return AuthModule.getVendorCustomerCompanies.filter(
      x => x.id === this.shipment.companyId
    ).length > 0
      ? true
      : false
  }

  //#endregion computed properties region end
}
</script>

<style lang="scss">
.transit-time {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 13px;
  color: white;
  border-radius: 50px;
  min-width: 40%;
  width: auto;
}
</style>
