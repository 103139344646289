import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators'
import store from '@/store'
import { ICountry, IParams } from '@/models'
import { CountryService } from '@/services/settings/country'

@Module({ dynamic: true, store, name: 'country', namespaced: true })
class Country extends VuexModule {
  private countries: ICountry[] = []

  @Mutation
  SET_COUNTRIES(countries: ICountry[]) {
    this.countries = [...countries]
  }

  @Action
  public async getAllCountries() {
    const params: IParams = {
      sorts: ['name'],
      pageSize: 1000
    }
    const { status, data } = await new CountryService().getAllCountries<
      ICountry[]
    >(params)
    if (status) {
      this.SET_COUNTRIES(data)
    }
  }
}

export const CountryModule = getModule(Country)
