import { AuthModule } from '@/store/modules'
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export class OnBehalfOfCustomerMixin extends Vue {
  onBehalfOfCustomer = (hasSingleCompany: boolean, companyId: string) => {
    if (!hasSingleCompany && companyId) {
      const vendorCustomerCompanies = AuthModule.getVendorCustomerCompanies.filter(
        x => x.id === companyId
      )
      return vendorCustomerCompanies && vendorCustomerCompanies.length > 0
        ? true
        : false
    } else {
      return false
    }
  }
}
