<template>
  <b-form-select
    :options="companies"
    :value="value"
    @input="updateUserCompanyId"
    autofocus
    :class="{
      'border border-danger': required,
      disabled: readOnly
    }"
  >
    <template v-if="isAllowNull" v-slot:first>
      <b-form-select-option :value="null">{{
        nullValueContent
      }}</b-form-select-option>
    </template>
  </b-form-select>
</template>

<script lang="ts">
import { IMultiSelect } from '@/models/multi-select'
import { getUserCompanies } from '@/utils/global-setting'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class UserCompaniesDropDown extends Vue {
  @Prop({ default: false, type: Boolean }) required!: boolean

  @Prop({ default: false, type: Boolean }) readOnly!: boolean

  @Prop({ required: true }) value!: string

  @Prop({ type: Boolean, default: true }) isAllowNull!: boolean

  @Prop({
    type: String,
    default: () => {
      return '-- Please select company name --'
    }
  })
  nullValueContent!: string

  @Prop({
    type: Array,
    default: () => {
      return getUserCompanies()
    }
  })
  companies!: Array<IMultiSelect>

  updateUserCompanyId(value: any) {
    this.$emit('input', value)
  }
}
</script>
<style scoped lang="scss"></style>
