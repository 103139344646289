import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Route } from 'vue-router'
import { AuthModule } from '@/store/modules/auth'
import Vue from 'vue'
import { removeChatInfo, setChatInfo } from '@/utils/si-chat-helper'

NProgress.configure({ showSpinner: false })

const liveChat = (function() {
  const url = `${process.env?.VUE_APP_LIVE_CHAT_URL}`
  const vue = Vue as any
  return {
    // loading live chat script
    load: async () => {
      await vue.loadScript(url)
      setChatInfo(AuthModule.user.email || '', AuthModule.user.firstName || '')
    },
    unload: async () => {
      // during unloading hide social intent element
      // because script is already loaded and we don't
      // to show social intent if user is not login
      document.querySelector('#social-intents-tab-chat')?.remove()
      document.querySelector('#socialintents-chat')?.remove()
      document.querySelector('#sibutton')?.remove()
      document.querySelector('#siWidget-chat')?.remove()

      try {
        await vue.unloadScript(url)
        removeChatInfo()
      } catch (e) {
        console.log('unloading script not present')
      }
    }
  }
})()

const isExistInMainLandingPage = (to: { path: string }) => {
  if (
    to.path === '/login' ||
    to.path === '/forgot-password' ||
    to.path === '/reset-password' ||
    to.path === '/sign-up' ||
    to.path === '/sign-up/register-successfully' ||
    to.path === '/confirm-email' ||
    to.path === '/create-user' ||
    to.path === '/confirm-tracking'
  ) {
    return true
  }
}

router.beforeEach(async (to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start()
  // select html element
  const htmlTag = document.querySelector('html')

  // Determine whether the user has logged in
  if (AuthModule.token && AuthModule.user && AuthModule.user.contact) {
    if (
      to.name === 'Details' ||
      to.name === 'Messages' ||
      to.name === 'Attachments' ||
      to.name === 'Quotes Invoices'
    ) {
      await liveChat.unload()
    } else {
      await liveChat.load()
    }

    next()
    // add and remove specific class because of vertical scroll
    if (htmlTag) {
      htmlTag.classList.add('pg-80')
      htmlTag.classList.remove('pg-0')
    }
    if (htmlTag && isExistInMainLandingPage(to)) {
      htmlTag.classList.add('pg-0')
      htmlTag.classList.remove('pg-80')
    }
    NProgress.done()
  } else if (AuthModule.token && AuthModule.user && !AuthModule.user.contact) {
    if (to.path === '/company-registration' || to.path === '/login') {
      next()
    } else if (to.path === '/') {
      router.replace('/company-registration')
    } else {
      next()
    }
  } else {
    // Other pages that do not have permission to access are redirected to the login page.
    await liveChat.unload()
    if (
      isExistInMainLandingPage(to) ||
      to.path === '/company-registration' ||
      to.path === '/terms-and-condition'
    ) {
      next()
    } else {
      if (to.path === '/') {
        router.replace({ path: '/login' })
      } else {
        router.replace({
          path: '/login',
          query: { redirect: to.path }
        })
      }
    }
    // add and remove specific class because of vertical scroll
    if (htmlTag && to.path !== '/terms-and-condition') {
      htmlTag.classList.add('pg-0')
      htmlTag.classList.remove('pg-80')
    } else if (htmlTag && to.path === '/terms-and-condition') {
      htmlTag.classList.add('pg-80')
      htmlTag.classList.remove('pg-0')
    }
    NProgress.done()
  }
})

router.afterEach(() => {
  // Finish progress bar
  NProgress.done()
})
