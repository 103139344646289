<template>
  <div>
    <section v-if="!isMobile()" class="tracking-overview">
      <ol v-if="legs && legs.length" class="tracking-list is-flex">
        <!-- We need :first and :last-child for the title area logic so v-if separators -->
        <template v-for="(leg, index) in legs">
          <li
            class="title-area"
            :class="getTitleAreaClass(leg, index, legs)"
            :key="index"
          >
            <app-tracking-icon v-if="hasIcon(leg)" :name="getLegIcon(leg)" />
            <span v-else>{{ getLegName(leg) }}</span>
            <div class="description">{{ getLegTitle(leg, index, legs) }}</div>
          </li>
          <li
            v-if="index < legs.length - 1"
            role="presentation"
            class="separator"
            :class="{ 'is-done': isDepartedFromLeg(leg, index, legs) }"
            :key="`before_${index}`"
          />
          <li
            v-if="showDot(leg, index, legs)"
            role="presentation"
            class="current-mark is-done"
            :key="`current_${index}`"
          />
          <li
            v-if="index < legs.length - 1"
            role="presentation"
            class="separator"
            :class="{ 'is-done': isArrivedToNextLeg(leg, index, legs) }"
            v-bind:key="`after_${index}`"
          />
        </template>
      </ol>
      <p v-else>
        <strong>N/A</strong>
      </p>
      <p v-if="status" class="status-text float-left">{{ status }}</p>
    </section>

    <div v-if="isMobile()" class="inner_shipment_row">
      <div v-if="status" class="dat_time">{{ status }}</div>
      <ol v-if="legs && legs.length" class="shipment_timeline">
        <template v-for="(leg, index) in legs">
          <li class="inner_wrap" :key="index">
            <div
              class="status_type icon"
              :class="getTitleAreaClass(leg, index, legs)"
            >
              <!-- <i class="fa-dot-circle fas"></i> -->
              <app-tracking-icon v-if="hasIcon(leg)" :name="getLegIcon(leg)" />
              <span v-else>{{ getLegName(leg) }}</span>
            </div>
            <!-- <div class="status_info">PU done 31.20</div> -->
            <div class="status_info">{{ getLegTitle(leg, index, legs) }}</div>
          </li>
        </template>
      </ol>
      <p v-else>
        <strong>N/A</strong>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { IShipmentRouteLeg } from '@/models'
import moment from 'moment'
import { TrackingRouteLegType, RouteType } from '@/utils/constants'
import { ShipmentTrackingService } from '@/services/shipment/tracking'
import TrackingIcon from '@/components/icon/TrackingIcon.vue'
import { ShipmentTrackingHelper } from './helpers/shipment-tracking-helper'
import { MobileDetectingMixin } from '@/mixins'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    appTrackingIcon: TrackingIcon
  }
})
export default class ShipmentTracking extends Mixins(MobileDetectingMixin) {
  @Prop()
  private shipmentRoute
  @Prop() private shipmentRouteLegs
  private dateFormat = 'DD.MM'
  private shipmentTrackingService = new ShipmentTrackingService()
  private shipmentTrackingHelper = new ShipmentTrackingHelper()
  private isBullonSplit = false

  get route() {
    return this.shipmentRoute
  }

  get legs() {
    if (this.shipmentRouteLegs && this.shipmentRouteLegs.length > 0) {
      const shipmentRouteLegs: Array<IShipmentRouteLeg> = cloneDeep(
        this.shipmentRouteLegs
      )
      const mainFreigntRouteLegIndex = shipmentRouteLegs.findIndex(
        x =>
          x.type === TrackingRouteLegType.MainFreight &&
          x.toGatewayId === null &&
          x.fromGatewayId === null
      )
      if (mainFreigntRouteLegIndex !== -1) {
        const mainFreightRouteLeg = cloneDeep(
          shipmentRouteLegs.find(
            x =>
              x.type === TrackingRouteLegType.MainFreight &&
              x.toGatewayId === null &&
              x.fromGatewayId === null
          )
        )

        shipmentRouteLegs[mainFreigntRouteLegIndex].legShortTitle =
          shipmentRouteLegs[mainFreigntRouteLegIndex].fromAddressString
        if (shipmentRouteLegs[mainFreigntRouteLegIndex].startActualAt) {
          shipmentRouteLegs[mainFreigntRouteLegIndex].endActualAt =
            shipmentRouteLegs[mainFreigntRouteLegIndex].startActualAt
          shipmentRouteLegs[mainFreigntRouteLegIndex].isActive = false
        } else {
          shipmentRouteLegs[mainFreigntRouteLegIndex].isActive = true
          shipmentRouteLegs[mainFreigntRouteLegIndex].endPlannedAt =
            shipmentRouteLegs[mainFreigntRouteLegIndex].startPlannedAt
        }

        if (mainFreightRouteLeg) {
          mainFreightRouteLeg.rowOrder = 2
          mainFreightRouteLeg.legShortTitle =
            shipmentRouteLegs[mainFreigntRouteLegIndex].toAddressString
          if (mainFreightRouteLeg.endActualAt)
            mainFreightRouteLeg.isActive = false
          else mainFreightRouteLeg.isActive = true
          shipmentRouteLegs.splice(
            mainFreigntRouteLegIndex + 1,
            0,
            mainFreightRouteLeg as IShipmentRouteLeg
          )
          shipmentRouteLegs[shipmentRouteLegs.length - 1].rowOrder = 3
          this.isBullonSplit = true
        }
      }

      return shipmentRouteLegs
    } else {
      return this.shipmentRouteLegs
    }
  }

  get status() {
    if (!this.route?.legLastUpdatedAt) return null
    const lastUpdated = moment(this.route.legLastUpdatedAt).format(
      'DD.MM.YYYY HH:mm'
    )
    return `Updated ${lastUpdated}`
  }

  get activeLegIndex() {
    // Here and below, it's fine if we return a negative number, we simply don't render the mark then
    if (!this.legs) {
      return -1
    }
    const activeLeg = this.legs.find(leg => leg.isActive)
    if (activeLeg) {
      return this.legs.indexOf(activeLeg) - 1
    }
    return -1
  }

  get isPointToPoint() {
    return this.route?.shipmentRouteType === RouteType.PointToPoint
  }

  getTitleAreaClass(
    leg: IShipmentRouteLeg,
    index: number,
    legs: Array<IShipmentRouteLeg>
  ) {
    return {
      'is-square': this.hasIcon(leg),
      'is-done': index < this.activeLegIndex || this.isLegDone(leg, legs)
    }
  }

  hasIcon(leg: IShipmentRouteLeg) {
    return (
      leg.type === TrackingRouteLegType.PickUp ||
      leg.type === TrackingRouteLegType.Delivery ||
      leg.type === TrackingRouteLegType.DestinationGw
    )
  }

  getLegIcon(leg: IShipmentRouteLeg) {
    switch (leg.type) {
      case TrackingRouteLegType.PickUp:
        return 'dot-circle'
      case TrackingRouteLegType.DestinationGw:
        return 'passport'
      case TrackingRouteLegType.Delivery:
        return 'map-marker-alt'
      default:
        return ''
    }
  }

  getLegName = (leg: IShipmentRouteLeg) =>
    this.shipmentTrackingHelper.getLegName(leg)

  getLegTitle = (
    leg: IShipmentRouteLeg,
    index: number,
    legs: Array<IShipmentRouteLeg>
  ) =>
    this.shipmentTrackingHelper.getLegTitle(
      legs,
      leg,
      this.isPointToPoint,
      this.isBullonSplit,
      index
    )

  isDepartedFromLeg = (
    leg: IShipmentRouteLeg,
    index: number,
    legs: Array<IShipmentRouteLeg>
  ) =>
    this.shipmentTrackingHelper.isDepartedFromLeg(
      legs,
      leg,
      this.isPointToPoint,
      this.isBullonSplit,
      index
    )

  showDot(
    leg: IShipmentRouteLeg,
    index: number,
    legs: Array<IShipmentRouteLeg>
  ) {
    return (
      index === this.activeLegIndex &&
      this.isDepartedFromLeg(leg, index, legs) &&
      !this.isArrivedToNextLeg(leg, index, legs)
    )
  }

  isArrivedToNextLeg(
    leg: IShipmentRouteLeg,
    index: number,
    legs: Array<IShipmentRouteLeg>
  ) {
    const nextLeg = legs && legs[index + 1]

    switch (leg.type) {
      case TrackingRouteLegType.PickUp:
        return !!nextLeg?.startActualAt
      case TrackingRouteLegType.OriginGw:
        return !!nextLeg?.endActualAt
      case TrackingRouteLegType.MainFreight:
        if (this.isBullonSplit) {
          return !!nextLeg?.endActualAt
        }
        if (this.isPointToPoint) {
          return this.isLegDone(nextLeg, legs)
        }
        if (nextLeg?.type === TrackingRouteLegType.MainFreight) {
          return !!nextLeg?.endActualAt
        }
        if (nextLeg?.type === TrackingRouteLegType.DestinationGw) {
          return !!nextLeg.startActualAt
        }
        return !!leg.endActualAt
      default:
        return !!nextLeg?.endActualAt
    }
  }

  isLegDone = (leg: IShipmentRouteLeg, legs: Array<IShipmentRouteLeg>) =>
    this.shipmentTrackingHelper.isLegDone(
      leg,
      this.isPointToPoint,
      this.isBullonSplit,
      legs
    )
}
</script>
