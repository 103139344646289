import { AuthModule } from '@/store/modules'

export const beforeEnter = async (_to: any, _from: any, next: any) => {
  if (
    AuthModule.user &&
    AuthModule.user.contact &&
    AuthModule.user.contact.isCompanyOwner
  ) {
    next()
  } else {
    next('/settings/my-profile')
  }
}
