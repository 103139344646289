<template>
  <div class="container">
    <b-nav tabs fill class="navtabsMenu">
      <b-nav-item
        v-for="(n, index) of nav"
        :key="index"
        :to="n.link"
        :active="n.isActive"
        >{{ n.label }}</b-nav-item
      >
    </b-nav>
    <transition name="fade" mode="out-in">
      <router-view :key="$route.path" />
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class TermsAndService extends Vue {
  private nav: { label: string; link: string; isActive: boolean }[] = [
    {
      label: 'FAQ',
      link: '/information/faq',
      isActive: false
    },
    {
      label: 'Tutorials',
      link: '/information/tutorial',
      isActive: false
    },
    {
      label: 'About Us',
      link: '/information/about-us',
      isActive: false
    },
    {
      label: 'Terms & conditions',
      link: '/information/terms-and-condition',
      isActive: false
    },
    {
      label: 'Shipping to Amazon',
      link: '/information/shipping-to-amazon',
      isActive: false
    }
  ]
}
</script>

<style lang="scss"></style>
