import API from '@/http/http-common'
import { customizeParams } from '@/utils/service-helper'
import { IParams } from '@/models/params'
import { ServiceResponse } from '../service-response'

export class CurrencyService {
  async getAllCurrency<T>(params: IParams = {}): ServiceResponse<T> {
    const queryParams = customizeParams(params)

    try {
      const res = await API.externalInstance.get(
        `/settings/currency`,
        queryParams
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new CurrencyService()
