import API from '@/http/http-common'

import { ServiceResponse } from '@/services/service-response'
import { IParams, IQuoteCargoTypeUpdate, IRejectQuote } from '@/models'
import { customizeParams } from '@/utils/service-helper'

/**
 * QuoteService
 * @description A class which contains all the service related to quote
 */
export class QuoteService {
  /**
   * get quote from id
   * @param {string} quoteId
   */
  async getQuote<T>(quoteId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(`/quote/${quoteId}`)
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   * change the quote status to accepted
   * @param {string} quoteId
   */
  async acceptQuote<T>(quoteId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(`/quote/${quoteId}/accept`, {
        noShipment: false
      })
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   * generate pdf from the backend for any quote
   * @param {string} quoteId
   */
  async generatePdf<T>(quoteId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(`/quote/${quoteId}/pdf`)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   * change the quote status to rejected
   * @param {string} quoteId
   */
  async rejectQuote<T>(
    quoteId: string,
    payload: IRejectQuote
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(
        `/quote/${quoteId}/reject`,
        payload
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   * Get all the price breakdown options for specific quote on specific route
   * @param {string} quoteId
   */
  //  quoteId: string,
  //  routeId: string
  async getQuotePriceByRouteId<T>(
    quoteId: string,
    params: IParams = {}
  ): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        `quote/${quoteId}/prices`,
        queryParams
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   * Update cargo type of quote
   * @param {IQuoteCargoTypeUpdate} quoteCargoTypeUpdate
   */
  async updateCargoTypeOfQuote<T>(
    quoteCargoTypeUpdate: IQuoteCargoTypeUpdate
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(
        `quote/update-cargo-type`,
        quoteCargoTypeUpdate
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new QuoteService()
