import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import { ShipmentService } from '@/services/shipment/shipment'
import { ShipmentAddressesService } from '@/services/shipment/address'
import { ShipmentTrackingService } from '@/services/shipment/tracking'
import store from '@/store'
import {
  IDetailAddress,
  IShipment,
  IShipmentAddress,
  IShipmentRouteLeg,
  IShipmentTrackingRoute
} from '@/models'
import { AddressPoint, TrackingStatus } from '@/utils/constants'

@Module({ dynamic: true, store, name: 'shipment-detail', namespaced: true })
class ShipmentDetail extends VuexModule {
  constructor(
    private shipmentService: ShipmentService,
    private shipmentTrackingService: ShipmentTrackingService,
    private shipmentAddressService: ShipmentAddressesService
  ) {
    super(VuexModule)
    this.shipmentService = new ShipmentService()
    this.shipmentTrackingService = new ShipmentTrackingService()
    this.shipmentAddressService = new ShipmentAddressesService()
  }

  /** STATE */
  public shipmentAddress: Array<IShipmentAddress> = []
  public shipment: IShipment = {} as IShipment
  private route: IShipmentTrackingRoute = {} as IShipmentTrackingRoute
  private routeLegs: IShipmentRouteLeg[] | null = null
  private showTracking = false
  private isComingForShipmentDetailChat = false
  /** STATE */

  /** MUTATION */
  @Mutation
  private SET_IS_COMING_FOR_SHIPMENT_DETAIL_CHAT(isComing: boolean) {
    this.isComingForShipmentDetailChat = isComing
  }

  @Mutation
  private SET_SHIPMENT_ADDRESS(address: Array<IShipmentAddress>) {
    this.shipmentAddress = address
  }

  @Mutation
  public SET_SHIPMENT(shipment: IShipment) {
    this.shipment = shipment
  }

  @Mutation
  private SET_TRACKING_STATUS(shipment: IShipment) {
    if (shipment.trackingStatus === TrackingStatus.WaitingForConfirmation)
      this.showTracking = false
    else this.showTracking = true
  }

  @Mutation
  private SET_ROUTE(route: IShipmentTrackingRoute) {
    this.route = route
  }

  @Mutation
  private SET_ROUTE_LEG(routeLegs: IShipmentRouteLeg[] | null) {
    this.routeLegs = routeLegs
  }

  @Mutation
  public RESET_ALL() {
    this.shipmentAddress = []
    this.shipment = {} as IShipment
    this.isComingForShipmentDetailChat = false
  }
  /** MUTATION */

  /** ACTION */
  @Action({ rawError: true })
  public setIsComingForShipmentDetailChat(isComing: boolean) {
    this.SET_IS_COMING_FOR_SHIPMENT_DETAIL_CHAT(isComing)
  }

  @Action({ rawError: true })
  public async getSingleShipment(shipmentId: string) {
    const response = await this.shipmentService.getSingleShipment<IShipment>(
      shipmentId
    )
    if (response.status) {
      this.SET_SHIPMENT(response.data)
      this.SET_TRACKING_STATUS(response.data)
      this.SET_ROUTE({} as IShipmentTrackingRoute)
      this.SET_ROUTE_LEG(null)
      if (this.showTracking) {
        this.SET_ROUTE(response.data.shipmentTrackingRoute)
        this.SET_ROUTE_LEG(response.data.shipmentTrackingRouteLeg)
      }
    }
  }

  @Action({ rawError: true })
  public async getShipmentAddress(shipmentId: string) {
    const response = await this.shipmentAddressService.getShipmentAddress<
      Array<IShipmentAddress>
    >(shipmentId)
    if (response.status) {
      this.SET_SHIPMENT_ADDRESS(response.data)
    }
  }
  /** ACTION */

  /** GETTER */
  get getShipmentDetailAddress(): IDetailAddress {
    const shipmentOriginAddress = this.shipmentAddress.find(
      x => x.point === AddressPoint.Origin
    )
    const shipmentDestinationAddress = this.shipmentAddress.find(
      x => x.point === AddressPoint.Destination
    )
    const shipmentIncotermAddress = this.shipmentAddress.find(
      x => x.point === AddressPoint.Incoterm
    )
    return {
      shipmentOriginAddress,
      shipmentDestinationAddress,
      shipmentIncotermAddress
    }
  }
  /** GETTER */
}

export const ShipmentDetailModule = getModule(ShipmentDetail)
