import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators'
import {
  IAddress,
  ICargoType,
  IInquiryContact,
  IQuote,
  ISelectOption
} from '@/models'
import { QuoteService } from '@/services/quote/quote'
import { QuoteAddressService } from '@/services/quote/address'
import { QuoteRouteService } from '@/services/quote/route'
import { CargoTypeService } from '@/services/settings/cargo-type'
import { AddressPoint } from '@/utils/constants'
import { getAddress, getCommodityOptions, parseSolutionRoutes } from './helpers'
import store from '@/store'
import { isEmpty } from 'lodash'
import { QuoteContactService } from '@/services/quote/contacts'

const getFormattedAddress = (address: Partial<IAddress>) =>
  !isEmpty(address) ? `${address.city} ${address.countryName}` : ''

const getCountryCode = (address: Partial<IAddress>) =>
  !isEmpty(address) ? (address.countryIsoNameAlpha2 as string) : 'EE'

@Module({ dynamic: true, store, name: 'quote', namespaced: true })
class Quote extends VuexModule {
  private quoteService = new QuoteService()
  private quoteContactService = new QuoteContactService()
  private quoteAddressService = new QuoteAddressService()
  private quoteRouteService = new QuoteRouteService()

  private cargoTypeService = new CargoTypeService()
  public quote: Partial<IQuote> = {}
  public originAddress: Partial<IAddress> = {}
  public destinationAddress: Partial<IAddress> = {}
  public incotermAddress: Partial<IAddress> | null = {}
  public contacts: IInquiryContact[] = []
  public transitRout = ''
  public commodityOptions: ISelectOption[] = []
  public cargoTypeId = ''

  @Mutation
  SET_QUOTE(quote: IQuote): void {
    this.quote = quote
  }

  @Mutation
  SET_ADDRESSES(addresses: IAddress[]): void {
    this.originAddress = getAddress(addresses, AddressPoint.Origin)
    this.destinationAddress = getAddress(addresses, AddressPoint.Destination)
    this.incotermAddress = getAddress(addresses, AddressPoint.Incoterm)
  }

  @Mutation
  SET_CONTACTS(contacts: IInquiryContact[]): void {
    this.contacts = contacts
  }

  @Mutation
  SET_TRANSIT_ROUTE(transitRout: string): void {
    this.transitRout = transitRout
  }

  @Mutation
  SET_COMMODITY_OPTIONS(commodityOptions: ISelectOption[]) {
    this.commodityOptions = commodityOptions
  }

  @Mutation
  SET_COMMODITY(cargoTypeId: string) {
    this.cargoTypeId = cargoTypeId
  }

  @Action
  public async fetchQuoteAndDetails(quoteId: string): Promise<void> {
    try {
      const { data: quote } = await this.quoteService.getQuote<IQuote>(quoteId)
      const [contactsRes, addressesRes, gatewaysRes] = await Promise.all([
        this.quoteContactService.getQuoteContacts<IInquiryContact[]>(quoteId),
        this.quoteAddressService.getQuoteAddresses<IAddress[]>(quoteId),
        this.quoteRouteService.getRouteGateways(quote.activeRouteId)
      ])

      // get the cargo types for commodity dropdown list
      // after fetching the quote, get the commodity select menu options.
      const { data: cargoTypes } = await this.cargoTypeService.getCargoTypes<
        ICargoType[]
      >({ pageSize: 1000 })

      const commodityOptions = getCommodityOptions(
        cargoTypes,
        quote.freightModeId
      )

      const transitRoute = parseSolutionRoutes(
        quote.addressOrigin,
        quote.addressDestination,
        gatewaysRes.data
      )

      this.SET_QUOTE(quote)
      this.SET_ADDRESSES(addressesRes.data)
      this.SET_CONTACTS(contactsRes.data)
      this.SET_TRANSIT_ROUTE(transitRoute)
      this.SET_COMMODITY_OPTIONS(commodityOptions)
      this.SET_COMMODITY(quote.cargoTypeId)
    } catch (err) {
      console.log('ACTION ERROR: ', err)
    }
  }

  @Action
  public async fetchQuote(quoteId: string) {
    const { data: quote } = await this.quoteService.getQuote<IQuote>(quoteId)
    if (status) {
      this.SET_QUOTE(quote)
    }
  }

  @Action
  public async fetchContacts(quoteId: string): Promise<void> {
    try {
      const contacts = await this.quoteContactService.getQuoteContacts<
        IInquiryContact[]
      >(quoteId)
      this.SET_CONTACTS(contacts.data)
    } catch (err) {
      console.log('ERROR: ', err)
    }
  }

  get getOriginAddress(): string {
    return getFormattedAddress(this.originAddress)
  }

  get getDestinationAddress(): string {
    return getFormattedAddress(this.destinationAddress)
  }

  get getIncotermAddress(): string {
    return getFormattedAddress(this.incotermAddress as IAddress)
  }

  get totalVolume(): number {
    return this.quote.totalVolume || 0
  }

  get volumeUnit(): string {
    return this.quote.volumeUnitName || 'cbm'
  }

  get totalWeight(): number {
    return this.quote.totalWeight || 0
  }

  get weightUnit(): string {
    return this.quote.weightUnitName || 'kg'
  }

  get getCcommodityOptions(): ISelectOption[] {
    return this.commodityOptions
  }

  get originCountryCode(): string {
    return getCountryCode(this.originAddress)
  }

  get destinationCountryCode(): string {
    return getCountryCode(this.destinationAddress)
  }
}

export const QuoteModule = getModule(Quote)
