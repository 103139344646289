import { AuthModule } from '@/store/modules'
import { ICustomDirective } from '@/models'

export const RemoveForPublic: ICustomDirective = {
  id: 'remove-for-public',
  defination: {
    // When the bound element is inserted into the DOM...
    inserted: function(el, binding, vnode) {
      if (!AuthModule.user.isPrivatePerson) {
        vnode.elm?.parentElement?.removeChild(vnode.elm)
      }
    }
  }
}
