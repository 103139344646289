import API from '@/http/http-common'
import { IParams } from '@/models'
import { customizeParams } from '@/utils/service-helper'
import { ServiceResponse } from '../service-response'

export class UnitConversionService {
  async getAllUnitConversions<T>(
    params: IParams = { pageSize: 1000 }
  ): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        'settings/unit-conversion',
        queryParams
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async getAllUnits<T>(
    params: IParams = { pageSize: 1000 }
  ): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get('settings/unit', queryParams)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new UnitConversionService()
