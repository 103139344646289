import { GoogleMapModule } from '@/store/modules'
import axios from 'axios'

export class GoogleStaticMapService {
  public async getAndSetGoogleMapAllowOrNot() {
    try {
      const axiosResponse = await axios
        .create({
          baseURL: 'https://maps.googleapis.com'
        })
        .get(
          `maps/api/staticmap?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&size=612x361`
        )

      if (
        axiosResponse.status === 200 ||
        axiosResponse.status === 201 ||
        axiosResponse.status === 204
      ) {
        GoogleMapModule.toggleGoogleMap(true)
      } else {
        GoogleMapModule.toggleGoogleMap(false)
      }
    } catch (err) {
      GoogleMapModule.toggleGoogleMap(false)
    }
  }
}

export default new GoogleStaticMapService()
