import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export class TermsAndCondition extends Vue {
  public information: { label: string; link: object; isActive: boolean }[] = []

  getLabel(label: string) {
    return this.$t(`${label}`).toString()
  }

  mounted() {
    this.information = [
      {
        label: 'information.terms_and_condition.defination.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#defination'
        },
        isActive: false
      },
      {
        label: 'information.terms_and_condition.legal_framework.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#legal-framework'
        },
        isActive: false
      },
      {
        label: 'information.terms_and_condition.placement_of_orders.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#placement-of-orders'
        },
        isActive: false
      },
      {
        label:
          'information.terms_and_condition.delivery_of_consignment.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#delivery-of-consignment'
        },
        isActive: false
      },
      {
        label:
          'information.terms_and_condition.payments_and_invoicing.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#payment-and-invoicing'
        },
        isActive: false
      },
      {
        label:
          'information.terms_and_condition.right_of_retention_and_lien.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#right-of-retention-and-lien'
        },
        isActive: false
      },
      {
        label: 'information.terms_and_condition.insurance.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#insurance'
        },
        isActive: false
      },
      {
        label: 'information.terms_and_condition.liability.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#liability'
        },
        isActive: false
      },
      {
        label:
          'information.terms_and_condition.cancellation_and_suspension_of_use.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#cancelation'
        },
        isActive: false
      },
      {
        label: 'information.terms_and_condition.licensing.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#licensing'
        },
        isActive: false
      },
      {
        label: 'information.terms_and_condition.final_provisions.menu_label',
        link: {
          name: 'Terms And Condition',
          hash: '#final-provisions'
        },
        isActive: false
      }
    ]
  }
}
