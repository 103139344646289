<template>
  <div>
    <b-img :src="require('../../assets/images/colan.svg')"></b-img>
    <div class="content-slider" @mouseover="mouseover" @mouseleave="mouseleave">
      <transition-group tag="div" name="slide">
        <div v-for="number in [currentContent]" :key="number">
          <h5 class="pt-4">
            {{ content[Math.abs(currentContent) % content.length].quote }}
          </h5>
          <strong class=" m-0"
            >{{ content[Math.abs(currentContent) % content.length].fullName }}
          </strong>
          <strong class=" m-0">{{
            content[Math.abs(currentContent) % content.length].designation
          }}</strong>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LoginOtherHalf extends Vue {
  content = [
    {
      quote:
        '“Do you know, what is the best part of MyDello? That you do not have to send any emails! Emails, that get lost, take time to find, take time to answer and usually are missing some important information. You just enter the data and you get the quotations.”',
      fullName: 'Marite Uustal',
      designation: 'Careria'
    },
    {
      quote:
        '“On the MyDello platform you can do almost everything yourself, skipping the most annoying waiting phase. So far, my experience has shown that prices received from MyDello are adequate, they can be used safely, regardless of whether the shipment is from Europe or Asia.”',
      fullName: 'Tiia Prous',
      designation: 'Kinkston'
    },
    {
      quote:
        '“When we first started thinking about sending our electric cargo bikes to the UK, we talked to numerous service providers. Then we stumbled on MyDello and suddenly without any head-scratching we had a plan and a budget and a timeline that not only seemed reasonable but exceeded our expectations.”',
      fullName: 'Kadri Sundja',
      designation: 'Vok Bikes'
    },
    {
      quote:
        '“MyDello is a platform where I can manage everything related to shipments, starting with placing an order, tracking , and ending with an invoice.”',
      fullName: 'Cäroly Kirs',
      designation: 'Loov Organic'
    },
    {
      quote:
        '“With MyDello we get informed every step of the way – when the cargo has been picked up, where it is at any given moment, or how many days are left until its arrival. The whole process works very organically and it has made out internal process much easier.”',
      fullName: 'Raivis Petersons',
      designation: 'Printful'
    },
    {
      quote:
        '“A tool like MyDello saved you from the tiring old-school way of sending e-mails to receive shipment options. It is easy to use as it gathers all of your shipment data and live information under one concise overview, which in the end, saves me a lot of valuable time.”',
      fullName: 'Sven Raba',
      designation: 'Ampler Bikes'
    },
    {
      quote:
        '“Digital solutions are the next probable step in supply chain management and platforms like MyDello, which are enabling it, are very welcome. Especially as it eases the work of logistics.”',
      fullName: 'Rasmus Kodres',
      designation: 'Cleveron'
    },
    {
      quote:
        '“MyDello sets an example to many freight-forwarding service providers by connecting all the dots between the lifespan of a consignment providing hassle-free overview of your orders and their statuses.”',
      fullName: 'Edit Rünkorg',
      designation: 'Click&Grow'
    },
    {
      quote:
        '“By using the MyDello platform, I can get price quotations for delivery by all kinds of transports from anywhere in the world, choose the best option and book a shipment in a matter of couple of minutes.”',
      fullName: 'Anna Gussev',
      designation: 'Karen Ray Technologies'
    }
  ]
  currentContent = 0
  intervalIds: number[] = []

  mouseover() {
    this.stop()
  }

  mouseleave() {
    this.start()
  }

  start() {
    this.intervalIds.push(
      setInterval(() => {
        this.currentContent = this.currentContent + 1
      }, 6000)
    )
  }

  stop() {
    this.intervalIds.forEach(intervalId => {
      clearInterval(intervalId)
    })
    this.intervalIds = []
  }

  updateSliderState() {
    if (document.hidden) {
      // User switched to another tab or minimized the window
      this.stop()
    } else {
      // User came back to the tab
      this.start()
    }
  }

  mounted() {
    setTimeout(() => {
      this.currentContent++
      this.start()
    }, 2000)

    document.addEventListener('visibilitychange', this.updateSliderState)
  }

  beforeDestroy() {
    this.stop()
    document.removeEventListener('visibilitychange', this.updateSliderState)
  }
}
</script>
<style scoped lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.content-slider {
  overflow: hidden;
  position: relative;
  height: 230px;
}

.content-slider div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.content-slider {
  color: #000027;
  font-family: 'ReplicaPro';
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  h5 {
    font-family: 'ReplicaPro';
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    color: #000027;
  }
  strong {
    color: #000027;
    font-family: 'ReplicaPro';
    font-size: 18px;
    line-height: 26px;
    display: block;
  }
}

.content-slider:hover {
  cursor: pointer;
}
</style>
