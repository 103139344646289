import API from '@/http/http-common'
import { ICompany, IError, IParams } from '@/models'
import { ServiceResponse } from '@/services/service-response'
import { customizeParams } from '@/utils/service-helper'

export class CompanyService {
  async getAllCompanies<T>(
    params = { pageSize: 1000 } as IParams
  ): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(`/company`, queryParams)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async getCompany<T>(companyId: string) {
    try {
      const res = await API.externalInstance.get(`company/${companyId}`)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async addCompany<T>(company: ICompany): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post('/company', company)
      return {
        status: true,
        data: res.data
      }
    } catch (err) {
      const error = err as IError
      return {
        status: false,
        data: {} as T,
        totalCount: 0,
        errorMsg: error.data.title
      }
    }
  }

  async updateCompany<T>(company: ICompany): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(`company`, company)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new CompanyService()
