import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators'
import store from '@/store'
import { IVendor, IVendorParams } from '@/models'
import { VendorService } from '@/services/settings/vendor'

@Module({ dynamic: true, store, name: 'vendor', namespaced: true })
class Vendor extends VuexModule {
  private vendorService = new VendorService()
  private vendors: IVendor[] = []
  private totalCount: number = 0
  private showWaitingForApprovalInNavBar: boolean = false

  @Mutation
  SET_VENDORS(vendors: IVendor[]) {
    this.vendors = [...vendors]
  }

  @Mutation
  SET_TOTAL_COUNT(count: number) {
    this.totalCount = Number(count)
  }

  @Mutation
  SET_SHOW_WAITING_FOR_APPROVAL_IN_NAV_BAR(flag: boolean) {
    this.showWaitingForApprovalInNavBar = flag
  }

  @Action({ rawError: true })
  public async getAllVendors(vendorParams: IVendorParams) {
    const response = await this.vendorService.getAllVendors<Array<IVendor>>(
      vendorParams.params
    )
    if (response.status) {
      if (vendorParams.fromNavBar) {
        this.SET_SHOW_WAITING_FOR_APPROVAL_IN_NAV_BAR(
          Number(response.totalCount) ? true : false
        )
      } else {
        this.SET_VENDORS(response.data)
        this.SET_TOTAL_COUNT(response.totalCount ? response.totalCount : 0)
      }
    }
  }
}

export const VendorModule = getModule(Vendor)
