import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'

export class CustomerAPIAccountService {
  /**
   * Get customer API account
   * @param {string} companyId
   */

  async getCustomerAPIAccount<T>(companyId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/company/${companyId}/api-account`,
        {
          headers: {
            AllowForbidden: true
          }
        }
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      const error = e as any
      let errorMsg = error.data.title
      switch (error.status as number) {
        case 404:
          return { status: true, data: {} as T }
        case 403:
          errorMsg =
            'Not allowed to proceed. Please contact with MyDello support team'
          break
      }
      return { status: false, data: {} as T, errorMsg: errorMsg }
    }
  }

  /**
   * Generate access token
   * @param {string} companyId
   */
  async generateAccessToken<T>(companyId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/company/${companyId}/api-account`,
        {
          headers: {
            AllowForbidden: true
          }
        }
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as any
      let errorMsg = error.data.title
      switch (error.status as number) {
        case 404:
          return { status: true, data: {} as T }
        case 403:
          errorMsg =
            'Not allowed to proceed. Please contact with MyDello support team'
          break
      }
      return { status: false, data: {} as T, errorMsg: errorMsg }
    }
  }
}

export default new CustomerAPIAccountService()
