import { IFilter } from '@/models'
import { ShipmentModule } from '@/store/modules'
import { AuthModule } from '@/store/modules/auth'
import { ShipmentTab } from '@/utils/constants'
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

@Component
export class HomeMixin extends Vue {
  tabIndex = ShipmentTab.Active
  activeFilter: IFilter[] = [
    {
      data: {
        key: 'trackingStatus',
        value:
          'WaitingForConfirmation | InTransit | WaitingForPu | WaitingForDeparture | WaitingForDelivery | WaitingForExport | WaitingForImport'
      }
    },
    {
      data: {
        key: 'WaitingForApproval',
        value: false
      }
    }
  ]
  allFilter: IFilter[] = [
    {
      data: {
        key: 'WaitingForApproval',
        value: false
      }
    }
  ]
  waitingForApprovalFilter: IFilter[] = [
    {
      data: {
        key: 'WaitingForApproval',
        value: true
      }
    },
    {
      data: {
        key: 'vendorCompanyId',
        value: AuthModule.user.contact.companyId
      }
    }
  ]

  @Watch('$route')
  routeChange() {
    const activeShipment = ShipmentModule.activeShipment

    if (activeShipment) {
      this.tabIndex = ShipmentTab.Active
    } else {
      this.tabIndex = ShipmentTab.All
    }
  }
}
