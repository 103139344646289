import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import { AuthService } from '@/services/auth/auth'
import { ShipmentService } from '@/services/shipment/shipment'
import { InquiryService } from '@/services/inquiry/inquiry'
import {
  getToken,
  setToken,
  removeToken,
  getUserData,
  removeUserData,
  setUserData
} from '@/utils/cookies'
import store from '@/store'
import router from '@/router'
import { IUser, IContact, ICompany, IAskRateChatParams } from '@/models'
import { IAuth } from '@/models/auth'
import {
  CompanyModule,
  NavWaitingForApprovalShipmentModule,
  ShipmentModule
} from '.'
import Watcher from '@/utils/watcher'
import { removeLogInStatus, setLogInStatus } from '@/utils/local-storage'
import { AccessibilityType } from '@/utils/constants'

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
class Auth extends VuexModule {
  constructor(
    private authService: AuthService,
    private shipmentService: ShipmentService,
    private inquiryService: InquiryService
  ) {
    super(VuexModule)
    this.authService = new AuthService()
    this.shipmentService = new ShipmentService()
    this.inquiryService = new InquiryService()
    const userData = getUserData()
    this.token = getToken() || ''
    this.user = userData ? JSON.parse(userData) : ({} as IUser)
  }
  public token = ''
  public user: IUser = {} as IUser
  @Mutation
  public SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  public SET_USER(user: IUser) {
    this.user = user
  }

  @Action({ rawError: true })
  public updateUserContact(contact: IContact) {
    this.user.contact = { ...contact }
    this.SET_USER(this.user)
    setUserData(JSON.stringify(this.user))
  }

  @Action({ rawError: true })
  public updateUserCompany(company: ICompany) {
    this.user.companies = [
      { ...company, accessibilityType: AccessibilityType.Group }
    ]
    this.SET_USER(this.user)
    setUserData(JSON.stringify(this.user))
  }

  @Action({ rawError: true })
  public async Login({
    userInfo,
    onLoginFailed
  }: {
    userInfo: { username: string; password: string }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onLoginFailed: (errorMsg: any) => void
  }) {
    let { username, password } = userInfo
    username = username.trim()
    password = userInfo.password
    const { status, data, errorMsg } = await this.authService.login<IAuth>({
      username,
      password
    })

    if (status) {
      setToken(data.token)
      setLogInStatus(true)
      setUserData(JSON.stringify(data.user))
      this.SET_TOKEN(data.token)
      this.SET_USER(data.user)

      if (this.user && this.user.contact && this.user.contact.companyId) {
        CompanyModule.SET_USER_COMPANY_ID(this.user.contact.companyId)
        setTimeout(() => {
          if (window.SI_API) {
            window.SI_API.addParams<IAskRateChatParams[]>([
              {
                name: 'Company name',
                value: this.user.companies.map(x => x.name).join(',')
              }
            ])
          }
        }, 5000)
      }

      //set watcher for token expiry
      // settimeout because URL is not changing instantly
      setTimeout(() => {
        new Watcher()
      }, 1000)
    } else {
      onLoginFailed(errorMsg)
    }
  }

  @Action({ rawError: true })
  public RedirectToHomeOrRedirection() {
    const query = router.currentRoute.query
    let path: any = '/'
    if (query && query.redirect) {
      path = query?.redirect
    }
    router.replace(path)
  }

  @Action({ rawError: true })
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('')
  }

  @Action({ rawError: true })
  public async Logout() {
    removeToken()
    removeUserData()
    removeLogInStatus()
    this.SET_TOKEN('')
    this.SET_USER({} as IUser)

    ShipmentModule.ResetAllData()
    NavWaitingForApprovalShipmentModule.Reset()

    if (
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/sign-up'
    )
      router.replace('/login')
  }

  get getVendorCustomerCompanies() {
    return this.user.companies && this.user.companies.length > 0
      ? this.user.companies.filter(
          x => x.accessibilityType === AccessibilityType.VendorCustomer
        )
      : []
  }
}

export const AuthModule = getModule(Auth)
