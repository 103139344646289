import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { UnitConversionService } from '@/services/settings/unit-conversion'
import { IConversion, IMeasurementUnit } from '@/models'

@Module({ dynamic: true, store, name: 'unitConversion', namespaced: true })
class UnitConversion extends VuexModule {
  private unitConversionService = new UnitConversionService()
  private conversions: IConversion[] = []
  private units: IMeasurementUnit[] = []

  @Mutation
  private SET_CONVERSION_UNITS(unitConversions: IConversion[]) {
    this.conversions = unitConversions
  }

  @Mutation
  private SET_UNITS(units: IMeasurementUnit[]) {
    this.units = units
  }

  @Action
  public async getAllUnitConversions() {
    const {
      status: unitStatus,
      data: units
    } = await this.unitConversionService.getAllUnits<Array<IMeasurementUnit>>()
    if (unitStatus) {
      this.SET_UNITS(units)
    }
    const {
      status: conversionsStatus,
      data: conversions
    } = await this.unitConversionService.getAllUnitConversions<
      Array<IConversion>
    >()
    if (conversionsStatus) {
      this.SET_CONVERSION_UNITS(conversions)
    }
  }

  get getConversions(): IConversion[] {
    return this.conversions
  }

  get getUnits(): IMeasurementUnit[] {
    return this.units
  }
}

export const UnitConversionModule = getModule(UnitConversion)
