import Vue from 'vue'

Vue.filter('formatText', function(value: string) {
  return value || '-'
})

Vue.filter('formatAvatarText', function(name: string) {
  let avatarText = ''
  if (name) {
    const splittedNames = name.split(' ')
    avatarText += splittedNames[0][0].toUpperCase()
    if (splittedNames[1]) {
      avatarText += `${splittedNames[1][0].toUpperCase()}`
    }
  }

  return avatarText
})
