import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'mobile-view', namespaced: true })
class MobileView extends VuexModule {
  private stepNumber = 1

  @Mutation
  public SET_STEP_NUMBER(value: number) {
    this.stepNumber = value
  }

  @Mutation
  public RESET() {
    this.stepNumber = 1
  }
}

export const MobileViewModule = getModule(MobileView)
