import { Vue } from 'vue-property-decorator'
import { AuthModule } from '@/store/modules/auth'
import { checkTokenExpire, warn } from '@/utils/global-setting'

export default class Watcher extends Vue {
  constructor() {
    super()
    if (
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/forgot-password' &&
      window.location.pathname !== '/reset-password' &&
      window.location.pathname !== '/sign-up' &&
      window.location.pathname !== '/sign-up/register-successfully' &&
      window.location.pathname !== '/confirm-email' &&
      window.location.pathname !== '/company-registration' &&
      window.location.pathname !== '/terms-and-condition' &&
      window.location.pathname !== '/create-user' &&
      window.location.pathname !== '/confirm-tracking'
    ) {
      this.tokenExpire()
    }
  }

  tokenExpire() {
    // set token expiry is false
    let isTokenExpire = false
    // set 2 second interval to check either the token is expire or not
    const interval = setInterval(() => {
      if (!isTokenExpire) {
        // checking token expiry
        isTokenExpire = checkTokenExpire()
        if (isTokenExpire) {
          warn(this, 'Token has been expired')
          console.log('--- clear interval ---')
          // if token expire clear all session and reload the page
          clearInterval(interval)
          AuthModule.Logout()
        }
      }
    }, 2000)
  }
}
