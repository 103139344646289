<template>
  <div class="inner-row">
    <div class="logo_wrap">
      <a href="https://www.mydello.com" target="_blank">
        <img src="../../assets/images/logo.svg" width="141" height="43" />
      </a>
    </div>
    <b-nav tabs fill class="loginTabs m-0">
      <b-nav-item
        v-for="(n, index) of nav"
        :key="index"
        :to="n.link"
        :active="n.isActive"
        >{{ n.label }}</b-nav-item
      >
    </b-nav>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class ConfirmRegistration extends Vue {
  private nav: { label: string; link: string; isActive: boolean }[] = [
    {
      label: 'Login',
      link: '/confirm-email',
      isActive: false
    },
    {
      label: 'Sign Up',
      link: '/sign-up',
      isActive: false
    }
  ]
}
</script>

<style lang="scss" scoped>
.loginTabs {
  margin: 20px 0px;

  .nav-item {
    flex: 0 1 auto;

    a {
      color: #00000052;
      margin: 0;
      margin-right: 30px;
      padding: 13px 0px;

      &.router-link-active {
        position: relative;
        color: #000;

        &:after {
          content: '';
          background: #7ecdc4;
          height: 2px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          margin-bottom: -2px;
        }
      }
    }
  }
}
</style>
