import { RouteConfig } from 'vue-router/types/router'
import { beforeEnter as companyProfileBeforeEnter } from '@/guards/companyProfile'
import { beforeEnter as apiSettingsBeforeEnter } from '@/guards/apiSettings'

export const settingsRoute: Array<RouteConfig> = [
  {
    path: 'billing-information',
    name: 'Billing Information',
    component: () => import('../../views/settings/BillingInformation.vue')
  },
  {
    path: 'company-profile',
    name: 'Company Profile',
    beforeEnter: companyProfileBeforeEnter,
    component: () => import('../../views/settings/CompanyProfile.vue')
  },
  {
    path: 'address-book',
    name: 'Address book',
    component: () => import('../../views/settings/AddressBook.vue')
  },
  {
    path: 'my-profile',
    name: 'My Profile & users',
    component: () => import('../../views/settings/MyProfile.vue')
  },
  {
    path: 'system-settings',
    name: 'System Settings',
    component: () => import('../../views/settings/SystemSettings.vue')
  },
  {
    path: 'vendors',
    name: 'Vendors',
    component: () => import('../../views/settings/Vendors.vue')
  },
  {
    path: 'api-settings',
    name: 'API settings',
    beforeEnter: apiSettingsBeforeEnter,
    component: () => import('../../views/settings/APISettings.vue')
  }
]
