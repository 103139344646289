import { IUser } from '@/models/user'
import { getUserData } from '@/utils/cookies'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({})
export class HasSingleCompanyMixin extends Vue {
  hasSingleCompany = () => {
    const userString = getUserData() as string
    const user = userString ? (JSON.parse(userString) as IUser) : ''
    return user && user.companies && user.companies.length == 1 ? true : false
  }
}
