<template>
  <div class="container">
    <b-row
      v-remove-for-single-company
      v-show="haveMoreThanOneGroupCompany"
      class="pt-2"
    >
      <b-col cols="4" class="group-company-selection-section">
        <p>Select group company:</p>
        <app-user-companies-drop-down
          :value="userCompanyId"
          :companies="companies"
          @input="changeCompanyId($event)"
          :isAllowNull="false"
        />
      </b-col>
    </b-row>
    <b-nav tabs fill class="navtabsMenu">
      <template v-for="(n, index) of nav">
        <b-nav-item
          v-if="n && n.label"
          :key="index"
          :to="n.link"
          :active="n.isActive"
          >{{ n.label }}</b-nav-item
        >
      </template>
    </b-nav>
    <transition name="fade" mode="out-in">
      <router-view :key="$route.path" />
    </transition>
  </div>
</template>

<script lang="ts">
import UserCompaniesDropDown from '@/components/inquiry/components/user-companies-dropdown.vue'
import { IParams, IVendor } from '@/models'
import { IMultiSelect } from '@/models/multi-select'
import {
  AuthModule,
  CompanyModule,
  CountryModule,
  CustomerAPIAccountModule,
  TemplateModule,
  VendorModule
} from '@/store/modules'
import { error, getUserCompanies } from '@/utils/global-setting'
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

const computed = {
  ...mapState('company', ['userCompanyId']),
  ...mapState('vendor', ['vendors']),
  ...mapState('customer-api-account', ['isAllowToAccess'])
}

@Component({
  components: {
    appUserCompaniesDropDown: UserCompaniesDropDown
  },
  computed
})
export default class Settings extends Vue {
  nav: { label: string; link: string; isActive: boolean }[] = []
  userCompanyId!: string
  vendors!: Array<IVendor>
  companies: Array<IMultiSelect> = []
  isAllowToAccess!: boolean

  changeCompanyId(userCompanyId: string) {
    CompanyModule.SET_USER_COMPANY_ID(userCompanyId)
  }

  private createSettingTabs() {
    if (AuthModule.user.isPrivatePerson) {
      this.nav = [
        {
          label: 'My profile',
          link: '/settings/my-profile',
          isActive: false
        },
        {
          label: 'System settings',
          link: '/settings/system-settings',
          isActive: false
        },
        this.vendors.length > 0
          ? {
              label: 'Vendors',
              link: '/settings/vendors',
              isActive: false
            }
          : ({} as any),
        AuthModule.user &&
        AuthModule.user.contact &&
        AuthModule.user.contact.isCompanyOwner &&
        this.isAllowToAccess
          ? {
              label: 'API settings',
              link: '/settings/api-settings',
              isActive: false
            }
          : ({} as any)
      ]
    } else {
      this.nav = [
        {
          label: 'Users',
          link: '/settings/my-profile',
          isActive: false
        },
        {
          label: 'Company profile',
          link: '/settings/company-profile',
          isActive: false
        },
        {
          label: 'Address book',
          link: '/settings/address-book',
          isActive: false
        },
        {
          label: 'System settings',
          link: '/settings/system-settings',
          isActive: false
        },
        this.vendors.length > 0
          ? {
              label: 'Vendors',
              link: '/settings/vendors',
              isActive: false
            }
          : ({} as any),
        AuthModule.user &&
        AuthModule.user.contact &&
        AuthModule.user.contact.isCompanyOwner &&
        this.isAllowToAccess
          ? {
              label: 'API settings',
              link: '/settings/api-settings',
              isActive: false
            }
          : ({} as any)
      ]
    }
  }

  private async getVendor(companyId: string) {
    const params: IParams = {
      pageSize: 1000,
      filters: [
        {
          data: {
            key: 'customerCompanyId',
            value: companyId
          }
        }
      ]
    }
    await VendorModule.getAllVendors({ fromNavBar: false, params: params })
  }

  get haveMoreThanOneGroupCompany() {
    return this.companies && this.companies.length > 1 ? true : false
  }

  async beforeMount() {
    TemplateModule.toggleLoader(true)
    await CompanyModule.getAllCompanies()
    this.companies = getUserCompanies(true)
    CompanyModule.SET_USER_COMPANY_ID(AuthModule.user.contact.companyId)
    await this.getVendor(this.userCompanyId)
    await CustomerAPIAccountModule.getCustomerAPIAccount(this.userCompanyId)
    this.createSettingTabs()
    await CountryModule.getAllCountries()
    TemplateModule.toggleLoader(false)
  }

  @Watch('userCompanyId') async onCompanyChange(companyId: string) {
    TemplateModule.toggleLoader(true)
    await this.getVendor(companyId)
    await CustomerAPIAccountModule.getCustomerAPIAccount(this.userCompanyId)
    this.createSettingTabs()
    TemplateModule.toggleLoader(false)
  }

  @Watch('isAllowToAccess') async onIsAllowToAccess(flag: string) {
    if (!flag && this.$route.path === '/settings/api-settings') {
      await this.$router.replace('/settings/my-profile')
      error(
        this,
        'Not allowed to proceed. Please contact with MyDello support team'
      )
    }
  }
}
</script>

<style lang="scss"></style>
