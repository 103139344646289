import API from '@/http/http-common'
import { IInquiryExtraFields } from '@/models'
import { ServiceResponse } from '@/services/service-response'

export class InquiryExtraFieldService {
  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async getInquiryExtraFields<T>(inquiryId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/inquiry/${inquiryId}/extra-fields`
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @param {IInquiryExtraFields} payload
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async postInquiryExtraFields<T>(
    inquiryId: string,
    payload: IInquiryExtraFields
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/inquiry/${inquiryId}/extra-fields`,
        payload
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @param {IInquiryExtraFields} payload
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async putInquiryExtraFields<T>(
    inquiryId: string,
    payload: IInquiryExtraFields
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(
        `/inquiry/${inquiryId}/extra-fields`,
        payload
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  /**
   *
   *
   * @template T
   * @param {string} inquiryId
   * @param {string} extraFieldId
   * @return {*}  {ServiceResponse<T>}
   * @memberof InquiryService
   */
  async deleteExtraFields<T>(
    inquiryId: string,
    extraFieldId: string
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.delete(
        `/inquiry/${inquiryId}/extra-fields/${extraFieldId}`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new InquiryExtraFieldService()
