import {
  IAddress,
  ICompanyAddress,
  InquiryItem,
  IQoutePdf,
  IQuoteDownloadLink,
  ITotalShipmentUnits,
  ITotalShipmentVolume,
  ITotalShipmentWeight,
  IUnit
} from '@/models'
import { QuoteAttachmentService } from '@/services/quote/attachment'
import { QuoteService } from '@/services/quote/quote'
import { TemplateModule } from '@/store/modules'
import { AddressPoint, conversions, ItemTypes } from '@/utils/constants'
import { parseDownloadLink, parseValue } from '@/utils/global-setting'
import { UnitConversion } from '@/utils/unit-conversion'

const unitConversion = new UnitConversion()

/**
 * @description generate pdf for qutoe
 * @param quoteId quote id of which the pdf is going to be generate.
 */
export const generateQuotePdf = async (
  quoteId: string,
  target = '_blank'
): Promise<void> => {
  const quoteAttachmentService = new QuoteAttachmentService()
  const quoteService = new QuoteService()
  TemplateModule.toggleLoader(true)
  const { status, data } = await quoteService.generatePdf<IQoutePdf>(quoteId)
  if (status) {
    const {
      status: pdfStatus,
      data: linkResponse
    } = await quoteAttachmentService.getQuotePdfDownloadLink<
      IQuoteDownloadLink
    >(data.id)

    if (pdfStatus) {
      window.open(parseDownloadLink(linkResponse.downloadLink), target)
    }
  }
  TemplateModule.toggleLoader(false)
}

/**
 *
 * @param addresses arrray of addresses
 * @param point point like Origin | Destination | Incoterm
 */
export const getAddress = (
  addresses: IAddress[],
  point: AddressPoint
): IAddress | undefined => {
  const address = addresses.find(address => address.point === point)
  return address
}

/**
 *
 * @export
 * @param {IUnit[]} boxes
 * @param {IUnit[]} pallets
 * @param {IContainerUnit[]} containers
 * @return {*}  {ITotalShipmentUnits}
 */
export const getTotalShipmentUnits = (
  items: InquiryItem[]
): ITotalShipmentUnits => {
  const totalShipment = items.reduce(
    (acc, currentItem) => {
      if (
        currentItem.itemType.includes('BOX') ||
        currentItem.itemType === ItemTypes.ENVELOPE
      ) {
        acc.totalBoxes = acc.totalBoxes + currentItem.data.quantity
      } else if (currentItem.itemType.includes('PALLET')) {
        acc.totalPallets = acc.totalPallets + currentItem.data.quantity
      }
      return acc
    },
    {
      totalBoxes: 0,
      totalContainers: 0,
      totalPallets: 0
    }
  )

  return {
    totalUnits:
      totalShipment.totalBoxes +
      totalShipment.totalPallets +
      totalShipment.totalContainers,
    totalBoxes: totalShipment.totalBoxes,
    totalPallets: totalShipment.totalPallets,
    totalContainers: totalShipment.totalContainers
  }
}

/**
 *
 *
 * @export
 * @param {*} boxes
 * @param {*} pallets
 * @return {*}  {ITotalShipmentVolume}
 */
export function getTotalShipmentVolume(
  items: InquiryItem[]
): ITotalShipmentVolume {
  const totalVolume = items.reduce(
    (acc, currentItem) => {
      if (
        currentItem.itemType.includes('BOX') ||
        currentItem.itemType === ItemTypes.ENVELOPE
      ) {
        acc.boxesTotalVolume =
          acc.boxesTotalVolume +
          (currentItem.data as IUnit).totalVolume * currentItem.data.quantity
      } else if (currentItem.itemType.includes('PALLET')) {
        acc.palletsTotalVolume =
          acc.palletsTotalVolume +
          (currentItem.data as IUnit).totalVolume * currentItem.data.quantity
      }
      return acc
    },
    {
      palletsTotalVolume: 0,
      boxesTotalVolume: 0
    }
  )
  return {
    ...totalVolume,
    totalVolume: parseValue(
      totalVolume.boxesTotalVolume + totalVolume.palletsTotalVolume
    )
  }
}

/**
 *
 *
 * @export
 * @return {*}  {ITotalShipmentWeight}
 */
export function getTotalShipmentWeight(
  items: InquiryItem[]
): ITotalShipmentWeight {
  const totalWeight = items.reduce(
    (acc, currentItem) => {
      const item = currentItem.data as IUnit
      if (item.weight?.value) {
        if (
          currentItem.itemType.includes('BOX') ||
          currentItem.itemType === ItemTypes.ENVELOPE
        ) {
          acc.boxTotalWeight += item.quantity * item.weight.totalWeight
        } else if (currentItem.itemType.includes('PALLET')) {
          acc.palletTotalWeight += item.quantity * item.weight.totalWeight
        }
      }
      return acc
    },
    {
      boxTotalWeight: 0,
      palletTotalWeight: 0
    }
  )
  return {
    totalWeight: parseValue(
      totalWeight.boxTotalWeight + totalWeight.palletTotalWeight
    ),
    boxTotalWeight: totalWeight.boxTotalWeight,
    palletTotalWeight: totalWeight.palletTotalWeight
  }
}

/**
 *
 * @export
 * @template T
 * @param {number} quantity
 * @param {number} rowIndex
 * @return Callback function for map
 */
export function itemQuantityUpdater<T>(quantity: number, rowIndex: number) {
  return (item: T, i: number): T => {
    return i === rowIndex ? { ...item, quantity } : item
  }
}

/**
 *
 *
 * @param {number} itemIndex
 */
export const quantityResetter = (itemIndex: number) => (item, index) => {
  if (index === itemIndex) {
    item.quantity = 0
  }
  return item
}

/**
 *
 *
 * @param {number} rowIndex
 * @param {string} dimension
 * @param {number} value
 */
export function dimensionUpdater(
  rowIndex: number,
  dimension: string,
  value: number
) {
  return (item: IUnit, index: number) => {
    value = value || 0
    if (index !== rowIndex) {
      return item
    }
    item.dimensions[dimension] = value
    // volume unit conversion
    let conversionRate: number | undefined
    if (item.dimensions.unit !== 'm') {
      conversionRate = unitConversion.getConversionRate(
        item.dimensions.unit,
        'm'
      )
    }
    const { length, width, height } = item.dimensions
    if (conversionRate) {
      item.totalVolume =
        length.value *
        conversionRate *
        (width.value * conversionRate) *
        (height.value * conversionRate)
    } else {
      item.totalVolume = length.value * width.value * height.value
    }
    item.totalVolume = parseFloat(item.totalVolume.toFixed(3))
    return item
  }
}

/**
 *
 * Finds the record on the provided item's index and update the weight
 * @export
 * @param {number} itemIndex
 * @param {number} weight
 * @return {Function} Mapper for the HOF
 */
export function itemWeightUpdater(itemIndex: number, weight: number) {
  return (item: IUnit, index: number): IUnit => {
    if (index !== itemIndex || !item.weight) {
      return item
    }
    let conversionRate: number | undefined
    if (item.weight && item?.weight.unit !== 'kg') {
      conversionRate = unitConversion.getConversionRate(item.weight.unit, 'kg')
    }
    if (conversionRate) {
      item.weight.totalWeight = parseFloat((weight * conversionRate).toFixed(3))
      item.weight.value = weight
    } else {
      item.weight.value = weight
      item.weight.totalWeight = weight
    }
    return item
  }
}

/**
 *
 * Generate google address string based on address provided
 * @export
 * @param {IAddress} address
 * @return {Function} Converter for google address string
 */
export function generateGoogleAddressString(
  address: IAddress | ICompanyAddress
) {
  const addressStringValues = [
    address.street,
    address.city,
    address.county,
    address.countryName,
    address.postalCode
  ]
  const filteredValues = addressStringValues.filter(x => x)
  let addressString = ''

  filteredValues.forEach((item, index) => {
    const hasSeparator =
      filteredValues.length !== 1 && index < filteredValues.length - 1
    addressString += hasSeparator ? `${item}, ` : item
  })
  return addressString
}

// NB! we have a unit conversion service which gets all the conversion from backend but unfortunately
// we don't have conversion with all the possibilities for example:- conversion from 'cm' to 'ft', specially in dimensions.
// therefore we have to use hard coded conversion rates.
/**
 *
 * Convert dimensions from one unit to another
 * @param {number} conversionRate
 * @param {number} value
 * @return {number} Converted value
 */
export function convert(conversionRate: number, value: number): number {
  return conversionRate
    ? +parseFloat((value * conversionRate).toString())
    : value
}

/**
 *
 * Get conversion rate of dimensions
 * @param {string} fromUnit
 * @param {string} toUnit
 * @return {number | undefined} Conversion rate
 */
export function getConversionRate(
  fromUnit: string,
  toUnit: string
): number | undefined {
  const conversion = conversions.find(
    c => c.fromUnitName === fromUnit && c.toUnitName === toUnit
  )
  if (conversion) return conversion.conversionRate
  return
}
