<template>
  <div class="mainPage h-100">
    <app-nav></app-nav>
    <!-- Header -->
    <div class="w-100">
      <app-header></app-header>
      <div class="content-body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Nav from '@/components/nav/Nav.vue'
import Header from '@/components/header/index.vue'
import {
  AuthModule,
  CurrencyModule,
  ExtraFieldTypesModule,
  VendorModule
} from '@/store/modules'
import { IParams, IVendorParams } from '@/models/params'
import { AccessibilityType } from '@/utils/constants'
import { EventBus } from '@/event-bus/event-bus'
import { NavWaitingForApprovalShipmentModule } from '@/store/modules'

@Component({
  components: {
    appNav: Nav,
    appHeader: Header
  }
})
export default class Main extends Vue {
  active = true

  private async getAllVendors() {
    const params: IParams = {
      page: 1,
      pageSize: 1,
      filters: [
        {
          data: {
            key: 'customerCompanyId',
            value: AuthModule.user.companies
              .filter(x => x.accessibilityType === AccessibilityType.Group)
              .map(y => y.id)
              .join('|')
          }
        }
      ]
    }
    const vendorParam: IVendorParams = { fromNavBar: true, params: params }
    await VendorModule.getAllVendors(vendorParam)
  }

  private async getWaitingForApprovalCount() {
    const params: IParams = {
      filters: [
        {
          data: {
            key: 'WaitingForApproval',
            value: true
          }
        },
        {
          data: {
            key: 'companyId',
            value: AuthModule.user.companies
              .filter(x => x.accessibilityType === AccessibilityType.Group)
              .map(y => y.id)
              .join('|')
          }
        }
      ],
      page: 1,
      pageSize: 1
    } as IParams

    NavWaitingForApprovalShipmentModule.getAllWaitingForApprovalShipment(params)
  }

  async created() {
    await CurrencyModule.getAllCurrencies()
    await ExtraFieldTypesModule.getExtraFieldTypes()
    await this.getAllVendors()
  }

  async mounted() {
    if (AuthModule.user.companies && AuthModule.user.companies.length > 0) {
      await this.getWaitingForApprovalCount()
      EventBus.$on('updateWaitingForApprovalCount', async (status: boolean) => {
        NavWaitingForApprovalShipmentModule.SET_IS_TRIGGER(false)
        if (status) {
          await this.getWaitingForApprovalCount()
        }
      })
    }
  }
}
</script>
