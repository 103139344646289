import { Component, Mixins } from 'vue-property-decorator'
import { InquiryItem, IUnit } from '@/models'
import { NewInquiryFormModule } from '@/store/modules'
import { ItemTypes } from '@/utils/constants'
import { mapState } from 'vuex'
import { ValidationMessagesMixin } from '.'

@Component({
  computed: {
    ...mapState('new-inquiry-form', ['items'])
  }
})
export class InquiryValidations extends Mixins(ValidationMessagesMixin) {
  /**
   * Vuex bindings
   */
  public items!: InquiryItem[]

  public validateWeight() {
    const isAllZero = this.items.every(x => {
      if (
        x.itemType !== ItemTypes.NOT_SELECTED &&
        x.itemType !== ItemTypes.FULL_CONTAINER
      ) {
        const unit = x.data as IUnit
        return unit.weight.value <= 0
      } else {
        return true
      }
    })

    if (isAllZero) {
      NewInquiryFormModule.setItemWeightValid()
    } else {
      NewInquiryFormModule.setItemisWeightInvalid()
    }
  }
}
