<template>
  <!-- class="form-wrapper-right container mr-auto my-auto" -->
  <div v-if="isRendered" class="welcome_contet_wrap">
    <template v-if="isUserIsInDesiredRegion">
      <h2>
        {{ content.heading1 }}
      </h2>
    </template>
    <template v-else>
      <h3>
        {{ content.heading1 }}
      </h3>
      <h4>
        {{ content.heading2 }}
      </h4>
    </template>
    <ul class="no-bullets">
      <li v-for="c in content.description" :key="c">
        <b-img :src="require('../../assets/images/logo-small.svg')"></b-img>
        <span>
          {{ c }}
        </span>
      </li>
      <li>
        <b-img :src="require('../../assets/images/logo-small.svg')"></b-img>
        <strong>
          Save time and money on logistics
        </strong>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { ClientCountry } from '@/services/client-country'
import { Regions } from '@/utils/constants'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class SignUpOtherHalf extends Vue {
  private country = ''
  private env = process.env.NODE_ENV
  isRendered = false

  get isUserIsInDesiredRegion() {
    return (
      this.country &&
      Object.values(Regions)
        .map(x => x.toLowerCase())
        .includes(this.country.toLowerCase())
    )
  }

  get content() {
    if (this.isRendered) {
      if (this.isUserIsInDesiredRegion) {
        return {
          heading1: 'Create your free company account – no hidden fees',
          heading2: '',
          description: [
            'Get instant quotes for all freight modes',
            'Compare options for hundreds of shipping providers',
            'Find the optimal shipping time and cost',
            'Avoid surprises with guaranteed prices',
            'Track all shipments and reduce email by 60%'
          ]
        }
      } else {
        return {
          heading1: `Our platform currently supports shipments coming to or going from Sweden, Estonia, Latvia and Lithuania.`,
          heading2: `We're continually expanding our solutions. Sign Up Today!`,
          description: [
            'Get instant quotes for all freight modes',
            'Compare options for hundreds of shipping providers',
            'Find the optimal shipping time and cost',
            'Avoid surprises with guaranteed prices',
            'Track all shipments and reduce email by 60%'
          ]
        }
      }
    } else {
      return {
        heading1: '',
        heading2: '',
        description: []
      }
    }
  }

  async beforeMount() {
    if (this.env === 'production') {
      const clientCountry = new ClientCountry()
      this.country = await clientCountry.getCountry()
      this.isRendered = true
    } else {
      this.isRendered = true
    }
  }
}
</script>
<style scoped lang="scss">
ul {
  li {
    padding: 5px 0px;
    color: #000027;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    position: relative;
    display: flex;
    align-content: center;
    padding-left: 35px;

    img {
      position: absolute;
      left: 0;
    }
  }
}

ul.no-bullets {
  list-style-type: none;
  /* Remov bullets */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margins */
}

.welcome_contet_wrap {
  position: relative;
  z-index: 250;

  h2 {
    color: #000027;
    font-size: 28px;
    line-height: 38px;
  }

  h3 {
    color: #000027;
    font-size: 24px;
    line-height: 36px;
  }

  h4 {
    color: #000027;
    font-size: 20px;
    line-height: 28px;
  }
}
</style>
