import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'

import { ValidationsService } from '@/services/settings/validations'

import { ICommonConfiguration } from '@/models'

@Module({
  dynamic: true,
  store,
  name: 'common-configuration',
  namespaced: true
})
class CommonConfiguration extends VuexModule {
  public inquiryValidations: any = {}
  private validationService = new ValidationsService()
  public commonConfiguration: Array<ICommonConfiguration> = [] as Array<
    ICommonConfiguration
  >

  @Mutation
  private SET_INQUIRY_VALIDATIONS(validations) {
    this.inquiryValidations = validations
  }

  @Mutation
  private SET_COMMON_CONFIGURATION(
    commonConfiguration: Array<ICommonConfiguration>
  ) {
    this.commonConfiguration = commonConfiguration
  }

  @Action
  public async getInquiryValidationRules() {
    const {
      data,
      status
    } = await this.validationService.getInquiryValidationRules()
    if (status) {
      const commonConfiguration = data as ICommonConfiguration[]
      this.SET_COMMON_CONFIGURATION(commonConfiguration)
      const parsedData = commonConfiguration.reduce((prev, curr) => {
        const [type, key] = curr.key.split('.')
        prev[type.toLowerCase()] = {
          ...prev[type.toLowerCase()],
          [key.toLowerCase()]: parseFloat(curr.value)
        }
        return prev
      }, {})
      this.SET_INQUIRY_VALIDATIONS(parsedData)
    }
  }

  get getShipmentValidations() {
    return { ...this.inquiryValidations.shipment }
  }

  get getContainersValidations() {
    return { ...this.inquiryValidations.container }
  }

  get getPalletValidations() {
    return { ...this.inquiryValidations.pallet }
  }

  get getBoxesValidations() {
    return { ...this.inquiryValidations.box }
  }

  get getEnvelopesValidations() {
    return { ...this.inquiryValidations.envelope }
  }
}

export const CommonConfigurationModule = getModule(CommonConfiguration)
