import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { IShipment, IInquiry, IParams } from '@/models'
import { ShipmentService } from '@/services/shipment/shipment'
import { InquiryService } from '@/services/inquiry/inquiry'

@Module({ dynamic: true, store, name: 'shipment', namespaced: true })
class Shipment extends VuexModule {
  constructor(
    private shipmentService: ShipmentService,
    private inquiryService: InquiryService
  ) {
    super(VuexModule)
    this.shipmentService = new ShipmentService()
    this.inquiryService = new InquiryService()
  }

  public totalShipment = 0
  public activeShipment = 0
  public totalInquiry = 0

  @Mutation
  public SET_TOTAL_SHIPMENT(_totalShipment: number | string) {
    this.totalShipment = Number(_totalShipment)
  }

  @Mutation
  public SET_ACTIVE_SHIPMENT(_activeShipment: number | string) {
    this.activeShipment = Number(_activeShipment)
  }

  @Mutation
  public SET_TOTAL_INQUIRY(_inquiry: number) {
    this.totalInquiry = Number(_inquiry)
  }

  @Mutation
  public RESET() {
    this.totalShipment = 0
    this.totalInquiry = 0
  }

  @Action({ rawError: true })
  public ResetAllData() {
    this.RESET()
  }

  @Action({ rawError: true })
  public async GetInquiry() {
    const inquiryParams: IParams = {
      page: 1,
      pageSize: 1
    } as IParams

    const inquiryPromise = await this.inquiryService.getInquiry<
      Array<IInquiry>
    >(inquiryParams)

    inquiryPromise.totalCount = inquiryPromise.totalCount
      ? inquiryPromise.totalCount
      : 0
    if (inquiryPromise.status) this.SET_TOTAL_INQUIRY(inquiryPromise.totalCount)
  }

  @Action({ rawError: true })
  public async GetAllTypeOfShipment() {
    const activeParams: IParams = {
      page: 1,
      pageSize: 1,
      filters: [
        {
          data: {
            key: 'trackingStatus',
            value:
              'WaitingForConfirmation | InTransit | WaitingForPu | WaitingForDeparture | WaitingForDelivery | WaitingForExport | WaitingForImport'
          }
        },
        {
          data: {
            key: 'WaitingForApproval',
            value: false
          }
        }
      ]
    } as IParams

    const allParams: IParams = {
      page: 1,
      pageSize: 1,
      filters: [
        {
          data: {
            key: 'WaitingForApproval',
            value: false
          }
        }
      ]
    }

    const allShipmentPromise = this.shipmentService.getAllShipment<
      Array<IShipment>
    >(allParams)
    const activeShipmentPromise = this.shipmentService.getAllShipment<
      Array<IShipment>
    >(activeParams)

    return Promise.all([allShipmentPromise, activeShipmentPromise]).then(
      resp => {
        resp.forEach((element, index) => {
          element.totalCount = element.totalCount ? element.totalCount : 0
          if (index === 0 && element.status) {
            this.SET_TOTAL_SHIPMENT(element.totalCount)
          } else if (index === 1 && element.status) {
            this.SET_ACTIVE_SHIPMENT(element.totalCount)
          }
        })
      }
    )
  }
}

export const ShipmentModule = getModule(Shipment)
