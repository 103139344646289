<template>
  <h1 class="pageTitle font-light text-gray-darker">Settings</h1>
</template>

<script>
export default {
  name: 'SettingsHeader'
}
</script>

<style></style>
