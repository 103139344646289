<template>
  <div class="shipment_detail_screen_wrap">
    <div class="container">
      <b-row class="shipment-number link-color cursor">
        <router-link to="/">
          <img src="../../assets/images/Icon-left-arrow.svg" />
          <span class="mx-1">Back to shipments dashboard</span>
        </router-link>
        <b-nav v-if="isMobile()" tabs fill class="navtabsMenu">
          <b-nav-item
            v-for="(n, index) of nav"
            :key="index"
            :to="n.link"
            :active="n.isActive"
            >{{ n.label }}</b-nav-item
          >
        </b-nav>
      </b-row>
    </div>

    <app-shipment-core-info v-if="!isMobile()" />

    <div class="container px-0">
      <b-nav v-if="!isMobile()" tabs fill class="navtabsMenu">
        <b-nav-item
          v-for="(n, index) of nav"
          :key="index"
          :to="n.link"
          :active="n.isActive"
          >{{ n.label }}</b-nav-item
        >
      </b-nav>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path" />
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { MobileDetectingMixin } from '@/mixins'
import ShipmentCoreInfo from '@/components/shipment/ShipmentCoreInfo.vue'
import { ShipmentDetailModule } from '@/store/modules'

@Component({
  components: {
    appShipmentCoreInfo: ShipmentCoreInfo
  }
})
export default class ShipmentDetail extends Mixins(MobileDetectingMixin) {
  private nav: { label: string; link: string; isActive: boolean }[] = [
    {
      label: 'Details',
      link: `/shipment/:id/details`,
      isActive: false
    },
    {
      label: 'Messages',
      link: '/shipment/:id/messages',
      isActive: false
    },
    {
      label: 'Attachments',
      link: '/shipment/:id/attachments',
      isActive: false
    },
    {
      label: 'Quotes & Invoices',
      link: '/shipment/:id/quotes-invoices',
      isActive: false
    }
  ]

  //#region lifecycle hooks region start
  /**
   * Lifecycle Hooks
   */

  async created() {
    this.nav.forEach(elem => {
      elem.link = elem.link.replace(':id', this.$route.params.id)
    })
  }

  async beforeMount() {
    const shipmentId = this.$route.params.id
    // shipment address
    await ShipmentDetailModule.getShipmentAddress(shipmentId)
  }

  beforeDestroy() {
    ShipmentDetailModule.RESET_ALL()
  }

  //#endregion lifecycle hooks region start
}
</script>

<style lang="scss"></style>
