import Cookies from 'js-cookie'

// Auth Token
const tokenKey = 'token'
export const getToken = () => Cookies.get(tokenKey)
export const setToken = (token: string) => Cookies.set(tokenKey, token)
export const removeToken = () => Cookies.remove(tokenKey)

//User Data
const userKey = 'user'
export const getUserData = () => Cookies.get(userKey)
export const setUserData = (user: string) => Cookies.set(userKey, user)
export const removeUserData = () => Cookies.remove(userKey)

//End Point
const userNameKey = 'username'
export const getUserName = () => {
  const encodedUserName = Cookies.get(userNameKey)
  return encodedUserName ? atob(encodedUserName) : ''
}

export const setUserName = (userName: string) =>
  Cookies.set(userNameKey, userName ? btoa(userName) : '')
export const removeUserName = () => Cookies.remove(userNameKey)

// Set API URL
const baseURLKey = 'baseURL'
export const getBaseURL = () => Cookies.get(baseURLKey)
export const setBaseURL = (url: string) => Cookies.set(baseURLKey, url)
export const removeBaseURL = () => Cookies.remove(baseURLKey)

// Incoterm Info
const showIncoInfoKey = 'showIncoInfo'
export const getIncotermInfo = () => Cookies.get(showIncoInfoKey)
export const setIncoInfo = (flag: boolean) =>
  Cookies.set(showIncoInfoKey, flag.toString())
export const removeIncoInfo = () => Cookies.remove(showIncoInfoKey)

// Incoterm Info
const showDestinationPostalCodeInfoKey = 'showDestinationPostalCodeInfo'
export const getDestinationPostalCodeInfo = () =>
  Cookies.get(showDestinationPostalCodeInfoKey)
export const setDestinationPostalCodeInfo = (flag: boolean) =>
  Cookies.set(showDestinationPostalCodeInfoKey, flag.toString())
export const removeDestinationPostalCodeInfo = () =>
  Cookies.remove(showDestinationPostalCodeInfoKey)

// Caro Description Info
const showCargoInfoKey = 'showCargoInfo'
export const getCargoInfo = () => Cookies.get(showCargoInfoKey)
export const setCargoInfo = (flag: boolean) =>
  Cookies.set(showCargoInfoKey, flag.toString())
export const removeCargoInfo = () => Cookies.remove(showCargoInfoKey)
