import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import { cloneDeep, uniqueId } from 'lodash'
import {
  IAddress,
  IContainerUnit,
  IEnvelopeValidationRules,
  IGoogleMapPlaceResult,
  IInquiry,
  IInquiryFreightModeSubTypes,
  InquiryItem,
  ISelectOption,
  IShipment,
  IUnit
} from '@/models'
import {
  ContainerItemPayload,
  DimensionPayload,
  DimensionValidation,
  IsStackablePayload,
  QuantityPayload,
  SetItemPayload,
  SetQuantityInvalidPayload,
  UpdateWeightPayload,
  UpdateWeightUnitPayload
} from './types'
import {
  getItemUnitsFromInquiry,
  getContainerUnitsFromInquiry,
  getAddress,
  getItemUnitsFromShipment,
  getTotalVolumeByDimensions,
  getItemTotalWeight
} from '../helpers'
import { ShipmentService } from '@/services/shipment/shipment'
import { ShipmentAddressesService } from '@/services/shipment/address'
import { ShipmentItemService } from '@/services/shipment/items'
import {
  AddressPoint,
  IPostType,
  InquiryRouteType,
  InquiryStatus,
  ItemTypes,
  Tabs
} from '@/utils/constants'
import { ItemFactory } from './item-factory'
import * as defaults from './constants'

import router from '@/router'
import store from '@/store'
import { InquiryService } from '@/services/inquiry/inquiry'
import {
  getTotalShipmentVolume,
  getTotalShipmentWeight
} from '@/components/inquiry/utils/helpers'

/**
 * @module NewInquiryForm
 * @description contains the state, actions and mutations for new inquiry form
 */
@Module({ dynamic: true, store, name: 'new-inquiry-form', namespaced: true })
class NewInquiryForm extends VuexModule {
  /**
   * ----------------------------------------
   *  STATE
   * ----------------------------------------
   */
  private activeRowIndex = 0
  private inquiry: IInquiry | null = null
  private openShipmentDetailedForm = false
  private originAddress: IAddress = defaults.DEFAULT_ADDRESS
  private destinationAddress: IAddress = defaults.DEFAULT_ADDRESS
  private incotermAddress: IAddress = defaults.DEFAULT_ADDRESS
  private originGoogleMapPlaceResult: IGoogleMapPlaceResult = {} as IGoogleMapPlaceResult
  private destinationGoogleMapPlaceResult: IGoogleMapPlaceResult = {} as IGoogleMapPlaceResult
  private incotermGoogleMapPlaceResult: IGoogleMapPlaceResult = {} as IGoogleMapPlaceResult
  private weightUnitOptions: ISelectOption[] = defaults.WEIGHT_UNIT_OPTIONS
  private volumeUnitOptions: ISelectOption[] = defaults.VOLUME_UNIT_OPTIONS
  private shipmentWeight: null | number = null
  private shipmentVolume: null | number = null
  private shipmentWeightUnit = defaults.DEFAULT_WEIGHT_UNIT
  private shipmentVolumeUnit = defaults.DEFAULT_VOLUME_UNIT
  private userCompanyId: string | null = null
  private incoterm = ''
  private isNotHazardousShipment = false
  private isNotPersonalShipment = false
  private envelopes = defaults.INITIAL_ENVELOPE
  private boxes = defaults.INITIAL_BOXES
  private pallets = defaults.INITIAL_PALLETS
  private containers = defaults.INITIAL_CONTAINERS
  private containerOtherTypeText = ''
  private currentTab = Tabs.Boxes

  private readOnly = false

  private items: InquiryItem[] = [
    {
      id: uniqueId(),
      itemType: ItemTypes.NOT_SELECTED,
      data: {} as IUnit | IContainerUnit
    }
  ]

  /**
   * ----------------------------------------
   * MUTATIONS
   * ----------------------------------------
   */

  @Mutation
  SET_CONTAINER_OTHER_TYPE_TEXT(text: string) {
    this.containerOtherTypeText = text
  }

  @Mutation
  SET_DEFAULT_ITEMS() {
    this.items = [
      {
        id: uniqueId(),
        itemType: ItemTypes.NOT_SELECTED,
        data: {} as IUnit | IContainerUnit
      }
    ]
  }

  @Mutation
  SET_ITEMS(items: InquiryItem[]) {
    this.items = items
    if (this.items.length === 0) {
      // you cannot call any function directly in a Mutation
      this.items = [
        {
          id: uniqueId(),
          itemType: ItemTypes.NOT_SELECTED,
          data: {} as IUnit | IContainerUnit
        }
      ]
    }
  }

  @Mutation
  SET_ACTIVE_ROW_INDEX(index: number) {
    this.activeRowIndex = index
  }

  @Mutation
  SET_CURRENT_TAB(newTab: Tabs) {
    this.currentTab = newTab
  }

  @Mutation
  SET_INQUIRY(inquiry: IInquiry) {
    this.inquiry = inquiry
  }

  @Mutation
  TOGGLE_OPEN_DETAILED_SHIPMENT_FORM() {
    this.openShipmentDetailedForm = !this.openShipmentDetailedForm
  }

  @Mutation
  OPEN_DETAILED_SHIPMENT_FORM() {
    this.openShipmentDetailedForm = true
  }

  @Mutation
  CLOSE_DETAILED_SHIPMENT_FORM() {
    this.openShipmentDetailedForm = false
  }

  @Mutation
  SET_ORIGIN_ADDRESS(address: IAddress) {
    this.originAddress = address
  }

  @Mutation
  SET_ORIGIN_GOOGLE_MAP_PLACE_RESULT(
    googleMapPlaceResult: google.maps.places.PlaceResult
  ) {
    this.originGoogleMapPlaceResult =
      googleMapPlaceResult && Object.keys(googleMapPlaceResult).length > 0
        ? ({
            $type: IPostType.GooglePlaces,
            ...googleMapPlaceResult
          } as IGoogleMapPlaceResult)
        : (googleMapPlaceResult as IGoogleMapPlaceResult)
  }

  @Mutation
  SET_DESTINATION_ADDRESS(address: IAddress) {
    this.destinationAddress = address
  }

  @Mutation
  SET_DESTINATION_GOOGLE_MAP_PLACE_RESULT(
    googleMapPlaceResult: google.maps.places.PlaceResult
  ) {
    this.destinationGoogleMapPlaceResult =
      googleMapPlaceResult && Object.keys(googleMapPlaceResult).length > 0
        ? ({
            $type: IPostType.GooglePlaces,
            ...googleMapPlaceResult
          } as IGoogleMapPlaceResult)
        : (googleMapPlaceResult as IGoogleMapPlaceResult)
  }

  @Mutation
  SET_INCOTERM_ADDRESS(address: IAddress) {
    this.incotermAddress = address
  }

  @Mutation
  SET_INCOTERM_GOOGLE_MAP_PLACE_RESULT(
    googleMapPlaceResult: google.maps.places.PlaceResult
  ) {
    this.incotermGoogleMapPlaceResult =
      googleMapPlaceResult && Object.keys(googleMapPlaceResult).length > 0
        ? ({
            $type: IPostType.GooglePlaces,
            ...googleMapPlaceResult
          } as IGoogleMapPlaceResult)
        : (googleMapPlaceResult as IGoogleMapPlaceResult)
  }

  @Mutation
  SET_SHIPMENT_WEIGHT(weight: number | null) {
    this.shipmentWeight = weight
  }

  @Mutation
  SET_SHIPMENT_USER_COMPANY(companyId: string | null) {
    this.userCompanyId = companyId
  }

  @Mutation
  SET_SHIPMENT_VOLUME(volume: number | null) {
    this.shipmentVolume = volume
  }

  @Mutation
  SET_INCOTERM(incoterm: string) {
    this.incoterm = incoterm
  }

  @Mutation
  SET_IS_NOT_HAZARDOUS_SHIPMENT(isNotHazardousShipment: boolean) {
    this.isNotHazardousShipment = isNotHazardousShipment
  }

  @Mutation
  SET_IS_NOT_PERSONAL_SHIPMENT(isNotPersonalShipment: boolean) {
    this.isNotPersonalShipment = isNotPersonalShipment
  }

  @Mutation
  ADD_NEW_BOX(box: IUnit) {
    this.boxes = [...this.boxes, box]
  }

  @Mutation
  ADD_NEW_ENVELOPE(envelope: IUnit) {
    this.envelopes = [...this.envelopes, envelope]
  }

  @Mutation
  ADD_NEW_PALLET(pallet: IUnit) {
    this.pallets = [...this.pallets, pallet]
  }

  @Mutation
  ADD_NEW_CONTAINER(container: IContainerUnit) {
    this.containers = [...this.containers, container]
  }

  @Mutation
  SET_BOXES(boxes: IUnit[]) {
    this.boxes = boxes
  }

  @Mutation
  SET_ENVELOPE(envelopes: IUnit[]) {
    this.envelopes = envelopes
  }

  @Mutation
  SET_PALLETS(pallets: IUnit[]) {
    this.pallets = pallets
  }

  @Mutation
  SET_CONTAINERS(containers: IContainerUnit[]) {
    this.containers = containers
  }

  @Mutation
  SET_SHIPMENT_WEIGHT_UNIT(weightUnit: string) {
    this.shipmentWeightUnit = weightUnit
  }

  @Mutation
  SET_SHIPMENT_VOLUME_UNIT(volumeUnit: string) {
    this.shipmentVolumeUnit = volumeUnit
  }

  @Mutation
  RESET_FORM_STATE() {
    this.items = [
      {
        id: uniqueId(),
        itemType: ItemTypes.NOT_SELECTED,
        data: {} as IUnit | IContainerUnit
      }
    ]
    this.openShipmentDetailedForm = false
    this.originAddress = defaults.DEFAULT_ADDRESS
    this.destinationAddress = defaults.DEFAULT_ADDRESS
    this.incotermAddress = defaults.DEFAULT_ADDRESS
    this.weightUnitOptions = defaults.WEIGHT_UNIT_OPTIONS
    this.volumeUnitOptions = defaults.VOLUME_UNIT_OPTIONS
    this.shipmentWeight = null
    this.shipmentVolume = null
    this.shipmentWeightUnit = defaults.DEFAULT_WEIGHT_UNIT
    this.shipmentVolumeUnit = defaults.DEFAULT_VOLUME_UNIT
    this.userCompanyId = null
    this.incoterm = ''
    this.isNotHazardousShipment = false
    this.isNotPersonalShipment = false
    this.envelopes = defaults.INITIAL_ENVELOPE
    this.boxes = defaults.INITIAL_BOXES
    this.pallets = defaults.INITIAL_PALLETS
    this.containers = defaults.INITIAL_CONTAINERS
    this.currentTab = Tabs.Boxes
    this.containerOtherTypeText = ''
    this.readOnly = false
  }

  @Mutation
  SET_READ_ONLY(readOnly: boolean) {
    this.readOnly = readOnly
  }
  /**
   * ----------------------------------------
   * ACTIONS
   * ----------------------------------------
   */
  @Action({ rawError: true })
  setContainerOtherTypeText(text: string) {
    this.SET_CONTAINER_OTHER_TYPE_TEXT(text)
  }

  @Action({ rawError: true })
  async addNewItem(): Promise<boolean> {
    const newItems = this.items.concat({
      id: uniqueId(),
      itemType: ItemTypes.NOT_SELECTED,
      data: {} as IUnit | IContainerUnit
    })
    this.SET_ITEMS(newItems)
    return new Promise(resolve => {
      return resolve(true)
    })
  }

  @Action({ rawError: true })
  setItemsBasedOnItemType({
    itemId,
    itemType,
    inquiryValidations
  }: SetItemPayload): void {
    try {
      const itemFactory = new ItemFactory(itemType, inquiryValidations)
      const newItem = itemFactory.getItemBasedOnItemType()
      const newItems = this.items.map(item =>
        item.id === itemId ? { ...item, ...newItem } : item
      )
      this.SET_ITEMS(newItems)
    } catch (err) {
      console.log(err)
    }
  }

  @Action({ rawError: true })
  async deleteItem(itemId: string): Promise<boolean> {
    this.SET_ITEMS(this.items.filter(item => item.id !== itemId))

    const { totalVolume } = getTotalShipmentVolume(this.items)
    const { totalWeight } = getTotalShipmentWeight(this.items)

    if (totalVolume) {
      this.setVolume(parseFloat(totalVolume.toFixed(3)))
    }

    if (totalWeight) {
      this.setWeight(parseFloat(totalWeight.toFixed(3)))
    }

    return new Promise(resolve => {
      return resolve(true)
    })
  }

  @Action({ rawError: true })
  toggleItemIsStackable({ stackable, itemId }: IsStackablePayload) {
    const updatedItems = this.items.map(
      (currItem: InquiryItem): InquiryItem =>
        currItem.id === itemId
          ? {
              ...currItem,
              data: {
                ...currItem.data,
                isStackable: stackable
              }
            }
          : currItem
    )
    this.SET_ITEMS(updatedItems)
  }

  @Action({ rawError: true })
  updateItemQuantity({ quantity, itemIndex }: QuantityPayload) {
    const updatedItems = this.items.map((item, index) =>
      itemIndex === index
        ? {
            ...item,
            data: {
              ...item.data,
              quantity
            }
          }
        : item
    )
    this.SET_ITEMS(updatedItems)
  }

  @Action({ rawError: true })
  updateItemDimensionValidation({
    itemIndex,
    dimensionName,
    isValid,
    validationMsg
  }: DimensionValidation) {
    const updatedItems = this.items.map(
      (item: InquiryItem, index: number): InquiryItem => {
        if (itemIndex === index) {
          {
            item = {
              ...item,
              data: {
                ...item.data,
                dimensions: {
                  ...(item.data as IUnit).dimensions,
                  [dimensionName]: {
                    value: (item.data as IUnit).dimensions[dimensionName].value,
                    isValid,
                    validationMsg
                  }
                }
              }
            } as InquiryItem
          }
        }
        return item
      }
    )

    this.SET_ITEMS(updatedItems)
  }

  @Action({ rawError: true })
  updateItemDimension({
    itemIndex,
    dimensionName,
    dimensionValue
  }: DimensionPayload) {
    const newItems = this.items.map(
      (item: InquiryItem, index: number): InquiryItem => {
        if (itemIndex === index) {
          item = {
            ...item,
            data: {
              ...item.data,
              dimensions: {
                ...(item.data as IUnit).dimensions,
                [dimensionName]:
                  dimensionName !== 'unit'
                    ? {
                        ...(item.data as IUnit).dimensions[dimensionName],
                        value: dimensionValue
                      }
                    : dimensionValue
              }
            } as IUnit
          }
          item.data = item.data as IUnit
          item.data.totalVolume = getTotalVolumeByDimensions({
            length: item.data.dimensions.length.value,
            width: item.data.dimensions.width.value,
            height: item.data.dimensions.height.value,
            unit: item.data.dimensions.unit
          })
        }
        return item
      }
    )
    this.SET_ITEMS(newItems)
  }

  @Action({ rawError: true })
  async updateItemWeight({
    weight,
    itemIndex
  }: UpdateWeightPayload): Promise<boolean> {
    const newItems = this.items.map((item: InquiryItem, index: number) =>
      index === itemIndex
        ? {
            ...item,
            data: {
              ...item.data,
              weight: {
                ...(item.data as IUnit).weight,
                value: weight,
                totalWeight: getItemTotalWeight(
                  weight,
                  (item.data as IUnit).weight.unit
                )
              }
            }
          }
        : item
    )
    this.SET_ITEMS(newItems)
    return new Promise<boolean>(resolve => {
      return resolve(true)
    })
  }

  @Action({ rawError: true })
  updateItemUnitAndWeight({ newUnit, itemIndex }: UpdateWeightUnitPayload) {
    let weight = 0
    const newItems = this.items.map((item: any, index: number) => {
      if (index === itemIndex) {
        item = {
          ...item,
          data: {
            ...item.data,
            weight: {
              ...item.data.weight,
              unit: newUnit
            }
          }
        }
        weight = item.data.weight.value
      }
      return item
    })
    this.SET_ITEMS(newItems)
    this.updateItemWeight({
      weight: weight,
      itemIndex
    })
  }

  @Action({ rawError: true })
  setContainerItem({ itemIndex, field, value }: ContainerItemPayload) {
    const updatedItems = this.items.map((item, index) =>
      index === itemIndex
        ? {
            ...item,
            data: {
              ...item.data,
              [field]: value
            }
          }
        : item
    )
    this.SET_ITEMS(updatedItems)
  }

  @Action({ rawError: true })
  setItemisQuantityInvalid({ rowIndex, invalid }: SetQuantityInvalidPayload) {
    const updatedItems = this.items.map((item, index) => {
      if (index === rowIndex) {
        return {
          ...item,
          data: {
            ...item.data,
            isQuantityInvalid: invalid
          }
        }
      }

      return item
    })
    this.SET_ITEMS(updatedItems)
  }

  @Action({ rawError: true })
  setItemisWeightInvalid() {
    const updatedItems = this.items.map(item => {
      if (
        item.itemType !== ItemTypes.NOT_SELECTED &&
        item.itemType !== ItemTypes.FULL_CONTAINER
      ) {
        const unit = item.data as IUnit

        return {
          ...item,
          data: {
            ...item.data,
            isWeightInvalid: unit.weight.value <= 0 ? true : false
          }
        }
      } else {
        return item
      }
    })
    this.SET_ITEMS(updatedItems)
  }

  @Action({ rawError: true })
  setItemWeightValid() {
    const updatedItems = this.items.map(item => {
      if (
        item.itemType !== ItemTypes.NOT_SELECTED &&
        item.itemType !== ItemTypes.FULL_CONTAINER
      ) {
        return {
          ...item,
          data: {
            ...item.data,
            isWeightInvalid: false
          }
        }
      } else {
        return item
      }
    })
    this.SET_ITEMS(updatedItems)
  }

  @Action({ rawError: true })
  setActiveRowIndex(index: number) {
    this.SET_ACTIVE_ROW_INDEX(index)
  }

  @Action({ rawError: true })
  setCurrentTab(newTab: Tabs) {
    this.SET_CURRENT_TAB(newTab)
  }

  @Action({ rawError: true })
  setOriginAddress({
    address,
    googleMapPlaceResult
  }: {
    address: IAddress
    googleMapPlaceResult: google.maps.places.PlaceResult
  }): void {
    this.SET_ORIGIN_ADDRESS(address)
    this.SET_ORIGIN_GOOGLE_MAP_PLACE_RESULT(googleMapPlaceResult)
  }

  @Action({ rawError: true })
  setDestinationAddress({
    address,
    googleMapPlaceResult
  }: {
    address: IAddress
    googleMapPlaceResult: google.maps.places.PlaceResult
  }): void {
    this.SET_DESTINATION_ADDRESS(address)
    this.SET_DESTINATION_GOOGLE_MAP_PLACE_RESULT(googleMapPlaceResult)
  }

  @Action({ rawError: true })
  setIncotermAddress({
    address,
    googleMapPlaceResult
  }: {
    address: IAddress
    googleMapPlaceResult: google.maps.places.PlaceResult
  }): void {
    this.SET_INCOTERM_ADDRESS(address)
    this.SET_INCOTERM_GOOGLE_MAP_PLACE_RESULT(googleMapPlaceResult)
  }

  @Action({ rawError: true })
  setWeight(weight: number | null): void {
    this.SET_SHIPMENT_WEIGHT(weight)
  }

  @Action({ rawError: true })
  setUserCompanyId(userCompanyId: string | null): void {
    this.SET_SHIPMENT_USER_COMPANY(userCompanyId)
  }

  @Action({ rawError: true })
  setVolume(volume: number | null): void {
    this.SET_SHIPMENT_VOLUME(volume)
  }

  @Action({ rawError: true })
  setIncoterm(incoterm: string): void {
    this.SET_INCOTERM(incoterm)
  }

  @Action({ rawError: true })
  setIsNotHazardousShipment(isNotHazardousShipment: boolean): void {
    this.SET_IS_NOT_HAZARDOUS_SHIPMENT(isNotHazardousShipment)
  }

  @Action({ rawError: true })
  setIsNotPersonalShipment(isNotPersonalShipment: boolean): void {
    this.SET_IS_NOT_PERSONAL_SHIPMENT(isNotPersonalShipment)
  }

  @Action({ rawError: true })
  setBoxes(boxes: IUnit[]) {
    this.SET_BOXES(boxes)
  }

  @Action({ rawError: true })
  setPallets(pallets: IUnit[]) {
    this.SET_PALLETS(pallets)
  }

  @Action({ rawError: true })
  addNewBox(): void {
    this.ADD_NEW_BOX(cloneDeep(defaults.getDefaultBox()))
  }

  @Action({ rawError: true })
  addNewPallet(): void {
    this.ADD_NEW_PALLET(cloneDeep(defaults.getPallet(120, 80)))
  }

  @Action({ rawError: true })
  addNewContainer(): void {
    this.ADD_NEW_CONTAINER(cloneDeep(defaults.getDefaultContainer()))
  }

  @Action({ rawError: true })
  setShipmenWeightUnit(weightUnit) {
    this.SET_SHIPMENT_WEIGHT_UNIT(weightUnit)
  }

  @Action({ rawError: true })
  setShipmentVolumeUnit(volumeUnit) {
    this.SET_SHIPMENT_VOLUME_UNIT(volumeUnit)
  }

  @Action({ rawError: true })
  toggleDetailedShipmentForm() {
    this.TOGGLE_OPEN_DETAILED_SHIPMENT_FORM()
  }

  @Action({ rawError: true })
  uncheckContainerType(containerId: string) {
    const updatedContainers = this.containers.map(
      (container: IContainerUnit): IContainerUnit =>
        container.id === containerId
          ? {
              ...container,
              containerEdges: '',
              type: ''
            }
          : container
    )
    this.SET_CONTAINERS(updatedContainers)
  }

  @Action({ rawError: true })
  async prefillInquiryForm({
    inquiryId,
    envelopesValidations
  }: {
    inquiryId: string
    envelopesValidations: IEnvelopeValidationRules
  }) {
    // get inquiry
    const { status, data: inquiry } = await new InquiryService().getInquiryById<
      IInquiry
    >(inquiryId)
    if (status) {
      let itemUnits = [] as InquiryItem[]
      const containerUnits = getContainerUnitsFromInquiry(inquiry.containers)
      if (containerUnits.length > 0) {
        itemUnits = [
          {
            id: uniqueId(),
            itemType: ItemTypes.FULL_CONTAINER,
            data: { ...containerUnits[0] } as IContainerUnit
          }
        ]
      }

      itemUnits = [
        ...itemUnits,
        ...getItemUnitsFromInquiry(inquiry.items, envelopesValidations)
      ]
      if (itemUnits.length >= 1) {
        this.TOGGLE_OPEN_DETAILED_SHIPMENT_FORM()
      }
      if (itemUnits.length === 0 && containerUnits.length === 0) {
        itemUnits = [
          {
            id: uniqueId(),
            itemType: ItemTypes.NOT_SELECTED,
            data: {} as IUnit | IContainerUnit
          }
        ]
      }

      const isInquiryExpired =
        inquiry.status === InquiryStatus.Expired ||
        inquiry.status === InquiryStatus.Rejected

      const path = router.currentRoute.path

      if (isInquiryExpired && !path.includes(InquiryRouteType.Create))
        this.SET_READ_ONLY(true)
      this.SET_INQUIRY(inquiry)
      this.SET_ORIGIN_ADDRESS(inquiry.originAddress)
      this.SET_DESTINATION_ADDRESS(inquiry.destinationAddress)
      this.SET_SHIPMENT_WEIGHT(inquiry.totalWeight)
      this.SET_SHIPMENT_VOLUME(inquiry.totalVolume)
      this.SET_SHIPMENT_WEIGHT_UNIT(inquiry.weightUnitName || 'kg')
      this.SET_SHIPMENT_VOLUME_UNIT(inquiry.volumeUnitName || 'cbm')
      this.SET_SHIPMENT_USER_COMPANY(inquiry.companyId)
      this.SET_INCOTERM_ADDRESS(inquiry.incotermAddress || ({} as IAddress))
      this.SET_INCOTERM(inquiry.incotermId || '')
      this.SET_IS_NOT_HAZARDOUS_SHIPMENT(!inquiry.isCargoHazardous as boolean)
      this.SET_IS_NOT_PERSONAL_SHIPMENT(!inquiry.isCargoPersonal as boolean)
      this.SET_ITEMS(itemUnits)
      this.SET_CONTAINERS(containerUnits)
    } else {
      router.push('/new-inquiry')
    }
  }

  @Action({ rawError: true })
  async prefillInquiryFormUsingShipment(shipmentId: string) {
    const shipmentService = new ShipmentService()
    const shipmentAddressService = new ShipmentAddressesService()
    const shipmentItemService = new ShipmentItemService()
    /**
     *
     * Get the shipment, addresses and items
     */
    const [
      singleShipmentResponse,
      shipmentAddressResponse,
      shipmentItemsResponse
    ] = await Promise.all([
      shipmentService.getSingleShipment<any>(shipmentId),
      shipmentAddressService.getShipmentAddress<any>(shipmentId),
      shipmentItemService.getShipmentItems<any>(shipmentId)
    ])

    /**
     * If anything fails, move to fallback case
     */
    if (
      !singleShipmentResponse.status ||
      !shipmentAddressResponse.status ||
      !shipmentItemsResponse.status
    ) {
      router.push('/new-inquiry')
      return
    }

    const originAddress = getAddress(
      shipmentAddressResponse.data,
      AddressPoint.Origin
    )
    const destinationAddress = getAddress(
      shipmentAddressResponse.data,
      AddressPoint.Destination
    )
    const incotermAddress = getAddress(
      shipmentAddressResponse.data,
      AddressPoint.Incoterm
    )
    const itemUnits = getItemUnitsFromShipment(shipmentItemsResponse.data)
    if (itemUnits.length >= 1) {
      this.TOGGLE_OPEN_DETAILED_SHIPMENT_FORM()
    }
    const shipment = singleShipmentResponse.data as IShipment

    this.SET_ORIGIN_ADDRESS(originAddress)
    this.SET_DESTINATION_ADDRESS(destinationAddress)
    this.SET_SHIPMENT_WEIGHT(shipment.totalWeight)
    this.SET_SHIPMENT_VOLUME(shipment.totalVolume)
    this.SET_SHIPMENT_WEIGHT_UNIT(shipment.weightUnitName || 'kg')
    this.SET_SHIPMENT_VOLUME_UNIT(shipment.volumeUnitName || 'cbm')
    this.SET_SHIPMENT_USER_COMPANY(shipment.companyId)
    this.SET_INCOTERM_ADDRESS(incotermAddress || ({} as IAddress))
    this.SET_INCOTERM(shipment.incotermId || '')
    this.SET_ITEMS(itemUnits)
    this.SET_IS_NOT_HAZARDOUS_SHIPMENT(false) //! problem
    this.SET_IS_NOT_PERSONAL_SHIPMENT(false) //! problem
    // this.SET_CONTAINERS(containerUnits) //! problem
  }

  @Action({ rawError: true })
  resetFormData() {
    this.RESET_FORM_STATE()
  }
  /**
   * ----------------------------------------
   * GETTERS
   * ----------------------------------------
   */

  get getInquiry(): IInquiry {
    return this.inquiry as IInquiry
  }

  get getOriginAddress(): IAddress {
    return this.originAddress
  }

  get getDestinationAddress(): IAddress {
    return this.destinationAddress
  }

  get getOriginGoogleMapPlaceResult(): IGoogleMapPlaceResult {
    return this.originGoogleMapPlaceResult
  }

  get getDestinationGoogleMapPlaceResult(): IGoogleMapPlaceResult {
    return this.destinationGoogleMapPlaceResult
  }

  get getFreightModeSubTypes(): Array<IInquiryFreightModeSubTypes> | undefined {
    return this.inquiry?.freightModeSubtypes
  }

  get getItemsDimensionValidStatus(): Array<boolean> {
    return this.items.map(item => {
      const dimensions = (item.data as IUnit).dimensions
      return dimensions &&
        dimensions.length &&
        dimensions.width &&
        dimensions.height
        ? dimensions.length.isValid &&
            dimensions.width.isValid &&
            dimensions.height.isValid
        : true
    })
  }

  get getItemsDimensions() {
    return this.items.map(item => {
      const dimensions = (item.data as IUnit).dimensions
      return {
        length: {
          isValid:
            dimensions && dimensions.length ? dimensions.length.isValid : true,
          validationMsg:
            dimensions && dimensions.length
              ? dimensions.length.validationMsg
              : ''
        },
        width: {
          isValid:
            dimensions && dimensions.width ? dimensions.width.isValid : true,
          validationMsg:
            dimensions && dimensions.width ? dimensions.width.validationMsg : ''
        },
        height: {
          isValid:
            dimensions && dimensions.height ? dimensions.height.isValid : true,
          validationMsg:
            dimensions && dimensions.height
              ? dimensions.height.validationMsg
              : ''
        }
      }
    })
  }
}

export const NewInquiryFormModule = getModule(NewInquiryForm)
