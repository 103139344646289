import { AuthModule, ShipmentDetailModule } from '@/store/modules'
import { AccessibilityType } from '@/utils/constants'

export const beforeEnter = async (_to: any, _from: any, next: any) => {
  await ShipmentDetailModule.getSingleShipment(_to.params.id)
  if (_to.name === 'Messages') {
    ShipmentDetailModule.setIsComingForShipmentDetailChat(true)
  }
  if (
    ShipmentDetailModule.shipment &&
    ShipmentDetailModule.shipment.waitingForApproval &&
    AuthModule.user.companies &&
    AuthModule.user.companies
      .filter(x => x.accessibilityType === AccessibilityType.Group)
      .findIndex(x => x.id === ShipmentDetailModule.shipment.companyId) !== -1
  ) {
    next(`/waiting-for-approval`)
  } else {
    next()
  }
}
