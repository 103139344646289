<template>
  <div class="ShippingTabBtn">
    <h1 class="pageTitle font-light text-gray-darker">New Inquiry</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // for now the current active tab is the new shipment inquery.
      tabIndex: 3
    }
  }
}
</script>

<style></style>
