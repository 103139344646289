import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { getConfirmModalConfig } from '@/utils/global-setting'

@Component({})
export class ValidationMessagesMixin extends Vue {
  async showContainersModal() {
    await this.$bvModal.hide('containerQtyModal')
    return await this.$bvModal.msgBoxConfirm(
      `Please add container's quantity`,
      {
        ...getConfirmModalConfig('containerQtyModal'),
        okTitle: 'Okay',
        cancelTitle: 'Discard'
      }
    )
  }

  async showQuantityModal(msg: string): Promise<boolean> {
    await this.$bvModal.hide('boxOrPalletQty')
    return await this.$bvModal.msgBoxConfirm(msg, {
      ...getConfirmModalConfig('boxOrPalletQty'),
      okTitle: 'Okay',
      cancelTitle: 'Cancel'
    })
  }

  async showSameAddressModal() {
    await this.$bvModal.hide('sameAddressModal')
    await this.$bvModal.msgBoxOk(
      `Sorry, but domestic transport solutions are not available in MyDello. We are focused on transport between the continents.
      Please contact support for further assistance.`,
      {
        ...getConfirmModalConfig('sameAddressModal'),
        okTitle: 'Ok',
        titleClass: 'hide-title',
        bodyClass: 'validation-modal same-address-modal-body',
        footerClass: 'p-2 no-border same-address-modal-footer'
      }
    )
  }

  createMessageNodes(tag: string, messages: string[], classes: string[]) {
    return messages.map(message =>
      this.$createElement(tag, { class: [...classes] }, message)
    )
  }

  async isHazardousModal() {
    const messages = this.createMessageNodes(
      'p',
      [
        'Please confirm Do you want to ship dangerous goods (i.e batteries, liquids, etc).'
      ],
      ['modal-theme-text']
    )
    await this.$bvModal.hide('isNotHazardousShipment')
    return await this.$bvModal.msgBoxConfirm([...messages], {
      ...getConfirmModalConfig('isNotHazardousShipment'),
      titleClass: 'hide-title',
      bodyClass: 'px-3 py-0 validation-modal same-address-modal-body',
      okVariant: 'default',
      cancelVariant: 'secondary',
      footerClass: 'shipment-modes-footer',
      hideHeaderClose: true,
      okTitle: 'Is dangerous',
      cancelTitle: 'Not dangerous'
    })
  }

  async isShipmentPersonalModal() {
    const messages = this.createMessageNodes(
      'p',
      ['Please confirm shipper and/or consignee is a private person.'],
      ['modal-theme-text']
    )
    await this.$bvModal.hide('isNotPersonalShipment')
    return await this.$bvModal.msgBoxConfirm([...messages], {
      ...getConfirmModalConfig('isNotHazardousShipment'),
      titleClass: 'hide-title',
      bodyClass: 'px-3 py-0 validation-modal same-address-modal-body',
      okVariant: 'secondary',
      cancelVariant: 'default',
      footerClass: 'shipment-modes-footer',
      hideHeaderClose: true
    })
  }

  /**
   * Detailed shipment form validation modals
   *
   */
  async inquiryHasExpiredModal() {
    await this.$bvModal.hide('expiredModal')
    return await this.$bvModal.msgBoxConfirm(
      'This inquiry has expired. Would you like to create new inquiry?',
      {
        ...getConfirmModalConfig('expiredModal'),
        bodyClass: 'px-3 py-0 validation-modal',
        okVariant: 'secondary',
        cancelVariant: 'default',
        footerClass: 'shipment-modes-footer'
      }
    )
  }

  async confirmDeleteItemModal() {
    return await await this.$bvModal.msgBoxConfirm(
      'Are you sure, you want to delete this row?',
      {
        ...getConfirmModalConfig('expiredModal'),
        titleClass: 'hide-title',
        id: 'confirm-delete-modal',
        bodyClass: 'px-3 py-0 validation-modal same-address-modal-body',
        okVariant: 'secondary',
        cancelVariant: 'default',
        footerClass: 'shipment-modes-footer confirm-delete-footer',
        autoFocusButton: 'ok'
      }
    )
  }

  async showInquiryAddressBookValidationMessage() {
    const validationMessage =
      'Do you want to make inquiry on behalf of "Selected company"?'
    this.$bvModal.hide('qtyModal')
    return await this.$bvModal.msgBoxConfirm(
      validationMessage,
      getConfirmModalConfig('qtyModal')
    )
  }
}
