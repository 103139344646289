import axios from 'axios'

export class ClientCountry {
  public async getCountry() {
    let country = ''
    const axiosResponse = await axios
      .create({
        baseURL: process.env.VUE_APP_IP_GEO_LOCATION_URL
      })
      .get(`v1/?api_key=${process.env.VUE_APP_IP_GEO_LOCATION_API_KEY}`)

    if (
      axiosResponse.status === 200 ||
      axiosResponse.status === 201 ||
      axiosResponse.status === 204
    ) {
      country = axiosResponse.data.country
    }
    return country
  }
}

export default new ClientCountry()
