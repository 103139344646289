<template>
  <div>
    <div
      :class="{
        main_login_wrapper_without_bg: $route.query && $route.query.iFrame,
        main_login_wrapper: $route.query && !$route.query.iFrame
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="left_login_box">
              <transition name="fade" mode="out-in">
                <router-view />
              </transition>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="right_login_content">
              <app-other-half />
            </div>
          </div>
        </div>
        <div class="">
          <div class="bottom_logo">
            <b-img
              v-if="$route.name === 'Confirm Email' && !isMobile()"
              class="group-photo"
              alt="Group image"
              fluid
              center
              width="1000"
              :src="require('../../assets/images/group-picture.png')"
            ></b-img>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import OtherHalf from '../../components/landing-page-other-half/index.vue'
import { MobileDetectingMixin } from '@/mixins'

@Component({
  components: {
    appOtherHalf: OtherHalf
  }
})
export default class LandingPage extends Mixins(MobileDetectingMixin) {}
</script>

<style lang="scss">
.group-photo {
  position: relative;
  top: 10%;
}

.main_login_wrapper_without_bg {
  position: relative;
  padding-top: 50px;
  min-height: 100vh;
  .container {
    .row {
      align-items: center;
      height: 100%;
    }
  }
}

.main_login_wrapper {
  position: relative;
  padding-top: 50px;
  min-height: 100vh;
  .container {
    .row {
      align-items: center;
      height: 100%;
    }
  }

  &::before {
    background: url(../../assets/images/bar.svg) no-repeat;
    height: 100vh;
    content: '';
    margin: 0;
    top: 0;
    position: fixed;
    left: 185px;
    background-size: auto 100%;
    width: 100%;
  }

  &::after {
    right: 0;
    bottom: 0;
    position: fixed;
    pointer-events: none;
    background: url(../../assets/images/bar.svg) no-repeat;
    display: block;
    height: 635px;
    content: '';
    width: 370px;
  }
}

.left_login_box {
  z-index: 1000;
  position: relative;
  width: 352px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: 40px;
  padding: 32px;

  .forgot_tabs {
    span {
      padding-left: 0px !important;
      border: none !important;
    }
  }

  form {
    padding: 0px;
  }

  .logo_wrap {
    margin-bottom: 15px;
  }

  .form-control {
    border: 1px solid #a8bcc7;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: none !important;
    font-size: 14px;
    color: #000027;
    font-family: 'ReplicaPro';
    font-weight: 400;
    height: 45px;
  }

  select {
    border: 1px solid #a8bcc7;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: none !important;
    font-size: 14px;
    color: #000027;
    font-family: 'ReplicaPro';
    font-weight: 400;
    height: 45px;
  }

  .form-control::placeholder {
    color: #a6b4bc;
    font-family: 'ReplicaPro';
  }

  .loginTabs {
    margin: 0px -32px !important;
    padding: 0px 32px;

    li {
      a {
        color: #000027 !important;
        font-size: 15px;
        border: none;
      }
    }
  }

  .registration-btn-group {
    .btn-outline-secondary {
      border-color: #a8bcc7;
      color: #000027 !important;
      font-size: 12px;
      box-shadow: none !important;
    }

    svg {
      fill: #000027;
      font-size: 13px !important;
    }
  }

  .custom-checkbox {
    font-family: 'ReplicaPro';
    font-weight: 400;
  }

  .invalid-feedback {
  }

  h6 {
    color: #1a212e;
    font-size: 18px;
    font-weight: 300;
    font-family: 'ReplicaPro';
    margin-bottom: 4px !important;
    b {
      font-family: 'ReplicaPro';
      font-weight: 400;
    }
  }
}

.right_login_content {
  .container {
    width: auto !important;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .right_login_content {
    display: none;
  }

  .main_login_wrapper,
  .main_login_wrapper_without_bg {
    .container {
      padding: 0px;
      .row {
        margin: 0px;
        padding: 0px;
        > .col-sm-6 {
          padding: 0px;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    .left_login_box {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      padding: 24px;

      .loginTabs {
        margin: 0px -24px !important;
        padding: 0px 24px;
      }
    }

    .py-4 {
      padding-bottom: 0px !important;
    }
  }

  .main_login_wrapper::before,
  .main_login_wrapper_without_bg::before {
    left: 0px;
  }

  .main_login_wrapper::after,
  .main_login_wrapper_without_bg::after {
    display: none;
  }
}

.main_login_wrapper,
.main_login_wrapper_without_bg {
  .bottom_logo {
    position: relative;
    z-index: 250;
    padding-top: 0px;
    margin-top: -240px;
  }
}
</style>
