<template>
  <div class="form-wrapper-right container mr-auto my-auto ">
    <app-login-other-half
      v-if="$route.name !== 'Sign Up' && $route.name !== 'Confirm Email'"
    />
    <app-sign-up-other-half v-if="$route.name == 'Sign Up'" />
    <app-confirm-email-other-half v-if="$route.name == 'Confirm Email'" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import LoginOtherHalf from './LoginOtherHalf.vue'
import SignUpOtherHalf from './SignUpOtherHalf.vue'
import ConfirmEmailOtherHalf from './ConfirmEmailOtherHalf.vue'

@Component({
  components: {
    appLoginOtherHalf: LoginOtherHalf,
    appSignUpOtherHalf: SignUpOtherHalf,
    appConfirmEmailOtherHalf: ConfirmEmailOtherHalf
  }
})
export default class OtherHalf extends Vue {}
</script>
