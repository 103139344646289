import Vue from 'vue'

Vue.filter('formatQuantityUnit', (value: number) =>
  !value || isNaN(value)
    ? '0 Units'
    : value > 1
    ? `${value} Units`
    : `${value} Unit`
)

Vue.filter('formatVolumUnit', function(value: number | string) {
  const parsedValue = parseFloat(value.toString()).toFixed(3)
  return typeof value === 'number' && isNaN(value)
    ? '0 m3'
    : `${parsedValue} m3`
})
