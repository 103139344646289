import { ICustomDirective, IUser } from '@/models'
import { getUserData } from '@/utils/cookies'

export const RemoveForSingleCompany: ICustomDirective = {
  id: 'remove-for-single-company',
  defination: {
    // When the bound element is inserted into the DOM...
    inserted: function(el, binding, vnode) {
      if (
        (JSON.parse(getUserData() as string) as IUser).companies.length == 1
      ) {
        vnode.elm?.parentElement?.removeChild(vnode.elm)
      }
    }
  }
}
