import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'

export class ShipmentContactService {
  async getShipmentContacts<T>(shipmentId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/shipment/${shipmentId}/contacts`
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}
export default new ShipmentContactService()
