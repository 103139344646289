import { AuthModule } from '@/store/modules'
import { ICustomDirective } from '@/models'

export const VendorCustomerCompany: ICustomDirective = {
  id: 'remove-for-vendor-customer-company',
  defination: {
    // When the bound element is inserted into the DOM...
    inserted: function(el, binding, vnode) {
      if (AuthModule.getVendorCustomerCompanies.length > 0) {
        vnode.elm?.parentElement?.removeChild(vnode.elm)
      }
    }
  }
}
