import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators'
import store from '@/store'
import { ICompany, IParams, IUser } from '@/models'
import { CompanyService } from '@/services/company/company'
import { getUserData, removeToken, removeUserData } from '@/utils/cookies'

@Module({ dynamic: true, store, name: 'company', namespaced: true })
class Company extends VuexModule {
  public companies: ICompany[] = []
  public userCompanyId = getUserData() ? this.getUserCompanyId() : null

  private getUserCompanyId() {
    const user = JSON.parse(getUserData() as string) as IUser
    if (user && user.contact && user.contact.companyId) {
      return user.contact.companyId
    } else {
      removeToken()
      removeUserData()
      window.location.replace('/login')
      return null
    }
  }

  @Mutation
  SET_COMPANIES(companies: ICompany[]) {
    this.companies = [...companies]
  }

  @Mutation
  SET_USER_COMPANY_ID(userCompanyId: string) {
    this.userCompanyId = userCompanyId
  }

  @Action
  public async getAllCompanies() {
    const params: IParams = {
      sorts: ['name'],
      pageSize: 1000
    }
    const { status, data } = await new CompanyService().getAllCompanies<
      ICompany[]
    >(params)
    if (status) {
      this.SET_COMPANIES(data)
    }
  }
}

export const CompanyModule = getModule(Company)
