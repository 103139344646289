import API from '@/http/http-common'
import { IError, IParams } from '@/models'
import { ICompanyAddressBook } from '@/models/address-book'
import { ServiceResponse } from '@/services/service-response'
import { customizeParams } from '@/utils/service-helper'
import { AxiosResponse } from 'axios'

export class AddressBookService {
  async getCompanyAddressBook<T>(params: IParams = {}): ServiceResponse<T> {
    const queryParams = customizeParams(params)
    try {
      const res = await API.externalInstance.get(
        `/company-address-book-entry`,
        queryParams
      )
      return {
        status: true,
        data: res.data,
        totalCount: res.headers['x-total-count']
          ? res.headers['x-total-count']
          : ''
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async deleteCompanyAddressBook<T>(id: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.delete(
        `/company-address-book-entry/${id}`
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async addCompanyAddressBook<T>(
    companyAddressBook: ICompanyAddressBook
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/company-address-book-entry`,
        companyAddressBook
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      const error = e as AxiosResponse
      return {
        status: false,
        data: {} as T,
        errorMsg: this.formatErrorMessages(error)
      }
    }
  }

  async updateCompanyAddressBook<T>(
    companyAddressBook: ICompanyAddressBook
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put(
        `/company-address-book-entry`,
        companyAddressBook
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      const error = e as AxiosResponse
      return {
        status: false,
        data: {} as T,
        errorMsg: this.formatErrorMessages(error)
      }
    }
  }

  async exportCompanyAddressBook<T>(companyId: string) {
    try {
      const res = await API.externalInstance.get(
        `/company-address-book-entry/export/excel?companyId=${companyId}`,
        { responseType: 'blob' }
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async uploadCompanyAddressBook<T>(data: FormData): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        '/company-address-book-entry/import/excel',
        data,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
      return { status: true, data: res.data }
    } catch (e) {
      const error = e as IError
      return { status: false, data: {} as T, errorMsg: error.data.title }
    }
  }

  formatErrorMessages(error: AxiosResponse): string {
    if (error && error.data && error.data.errors) {
      if (error.status === 500) {
        return error.data.title as string
      } else if (error.status === 400) {
        const errors = error.data.errors
        let formattedErrors: any = []
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [key, message] of Object.entries(errors)) {
          formattedErrors = [...formattedErrors, ...(message as any)]
        }
        return formattedErrors.join(', ')
      } else {
        return 'Something went wrong, Please contact MyDello support team.'
      }
    } else {
      return 'Something went wrong, Please contact MyDello support team.'
    }
  }
}

export default new AddressBookService()
