import { ISelectOption } from '@/models'
import { APIURL, Regions } from './constants'

export const weightUnitOptions: Array<ISelectOption> = [
  {
    label: 'kg',
    value: 'kg'
  },
  {
    label: 'g',
    value: 'g'
  },
  {
    label: 'oz',
    value: 'oz'
  },
  {
    label: 'lb',
    value: 'lb'
  }
]

export const volumeUnitOptions = [
  {
    label: 'm³',
    value: 'cbm'
  },
  {
    label: 'cm³',
    value: 'ccm'
  },
  {
    label: 'ft³',
    value: 'cft'
  },
  {
    label: 'in³',
    value: 'cin'
  }
]

export const apiOptions = (): Array<ISelectOption> => {
  const env = process.env.NODE_ENV
  if (env === 'production')
    return [
      {
        text: Regions.Estonia,
        value: {
          region: Regions.Estonia,
          api: APIURL.Estonia
        }
      },
      {
        text: Regions.Latvia,
        value: {
          region: Regions.Latvia,
          api: APIURL.Latvia
        }
      },
      {
        text: Regions.Lithuania,
        value: {
          region: Regions.Lithuania,
          api: APIURL.Lithuania
        }
      },
      {
        text: Regions.Sweden,
        value: {
          region: Regions.Sweden,
          api: APIURL.Estonia
        }
      },
      {
        text: Regions.OtherEU,
        value: {
          region: Regions.OtherEU,
          api: APIURL.Estonia
        }
      }
    ]
  else
    return [
      {
        text: Regions.PreLive,
        value: {
          region: Regions.PreLive,
          api: APIURL.PreLive
        }
      },
      {
        text: Regions.PreLive2,
        value: {
          region: Regions.PreLive2,
          api: APIURL.PreLive2
        }
      }
    ]
}
