import store from '@/store'

/**
 * A module for overall conversion logic.
 * The conversions will be loaded from the store
 * @export
 * @class UnitConversion
 */
export class UnitConversion {
  /**
   *
   *
   * @param {string} fromUnit
   * @param {string} toUnit
   * @memberof UnitConversion
   */
  public getConversionRate = (
    fromUnit: string,
    toUnit: string
  ): number | undefined => {
    const conversions = store.getters['unitConversion/getConversions']
    const conversion = conversions.find(
      c => c.fromUnitName === fromUnit && c.toUnitName === toUnit
    )
    if (conversion) return conversion.conversionRate
    return
  }

  /**
   *
   *
   * @param {string} from
   * @param {string} to
   * @param {number} value
   * @return {*}  {number}
   * @memberof UnitConversion
   */
  public convert(from: string, to: string, value: number): number {
    const conversionRate = this.getConversionRate(from, to) || 0
    return conversionRate
      ? +parseFloat((value * conversionRate).toString())
      : value
  }
}
