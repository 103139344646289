import axios, { AxiosInstance } from 'axios'
import {
  requestConfig,
  requestError,
  responseConfig,
  responseError
} from '@/http/http-intercepter'
import { setBaseURL, getBaseURL } from '@/utils/cookies'

const API = new (class API {
  public externalInstance: AxiosInstance

  private baseURL = getBaseURL() ? getBaseURL() : process.env.VUE_APP_BASE_URL
  private headers = {
    'Content-Type': 'application/json'
  }

  constructor() {
    /*
     * use baseURL/external-api
     */
    this.externalInstance = axios.create({
      baseURL: `${this.baseURL}/external-api`,
      headers: this.headers
    })

    //adding interceptor for request
    this.externalInstance.interceptors.request.use(requestConfig, requestError)

    //adding interceptor for response
    this.externalInstance.interceptors.response.use(
      responseConfig,
      responseError
    )
  }

  public updateBaseURL(uri: string) {
    this.externalInstance.defaults.baseURL = `${uri}/external-api`
    setBaseURL(uri)
  }
})()
// Make the instance debuggable
if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  ;(window as any).API = API
}
export default API
