<template>
  <img :src="src" :height="height" :width="width" />
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getFreightModeIcon } from '@/utils/global-setting'

@Component
export default class FreightModeIcon extends Vue {
  @Prop() private freightModeCode!: string

  @Prop({ type: Number, default: 38 }) height!: number
  @Prop({ type: Number, default: 38 }) width!: number

  get src() {
    return getFreightModeIcon(this.freightModeCode)
  }
}
</script>
