import {
  IAddress,
  ICargoType,
  IContainer,
  IContainerUnit,
  IEnvelopeValidationRules,
  IExtraFieldType,
  IGatewayResponse,
  IInquiryFilters,
  IInquiryItem,
  IInquirySolution,
  InquiryItem,
  ISelectOption,
  IShipmentItem
} from '@/models'
import { AddressPoint, ContainerTypes, ItemTypes } from '@/utils/constants'
import {
  getMaxFromArray,
  getMinFromArray,
  getMinMaxFromArray
} from '@/utils/global-setting'
import { isNull, uniqueId } from 'lodash'
import { UnitConversion } from '@/utils/unit-conversion'
import { QuoteRouteService } from '@/services/quote/route'

const unitConversion = new UnitConversion()
/**
 *
 * filter the solutions with respect to price
 * @param {IInquiryFilters} filterParams
 * @param {IInquirySolution} solution
 * @return {*}  {boolean}
 */
export const filterByPrice = (
  filterParams: IInquiryFilters,
  solution: IInquirySolution,
  inquirySolutions?: IInquirySolution[]
): boolean => {
  const [priceMin, priceMax] = getMinMaxFromArray(inquirySolutions, 'sellTotal')
  if (
    Object.keys(filterParams).length > 0 &&
    (priceMin !== filterParams.priceMin || priceMax !== filterParams.priceMax)
  ) {
    return (
      solution.sellTotal >= filterParams.priceMin &&
      solution.sellTotal <= filterParams.priceMax
    )
  }
  return true
}

interface Dimension {
  height: number
  length: number
  width: number
  unit: string
}

export const getTotalVolumeByDimensions = (dimensions: Dimension): number => {
  const { length, width, height, unit } = dimensions
  let conversionRate: number | undefined
  let totalVolume
  if (unit !== 'm') {
    conversionRate = unitConversion.getConversionRate(unit, 'm')
  }
  if (conversionRate) {
    totalVolume =
      length *
      conversionRate *
      (width * conversionRate) *
      (height * conversionRate)
  } else {
    totalVolume = length * width * height
  }
  totalVolume = parseFloat(totalVolume.toFixed(3))
  return totalVolume
}

export const getItemTotalWeight = (weight: number, unit: string): number => {
  let conversionRate: number | undefined
  let totalWeight
  if (weight && unit !== 'kg') {
    conversionRate = unitConversion.getConversionRate(unit, 'kg')
  }
  if (conversionRate) {
    totalWeight = parseFloat((weight * conversionRate).toFixed(3))
  } else {
    totalWeight = weight
  }
  return totalWeight
}

/**
 *
 * filter the solutions with respect to transit Days
 * @param {IInquiryFilters} filterParams
 * @param {IInquirySolution} solution
 * @return {*}  {boolean}
 */
export const filterByTransitDays = (
  filterParams: IInquiryFilters,
  solution: IInquirySolution,
  inquirySolutions
): boolean => {
  const [daysMin, daysMax] = [
    getMinFromArray(inquirySolutions, 'transitTimePrognosedMin'),
    getMaxFromArray(inquirySolutions, 'transitTimePrognosedMdn')
  ]
  if (
    Object.keys(filterParams).length > 0 &&
    (daysMin !== filterParams.daysMin || daysMax !== filterParams.daysMax)
  ) {
    if (
      solution.transitTimePrognosedMin === null ||
      solution.transitTimePrognosedMdn === null
    )
      return false
    return (
      solution.transitTimePrognosedMin >= filterParams.daysMin &&
      solution.transitTimePrognosedMdn <= filterParams.daysMax
    )
  }

  return true
}

/**
 *
 * filter the solutions with respect to freight mode codes
 * @param {IInquiryFilters} filterParams
 * @param {IInquirySolution} solution
 * @return {*}  {boolean}
 */
export const filterByFreightModes = (
  filterParams: IInquiryFilters,
  solution: IInquirySolution
): boolean => {
  if (Object.keys(filterParams).length > 0) {
    if (!filterParams.freightModeFilter.length) return false
    else
      return filterParams.freightModeFilter.includes(solution.freightModeCode)
  } else {
    return true
  }
}

/**
 *
 *
 * @param {IInquirySolution[]} solutions
 * @return {*}  {string[]}
 */
export const findAllFreightModes = (
  solutions: IInquirySolution[]
): string[] => {
  const result: string[] = []
  solutions.forEach(solution => {
    const hasFreightMode = result.includes(solution.freightModeCode)
    if (!hasFreightMode) {
      result.push(solution.freightModeCode)
    }
  })
  return result
}

/**
 *
 *
 * @param {IInquirySolution[]} solutions
 * @return {*}  {IInquirySolution[]}
 */
export const findFastestAndSlowestSolution = (
  solutions: IInquirySolution[]
): IInquirySolution[] => {
  const updatedSolutions = solutions.sort((x, y) => {
    if (x.transitTimePrognosedMin && y.transitTimePrognosedMin) {
      return x.transitTimePrognosedMin - y.transitTimePrognosedMin
    } else {
      return 0
    }
  })
  if (!updatedSolutions.length) {
    return solutions
  }
  const FIRST_ELEMENT = 0
  const LAST_ELEMENT = updatedSolutions.length - 1
  if (updatedSolutions.length === 1) {
    updatedSolutions[FIRST_ELEMENT].fastest = true
    updatedSolutions[FIRST_ELEMENT].slowest = false
  } else {
    updatedSolutions[FIRST_ELEMENT].fastest = true
    updatedSolutions[FIRST_ELEMENT].slowest = false
    updatedSolutions[LAST_ELEMENT].slowest = true
    updatedSolutions[LAST_ELEMENT].fastest = false
  }

  return updatedSolutions
}

/**
 *
 *
 * @param {IInquirySolution[]} solutions
 * @return {*}  {IInquirySolution[]}
 */
export const setFastestAndSlowestSolution = (
  solutions: IInquirySolution[]
): IInquirySolution[] => {
  /**
   * First, sort the solution with respect to null solutions at the end of array
   */
  const nullableSolutions: IInquirySolution[] = []
  let nonNullableSolutions: IInquirySolution[] = []
  solutions.forEach(solution => {
    const isNullable =
      isNull(solution.transitTimePrognosedMin) &&
      isNull(solution.transitTimePrognosedMdn)
    if (isNullable) {
      nullableSolutions.push(solution)
    } else {
      nonNullableSolutions.push(solution)
    }
  })

  /**
   * From the valid solutions, now find the fastest and slowest and set the fastest and
   * slowest bit to true or false
   */
  nonNullableSolutions = findFastestAndSlowestSolution(nonNullableSolutions)
  return [
    ...nonNullableSolutions,
    ...nullableSolutions.map(solution => ({
      ...solution,
      transitTimePrognosedMin: 0,
      transitTimePrognosedMdn: 0
    }))
  ]
}

/**
 *
 *
 * @param {IInquirySolution[]} solutions
 * @return {*}
 */
export const setLowestPriceSolution = (solutions: IInquirySolution[]) => {
  /**
   * Filter out those who have sell price zero after that find the lowest one
   */
  const solutionWithZeroSellTotal = solutions.filter(x => x.sellTotal === 0)

  let solutionWithOutZeroSellTotal = solutions.filter(x => x.sellTotal)

  if (solutionWithOutZeroSellTotal && solutionWithOutZeroSellTotal.length > 0) {
    solutionWithOutZeroSellTotal.sort(function(a, b) {
      return b.sellTotal - a.sellTotal
    })
    solutionWithOutZeroSellTotal[
      solutionWithOutZeroSellTotal.length - 1
    ].isLowestInPrice = true
  }

  solutionWithOutZeroSellTotal = [
    ...solutionWithOutZeroSellTotal,
    ...solutionWithZeroSellTotal
  ]
  return solutionWithOutZeroSellTotal
}

/**
 *
 * @param inquiry the inquiry
 * @param gateways the response of gateways
 */
export const parseSolutionRoutes = (
  originCityName: string,
  destinationCityName: string,
  gateways: IGatewayResponse[]
): string => {
  if (gateways.length > 0) {
    return `${originCityName} > ${gateways
      .map(g => g.gatewayCode)
      .join(' > ')} > ${destinationCityName}`
  } else {
    return `${originCityName} > ${destinationCityName}`
  }
}

/**
 *
 * @param extraFieldTypes
 * @description calculate the default extra field type
 */
export const getDefaultExtraFieldType = (
  extraFieldTypes: IExtraFieldType[]
): IExtraFieldType => {
  const idx = extraFieldTypes.findIndex(
    (eft: IExtraFieldType) => eft.name === 'Customer reference'
  )
  return extraFieldTypes[idx]
}

/**
 *
 * @param addresses array of addresses
 * @param point point like Origin | Destination | Incoterm
 */
export const getAddress = (
  addresses: IAddress[],
  point: AddressPoint
): IAddress => {
  return (
    (addresses.find(address => address.point === point) as IAddress) || null
  )
}

/**
 * filter all the cargoTypes and get a selected list for commodity options.
 * @param cargoTypes array of cargo types
 * @param freightModeId quote freight mode id
 */
export const getCommodityOptions = (
  cargoTypes: ICargoType[],
  freightModeId: string
): ISelectOption[] => {
  return cargoTypes
    .filter(ct => ct.freightModeId === freightModeId)
    .map(ct => ({
      text: ct.name,
      value: ct.id
    }))
}

/**
 *
 *
 * @param {{
 *   width: number
 *   length: number
 * }}
 * @return {*}  {ItemTypes}
 */
function getPalletType({
  width,
  length
}: {
  width: number
  length: number
}): ItemTypes {
  if (length === 120 && width === 80) {
    return ItemTypes.EUR_PALLET
  } else if (length === 120 && width === 100) {
    return ItemTypes.EUR_2_PALLET
  }
  return ItemTypes.OTHER_PALLET
}

/**
 *
 *
 * @param {{
 *   width: number
 *   length: number
 *   height: number
 * }}
 * @return {*}  {ItemTypes}
 */
function getOtherType({
  width,
  length,
  height,
  envelopesValidations
}: {
  width: number
  length: number
  height: number
  envelopesValidations: IEnvelopeValidationRules
}): ItemTypes {
  if (
    width === envelopesValidations.widthdefault &&
    height === envelopesValidations.heightdefault &&
    length === envelopesValidations.lengthdefault
  )
    return ItemTypes.ENVELOPE
  else return ItemTypes.BOX_CRATES
}

/**
 *
 * @param {IInquiryItem[]} items
 * @param {IEnvelopeValidationRules} envelopesValidations
 * @return {*}  {InquiryItem[]}
 */
export const getItemUnitsFromInquiry = (
  items: IInquiryItem[],
  envelopesValidations: IEnvelopeValidationRules
): InquiryItem[] => {
  return items.length > 0
    ? items.map(item => {
        return {
          id: uniqueId(),
          itemType: item.isPallet
            ? getPalletType({
                width: item.width,
                length: item.length
              })
            : getOtherType({
                width: item.width,
                length: item.length,
                height: item.height,
                envelopesValidations: envelopesValidations
              }),
          data: {
            id: item.id,
            quantity: item.quantity,
            dimensions: {
              length: { value: item.length, isValid: true, validationMsg: '' },
              width: { value: item.width, isValid: true, validationMsg: '' },
              height: { value: item.height, isValid: true, validationMsg: '' },
              unit: item.lengthUnitName
            },
            weight: {
              value: item.weight,
              unit: item.weightUnitName,
              totalWeight: getItemTotalWeight(item.weight, item.weightUnitName)
            },
            totalVolume: getTotalVolumeByDimensions({
              length: item.length,
              width: item.width,
              height: item.height,
              unit: item.lengthUnitName
            }),
            showSumModal: true,
            isStackable: item.isStackable,
            isPallet: false
          }
        }
      })
    : []
}

/**
 *
 *
 * @param {IShipmentItem[]} items
 * @return {*}  {IUnit[]}
 */

export const getItemUnitsFromShipment = (
  items: IShipmentItem[]
): InquiryItem[] => {
  return items.length > 0
    ? items.map(item => {
        return {
          id: uniqueId(),
          itemType: item.isPallet
            ? getPalletType({
                width: item.widthOriginal,
                length: item.lengthOriginal
              })
            : ItemTypes.BOX_CRATES,
          data: {
            id: item.id,
            quantity: item.quantity,
            dimensions: {
              length: {
                value: item.lengthOriginal,
                isValid: true,
                validationMsg: ''
              },
              width: {
                value: item.widthOriginal,
                isValid: true,
                validationMsg: ''
              },
              height: {
                value: item.heightOriginal,
                isValid: true,
                validationMsg: ''
              },
              unit: item.originalLengthUnitName
            },
            weight: {
              value: item.weightOriginal,
              unit: item.weightUnitName,
              totalWeight: item.weightOriginal
            },
            totalVolume: getTotalVolumeByDimensions({
              length: item.lengthOriginal,
              width: item.widthOriginal,
              height: item.heightOriginal,
              unit: item.originalLengthUnitName
            }),
            showSumModal: true,
            isStackable: item.isStackable,
            isPallet: false
          }
        }
      })
    : []
}

/**
 *
 *
 * @param {IContainer[]} containers
 * @return {*}  {IContainerUnit[]}
 */
export const getContainerUnitsFromInquiry = (
  containers: IContainer[]
): IContainerUnit[] => {
  return containers
    .filter(container => container.quantity > 0)
    .map(filteredContainer => ({
      id: filteredContainer.id,
      quantity: filteredContainer.quantity,
      type:
        filteredContainer.type === ContainerTypes.Other
          ? ''
          : filteredContainer.type,
      isOverweight: filteredContainer.isOverweight,
      containerEdges: ''
    }))
}

/**
 *
 *
 * @export
 * @interface SolutionsData
 */
export interface ISolutionsData {
  solutions: IInquirySolution[]
  fastestIndex: number
  slowestIndex: number
  freightModes: string[]
}
/**
 *
 *
 * @param {*} solutions
 * @return {*}
 */
export const getSolutionsFreightModes = solutions => {
  return findAllFreightModes(solutions)
}

/**
 *
 *
 * @param {IInquirySolution[]} solutions
 * @param {string} originCity
 * @param {string} destinationCity
 * @return {*}  {Promise<IInquirySolution[]>}
 */
export const attachRoutesToSolutions = async (
  solutions: IInquirySolution[],
  originCity: string,
  destinationCity: string
): Promise<IInquirySolution[]> => {
  const quoteRouteService = new QuoteRouteService()
  const routePromises = solutions.map(async (solution: IInquirySolution) => {
    const { data } = await quoteRouteService.getRouteGateways(solution.id)
    const gateways = data as IGatewayResponse[]
    solution.solutionRoute = parseSolutionRoutes(
      originCity,
      destinationCity,
      gateways
    )
    return solution
  })
  const result = await Promise.all(routePromises)
  return result
}

/**
 *
 *
 * @param {IInquirySolution[]} solutions
 * @return {*}  {IInquirySolution[]}
 */
export const setFreightPremium = (
  solutions: IInquirySolution[]
): IInquirySolution[] => {
  solutions.forEach(solution => {
    if (
      solution.freightModeCode === 'AFR' &&
      (solution.freightModeSubtypeName === 'MAC' ||
        solution.freightModeSubtypeName === 'PRM')
    ) {
      solution.isPremium = true
    }
  })
  return solutions
}

/**
 *
 *
 * @param {IInquirySolution[]} solutions
 * @return {*}  {IInquirySolution[]}
 */
export const orderByRecommended = (
  solutions: IInquirySolution[]
): IInquirySolution[] => {
  // sorted it in ascending order
  const premiumSolutions = solutions
    .filter(x => x.isPremium)
    .sort((a, b) => {
      return a.sellTotal - b.sellTotal
    })

  if (premiumSolutions && premiumSolutions.length > 0) {
    return [...premiumSolutions, ...solutions.filter(x => !x.isPremium)]
  } else {
    return solutions
  }
}

/**
 *
 *
 * @param {IInquirySolution[]} solutions
 * @return {*}  {IInquirySolution[]}
 */
export const getPremiumSortedSolutions = (
  solutions: IInquirySolution[]
): IInquirySolution[] => {
  // sorted it in ascending order
  return solutions
    .filter(x => x.isPremium)
    .sort((a, b) => {
      return a.sellTotal - b.sellTotal
    })
}
