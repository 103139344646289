import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators'
import store from '@/store'
import { IParams, IShipment } from '@/models'
import { ShipmentService } from '@/services/shipment/shipment'

@Module({
  dynamic: true,
  store,
  name: 'nav-waiting-for-approval-shipment',
  namespaced: true
})
class NavWaitingForApprovalShipment extends VuexModule {
  private shipmentService = new ShipmentService()
  private waitingForApprovalCount: number = 0
  private isTrigger: boolean = false

  @Mutation
  SET_WAITING_FOR_APPROVAL_TOTAL_COUNT(count: number) {
    this.waitingForApprovalCount = Number(count)
  }

  @Mutation
  public SET_IS_TRIGGER(flag: boolean) {
    this.isTrigger = flag
  }

  @Mutation
  public Reset() {
    this.waitingForApprovalCount = 0
    this.isTrigger = false
  }

  @Action({ rawError: true })
  public async getAllWaitingForApprovalShipment(params: IParams) {
    if (!this.isTrigger) {
      const response = await this.shipmentService.getAllShipment<
        Array<IShipment>
      >(params)
      if (response.status) {
        this.SET_IS_TRIGGER(true)
        this.SET_WAITING_FOR_APPROVAL_TOTAL_COUNT(
          response.totalCount ? response.totalCount : 0
        )
      }
    }
  }
}

export const NavWaitingForApprovalShipmentModule = getModule(
  NavWaitingForApprovalShipment
)
