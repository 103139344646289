import { RouteConfig } from 'vue-router/types/router'

export const loginRegistrationRoute: Array<RouteConfig> = [
  {
    path: 'login',
    name: 'Login',
    component: () =>
      import(
        '../../../views/landing-page/loginAndRegistration/LoginMainLayout.vue'
      )
  },
  {
    path: 'sign-up',
    name: 'Sign Up',
    component: () =>
      import(
        '../../../views/landing-page/loginAndRegistration/registration/index.vue'
      )
  }
]
