import Vue from 'vue'
import '@/extension/string.extension'
import * as VueGoogleMaps from 'vue2-google-maps'

import App from '@/App.vue'
// import '@/registerServiceWorker'
import router from '@/router'
import '@/guards/auth'
import store from '@/store'
import '@/assets/scss/custom.scss'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import * as customDirectives from '@/directive/index'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate'
import '@/validations'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import { MazPhoneNumberInput } from 'maz-ui'
import LoadScript from 'vue-plugin-load-script/index'
import VueSignature from 'vue-signature-pad'
import vSelect from 'vue-select'
import { ToggleButton } from 'vue-js-toggle-button'

// Install VeeValidate rules and localization
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  })
}
//Install custom directive
for (const [, value] of Object.entries(customDirectives)) {
  Vue.directive(value.id, value.defination)
}

localize('en', en)
// Add country iso code input
Vue.use(MazPhoneNumberInput)

Vue.component('v-select', vSelect)
Vue.component('ToggleButton', ToggleButton)

//Adding Vee Validate globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)

Vue.use(VueSignature)

Vue.config.productionTip = false

// filters
import '@/filters'

import i18n from './i18n'

// initialize event bus as a separate vue instance
export const bus = new Vue()

// Google maps configurations
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    language: 'en'
  },
  installComponents: true
})

Vue.use(LoadScript)

// Above the createApp() line
import { defineCustomElements } from '@ionic/pwa-elements/loader'

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
