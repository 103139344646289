<template>
  <span>
    {{ description }}
  </span>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IFreightModeDescription } from '@/models'

const freightModeMapping = {
  AFR: {
    ECO: {
      name: 'Air economy'
    },
    EXP: {
      name: 'Air direct'
    },
    PRM: {
      name: 'MyDello Air Premium'
    },
    MAC: {
      name: 'MyDello Air Consolidated'
    }
  },
  OFR: {
    LCL: {
      name: 'Ocean less than container load'
    },
    '20DC': {
      name: 'Ocean 20DC'
    },
    '40DC': {
      name: 'Ocean 40DC'
    },
    '40HC': {
      name: 'Ocean 40HC'
    },
    Other: {
      name: ''
    }
  },
  RW: {
    LCL: {
      name: 'Rail less than container load'
    },
    '20DC': {
      name: 'Rail 20DC'
    },
    '40DC': {
      name: 'Rail 40DC'
    },
    '40HC': {
      name: 'Rail 40HC'
    },
    Other: {
      name: ''
    }
  },
  RFR: {
    LTL: {
      name: 'Road economy'
    },
    FTL: {
      name: 'Road full truck'
    }
  },
  COR: {
    CON: {
      name: 'Courier economy continental'
    },
    WOR: {
      name: 'Courier economy world'
    },
    EXP: {
      name: 'Courier direct'
    }
  },
  ICE: {
    ECO: {
      name: ''
    }
  },
  LOG: {
    ECO: {
      name: ''
    }
  },
  HUB: {
    NON: {
      name: ''
    },
    BON: {
      name: ''
    }
  },
  CLO: {
    ECO: {
      name: ''
    }
  }
}

@Component
export default class FreightModeDescription extends Vue {
  @Prop() private data!: IFreightModeDescription

  get description() {
    if (this.data) {
      const freightMode = freightModeMapping[this.data.freightModeCode]
      if (freightMode) {
        const freightModeSubType = freightMode[this.data.freightModeSubTypeName]
        if (freightModeSubType && freightModeSubType.name)
          return freightModeSubType.name
        else return this.defaultFreightModeDescription(this.data)
      } else {
        return this.defaultFreightModeDescription(this.data)
      }
    }
    return ''
  }

  defaultFreightModeDescription(
    freightModeDescription: IFreightModeDescription
  ) {
    return `${freightModeDescription.freightModeCode} ${freightModeDescription.freightModeSubTypeName}`
  }
}
</script>
