import { extend } from 'vee-validate'

export const registartion = extend('password', {
  message: () =>
    'Passwords must be at least 6 characters, must have at least one lowercase (a-z) and one uppercase (A-Z) letter and at least one digit (0-9).',
  validate: (value: string) => {
    // prettier-ignore
    /* eslint-disable */
    var isValueMatch = value.match(
      /^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*\d)[*\p{L}\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]{6,}$/gu
    )
    if (isValueMatch && isValueMatch.length > 0) {
      return true
    } else {
      return false
    }
  },
  params: ['data']
})
