<template>
  <h1 class="pageTitle font-light text-gray-darker">Information</h1>
</template>

<script>
export default {
  name: 'InformationHeader'
}
</script>

<style></style>
