<template>
  <!-- <div>Aside</div> -->
  <div class="sideMenu d-flex h-100">
    <aside>
      <div class="sidebar left" :class="{ fliph: active }">
        <ul class="list-sidebar">
          <!-- Main logo -->
          <b-nav-item
            id="home-btn"
            to="/"
            class="top-logo"
            :active="mainNavActive"
            @click="onMainLogoClick()"
          >
            <span class="logo-icon">
              <i>
                <img src="../../assets/images/menu-logo.svg" />
              </i>
            </span>
            <span class="nav-label"></span>
            <span class="side-logo">
              <i>
                <img src="../../assets/images/logo-white.svg" />
              </i>
            </span>
            <b-tooltip
              v-if="active"
              target="home-btn"
              title="Home"
              placement="right"
              triggers="hover"
            />
          </b-nav-item>

          <b-nav-item
            v-for="nav in navs"
            @click="onClick(nav.name, nav.link)"
            :key="nav.name"
            :active="nav.active"
            :id="nav.tooltipText"
            v-show="
              nav.name == 'Waiting for approval' ? showWaitingForApproval : true
            "
            :class="{
              badge_pointer:
                nav.name == `Waiting for approval` &&
                totalCountWaitingForApproval
            }"
          >
            <i>
              <img :src="getImageUrl(nav.imageName)" />
              <b-badge
                class="badge_red"
                v-if="
                  nav.name == 'Waiting for approval' &&
                    totalCountWaitingForApproval
                "
                variant="danger"
                >{{ totalCountWaitingForApproval }}
              </b-badge>
            </i>
            <span class="nav-label" :class="nav.class">{{ nav.name }} </span>

            <b-tooltip
              v-if="active"
              :target="nav.tooltipText"
              :title="nav.tooltipText"
              placement="right"
              triggers="hover"
            ></b-tooltip>
          </b-nav-item>

          <li class="nav-item logout" role="button" @click="logout()">
            <span class="nav-label">
              <figure class="user_thumb">
                <svg
                  data-v-1db47772=""
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  focusable="false"
                  role="img"
                  aria-label="person fill"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi-person-fill mr-2 b-icon bi"
                >
                  <g data-v-1db47772="">
                    <path
                      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    ></path>
                  </g>
                </svg>
              </figure>
              <span class="userName">{{
                user && user.contact && user.contact.fullName
                  ? user.contact.fullName
                  : ''
              }}</span>
              <b-nav-item>
                <span class="nav-label">Log out</span>
              </b-nav-item>
            </span>
          </li>
        </ul>
      </div>
    </aside>
    <span
      class="sidemenu-btn"
      @click="onClickSideMenuBtn"
      :aria-pressed="active ? 'true' : 'false'"
    >
      <div class="h5 mb-2" v-if="active">
        <b-icon-chevron-right></b-icon-chevron-right>
      </div>
      <div class="h5 mb-2" v-if="!active">
        <b-icon-chevron-left></b-icon-chevron-left>
      </div>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { getImgUrl } from '@/utils/image-path'
import { AuthModule } from '@/store/modules/auth'
import { showPopup } from '@/utils/si-chat-helper'
import { NavigationFailureType } from '@/utils/constants'
import { bus } from '@/main'
import { MobileDetectingMixin } from '@/mixins'

@Component
export default class Nav extends Mixins(MobileDetectingMixin) {
  mainNavActive = true
  active = true
  get user() {
    return AuthModule.user
  }

  navs = [
    {
      name: 'New Inquiry',
      imageName: 'plus_green.svg',
      active: false,
      link: '/new-inquiry',
      class: 'theme-color',
      totalCount: 0,
      show: true,
      tooltipText: 'New Inquiry'
    },
    {
      name: 'Waiting for approval',
      imageName: 'bell-solid.svg',
      active: false,
      link: '/waiting-for-approval',
      class: '',
      totalCount: 0,
      show: false,
      tooltipText: 'Waiting for approval'
    },
    {
      name: 'My shipments',
      imageName: 'ship.svg',
      active: false,
      link: '/my-shipment',
      class: '',
      totalCount: 0,
      show: true,
      tooltipText: 'My Shipments'
    },
    {
      name: 'My inquiries',
      imageName: 'my_inquiries.svg',
      active: false,
      link: '/inquiry',
      class: '',
      totalCount: 0,
      show: true,
      tooltipText: 'My inquiries'
    },
    {
      name: 'My Invoices',
      imageName: 'coin.svg',
      active: false,
      link: '/invoices',
      class: '',
      totalCount: 0,
      show: true,
      tooltipText: 'My invoices'
    },
    {
      name: 'My reports',
      imageName: 'file-export-solid.svg',
      active: false,
      link: '/my-reports',
      class: '',
      totalCount: 0,
      show: true,
      tooltipText: 'My Reports'
    },
    {
      name: 'Chat',
      imageName: 'chat-1.svg',
      active: false,
      link: '',
      class: '',
      totalCount: 0,
      show: true,
      tooltipText: 'Chat'
    },
    {
      name: 'Settings',
      imageName: 'setting.svg',
      active: false,
      link: '/settings/my-profile',
      class: '',
      totalCount: 0,
      show: true,
      tooltipText: 'Settings'
    },
    {
      name: 'Information',
      imageName: 'help.svg',
      active: false,
      link: '/information/faq',
      class: '',
      totalCount: 0,
      show: true,
      tooltipText: 'Information'
    }
  ]

  get showWaitingForApproval() {
    return this.$store.state['vendor'].showWaitingForApprovalInNavBar
  }

  get totalCountWaitingForApproval() {
    return Number(
      this.$store.state['nav-waiting-for-approval-shipment']
        .waitingForApprovalCount
    )
  }

  onClick(name: string | undefined, link: string | undefined): void {
    //deactive main logo route
    this.mainNavActive = false
    this.navs.forEach(nav => {
      if (nav.name === name) {
        nav.active = true
      } else {
        nav.active = false
      }
    })
    if (name === 'Chat') {
      showPopup()
    }
    // why we are using router.push because we have to catch routing exception
    // specially those are duplicated
    this.$router.push({ path: link }).catch(err => {
      if (
        err.type === NavigationFailureType.Duplicated &&
        name === 'New Inquiry'
      ) {
        bus.$emit('inquiryDuplicateNavigation', true)
      }
    })
    this.$emit('onRouteChange', true)
  }

  onClickSideMenuBtn() {
    this.active = !this.active
    const mainPage = document.querySelector('.mainPage')
    if (!this.active) mainPage?.classList.add('menuOpen')
    else mainPage?.classList.remove('menuOpen')
  }

  onMainLogoClick() {
    this.mainNavActive = true
    this.navs.map(x => (x.active = false))
  }

  getImageUrl(imageName: string) {
    return getImgUrl(imageName)
  }

  created() {
    const path = this.$route.fullPath
    if (path === '/') {
      this.onMainLogoClick()
    } else {
      const navPath = this.navs.find(x => x.link === path)
      this.onClick(navPath?.name, navPath?.link)
    }
  }

  logout() {
    AuthModule.Logout()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.badge_pointer {
  a {
    position: relative;

    .badge_red {
      position: absolute;
      width: 18px;
      height: 13px;
      background: #e43f3f;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 17px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 21px;
      left: 34px;
    }
  }
}
</style>
