import API from '@/http/http-common'
import { ServiceResponse } from '../service-response'
import { IParams } from '@/models'
import { customizeParams } from '@/utils/service-helper'

export class VendorService {
  async getAllVendors<T>(
    params = { pageSize: 1000 } as IParams
  ): ServiceResponse<T> {
    try {
      const queryParams = customizeParams(params)
      const res = await API.externalInstance.get('/vendor', queryParams)
      return {
        status: true,
        data: res.data,
        totalCount: res.headers['x-total-count']
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async getVendorCompanyContacts<T>(companyId): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.get(
        `/vendor-company-contact/${companyId}`
      )
      return {
        status: true,
        data: res.data,
        totalCount: res.headers['x-total-count']
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async updateVendor<T>(data: T): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.put('/vendor', data)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async patchVendorCompanyContact<T>(
    shipmentId: string,
    vendorCompanyContactId: string
  ): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.patch(
        `shipment/${shipmentId}/vendorCompanyContactId`,
        vendorCompanyContactId
      )
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }

  async deleteVendor<T>(id: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.delete(`/vendor/${id}`)
      return { status: true, data: res.data }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}

export default new VendorService()
