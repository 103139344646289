import { ISelectOption } from '@/models'

export enum ShipmentStatus {
  New = 'New',
  WaitingForPu = 'Waiting For PU',
  WaitingForDeparture = 'Waiting For Departure',
  InTransit = 'In Transit',
  WaitingForDelivery = 'Waiting For Delivery',
  InvoiceIssued = 'Invoice Issued',
  Invoiced = 'Invoiced',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
  WaitingForConfirmation = 'Booked',
  WaitingForExport = 'Waiting For Export',
  WaitingForImport = 'Waiting For Import'
}

export enum AddressPoint {
  Origin = 'Origin',
  TransshipmentPoint = 'Transshipment Point',
  Destination = 'Destination',
  Incoterm = 'Incoterm'
}

export enum AddressLabels {
  Warehouse = 'Warehouse',
  Registration = 'Registration',
  Origin = 'Origin',
  TransshipmentPoint = 'Transshipment Point',
  Destination = 'Destination',
  Incoterm = 'Incoterm'
}

export enum ExtraField {
  CustomerReference = 'Customer reference',
  ExportDeclaration = 'Export declaration',
  ImportDeclaration = 'Import declaration',
  TransitDeclaration = 'Transit declaration',
  PublicTrackingLink = 'Public tracking link'
}

export enum FreightMode {
  Airplane = 'AFR',
  Courier = 'COR',
  Ocean = 'OFR',
  Railway = 'RW',
  Road = 'RFR',
  ICE = 'ICE',
  LOGISTICS = 'LOG',
  WAREHOUSE = 'HUB',
  LOCAL = 'CLO'
}

export enum IncotermCode {
  Dap = 'DAP',
  Fob = 'FOB',
  Des = 'DES',
  Deq = 'DEQ',
  Ddu = 'DDU',
  Ddp = 'DDP',
  Fca = 'FCA',
  Cfr = 'CFR',
  Cip = 'CIP',
  Cif = 'CIF',
  Cpt = 'CPT',
  Daf = 'DAF',
  Exw = 'EXW',
  Fas = 'FAS'
}

export enum PdfTemplateLocaleCategory {
  QuotePdf = 'QuotePdf',
  InvoicePdf = 'InvoicePdf'
}

export enum TrackingRouteLegType {
  PickUp = 'PickUp',
  OriginGw = 'OriginGw',
  MainFreight = 'MainFreight',
  DestinationGw = 'DestinationGw',
  Delivery = 'Delivery'
}

export enum TrackingRouteLegStatus {
  Auto = 'Auto',
  SemiAuto = 'SemiAuto',
  Manual = 'Manual'
}

export enum RouteType {
  Predefined = 'Predefined',
  PricelistDirect = 'PricelistDirect',
  PricelistTsp = 'PricelistTsp',
  Manual = 'Manual',
  PointToPoint = 'PointToPoint'
}

export enum CostInvoiceStatus {
  Draft = 'Draft',
  Added = 'Added',
  Confirmed = 'Confirmed',
  Invoices = 'Invoices'
}

export enum InvoiceStatus {
  Draft = 'Draft',
  Invoiced = 'Invoiced'
}

export enum PaymentStatus {
  Unpaid = 'Unpaid',
  Paid = 'Paid',
  Overdue = 'Overdue',
  Draft = 'Draft'
}

export enum UserStatus {
  Active = 'Active',
  InActive = 'InActive',
  None = 'None'
}

export enum ActiveType {
  Active = 'Active',
  InActive = 'InActive'
}

export enum ContractStatus {
  New = 'New',
  WaitingForManagerApproval = 'WaitingForManagerApproval',
  Approved = 'Approved',
  Expired = 'Expired',
  Cancelled = 'Cancelled'
}

export enum BonusSystemStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum ContainerTypes {
  DC20 = 'DC20',
  DC40 = 'DC40',
  HC40 = 'HC40',
  Other = 'Other'
}

export enum Tabs {
  Boxes,
  Pallets,
  Containers,
  Envelope
}

export enum BusinessState {
  New,
  Renewed,
  Old,
  Lost
}

export enum BusinessType {
  Local,
  Outside
}

export enum ServiceMode {
  Regular,
  Express
}

export enum TransitUnit {
  WorkingDays,
  CalendarDays
}

export enum TransitTerm {
  FromPickup,
  SubjectToSpace
}

export enum RouteDirectionType {
  ThirdParty,
  Import,
  Export
}

export enum ShipmentTrackingStatus {
  WaitingForConfirmation,
  WaitingForPu,
  WaitingForDeparture,
  InTransit,
  WaitingForDelivery,
  Delivered
}

export enum TrackingStatus {
  WaitingForConfirmation = 'WaitingForConfirmation',
  WaitingForPu = 'WaitingForPu',
  WaitingForDeparture = 'WaitingForDeparture',
  InTransit = 'InTransit',
  WaitingForDelivery = 'WaitingForDelivery',
  Delivered = 'Delivered',
  WaitingForImport = 'WaitingForImport',
  WaitingForExport = 'WaitingForExport'
}

export enum ContactType {
  Shipper = 'Shipper',
  Consignee = 'Consignee'
}

export enum TermStatus {
  Accepted = 'accepted',
  NotAccepted = 'not_accepted'
}

export const initialContact = {
  companyName: '',
  companyContactName: '',
  phone: '',
  email: '',
  isTailLiftNeeded: false,
  loadingNotes: ''
}

export const DEFAULT_REFERENCE_NUMBER = 'Customer reference'
export const ATTACHMENT_MODAL_ID = 'attachment-modal'
export enum InquiryStatus {
  New = 'New',
  WaitingForFeedback = 'WaitingForFeedback',
  WaitingForDecision = 'WaitingForDecision',
  Accepted = 'Accepted',
  AcceptedNoShipment = 'AcceptedNoShipment',
  Rejected = 'Rejected',
  Expired = 'Expired'
}

export enum InquiryStatusTab {
  New = 'New',
  WaitingForFeedback = 'Waiting For Feedback',
  WaitingForDecision = 'Waiting For Decision',
  Accepted = 'Accepted',
  AcceptedNoShipment = 'Accepted No Shipment',
  Rejected = 'Rejected',
  Expired = 'Expired'
}

export enum UserTypeEnum {
  Company,
  PrivatePerson
}

export enum ShipmentTab {
  WaitingForApproval,
  Active,
  All
}

export enum InquiryTab {
  Active,
  All
}

export enum InquiryTabStatus {
  Active = 'Active',
  All = 'All'
}

export enum InvoiceTab {
  All,
  Paid,
  WaitingForPayment,
  Overdue
}

export enum InvoiceTabStatus {
  All = 'All',
  Paid = 'Paid',
  WaitingForPayment = 'Waiting for payment',
  Overdue = 'Overdue'
}

export enum InquiryRouteType {
  Create = 'create',
  Update = 'update',
  Shipment = 'shipment'
}

export const PALLET_OPTIONS = [
  { label: '120 x 80 cm (EUR)', value: '120 x 80 cm (EUR)' },
  { label: '120 x 100 cm (EUR 2)', value: '120 x 100 cm (EUR 2)' },
  { label: '80 x 60 cm (EUR 6)', value: '80 x 60 cm (EUR 6)' },
  { label: 'Other', value: 'Other' }
]

export const defaultSelectedPallet = '120 x 80 cm (EUR)'

export enum IRememberMyUserName {
  NotRemember,
  Remember
}

export enum ItemTypes {
  NOT_SELECTED = 'NOT_SELECTED',
  FULL_CONTAINER = 'FULL_CONTAINER',
  BOX_CRATES = 'BOX_CRATES',
  EUR_PALLET = 'EUR_PALLET',
  EUR_2_PALLET = 'EUR_2_PALLET',
  OTHER_PALLET = 'OTHER_PALLET',
  ENVELOPE = 'ENVELOPE'
}

export enum IDocumentTypeIDs {
  ImportDeclaration = 'd4f2795a-8b02-44c7-8da6-4c37a82ab8dd',
  ExportDeclaration = 'f941942e-e8f6-4630-a67b-5cb092f3c59c',
  TransitDeclaration = '36629ef2-e408-4c1d-a7b2-87d98fd96efc',
  CargoInvoice = 'd96abb5b-80f2-4c0c-8349-0f326856ecf0',
  PackingList = 'df901b41-8281-4659-8c90-12de7710670b',
  DGD = '1defd276-f074-407a-bada-6e0def417512',
  Other = 'e07ae18a-4163-43d7-80f2-d02c4990b241'
}

export interface IStackable {
  srcEvent: Event
  value: boolean
  tag: unknown
}

export enum Regions {
  Local = 'Local',
  Dev = 'Dev',
  PreLive = 'Pre-Live',
  PreLive2 = 'Pre-Live2',
  Estonia = 'Estonia',
  Latvia = 'Latvia',
  Lithuania = 'Lithuania',
  OtherEU = 'Other EU',
  Sweden = 'Sweden'
}

export enum IRegistrationType {
  PrivatePerson = 'Private person',
  Company = 'Company'
}

export enum PaymentStatusForInvoices {
  Draft = 'Draft',
  Unpaid = 'Unpaid',
  Paid = 'Paid',
  Overdue = 'Overdue',
  Cancelled = 'Cancelled',
  Unsettled = 'Unsettled',
  unknown = 'Unknown'
}

export const extraFieldTypeIDs = {
  'pre-live': [
    'd73c2c86-447b-4c6d-afde-540cc978d8e6',
    'f330d2d6-cf30-4f0e-a3a2-49095237a6fe',
    '8a98da90-88c2-4ce5-96ac-827d60045fb4',
    'e66034da-c37c-49f3-9003-177fce6f3326',
    '373de3db-93ff-4f54-a3d3-9d6c2228c847'
  ],
  'pre-live2': [
    'd73c2c86-447b-4c6d-afde-540cc978d8e6',
    'f330d2d6-cf30-4f0e-a3a2-49095237a6fe',
    '8a98da90-88c2-4ce5-96ac-827d60045fb4',
    'e66034da-c37c-49f3-9003-177fce6f3326',
    '373de3db-93ff-4f54-a3d3-9d6c2228c847'
  ],
  estonia: [
    '162c650f-5cae-49a9-997d-a1efa178c4a7',
    'ccbd4f7b-d82a-421d-a73c-fa3879eb5ef6',
    'db8530e2-b0d1-4a80-a25d-347ac122275c',
    '373de3db-93ff-4f54-a3d3-9d6c2228c847'
  ],
  latvia: [
    'cd16629f-01ee-41b8-b180-faaabede82b0',
    'c93f3aab-aa25-4e43-ba70-d168b1f37e68',
    'bd33f593-28fc-4a2b-a25d-b329ec4687e4',
    '373de3db-93ff-4f54-a3d3-9d6c2228c847'
  ],
  lithuania: [
    '3baf8e8b-5769-4139-9fd5-dc2f4309e3e7',
    '8a23b80d-7ddc-4661-bebf-19eaba1f0f28',
    'fae70073-90c6-479e-88d4-cc48d8b91fa6',
    '373de3db-93ff-4f54-a3d3-9d6c2228c847'
  ],
  othereu: [
    '162c650f-5cae-49a9-997d-a1efa178c4a7',
    'ccbd4f7b-d82a-421d-a73c-fa3879eb5ef6',
    'db8530e2-b0d1-4a80-a25d-347ac122275c',
    '373de3db-93ff-4f54-a3d3-9d6c2228c847'
  ],
  sweden: [
    '162c650f-5cae-49a9-997d-a1efa178c4a7',
    'ccbd4f7b-d82a-421d-a73c-fa3879eb5ef6',
    'db8530e2-b0d1-4a80-a25d-347ac122275c',
    '373de3db-93ff-4f54-a3d3-9d6c2228c847'
  ]
}

export const itemTypeOptions = [
  {
    value: ItemTypes.NOT_SELECTED,
    text: 'Select'
  },
  {
    value: ItemTypes.FULL_CONTAINER,
    text: 'Full Container'
  },
  {
    value: ItemTypes.BOX_CRATES,
    text: 'Box/Crate'
  },
  {
    value: ItemTypes.EUR_PALLET,
    text: 'EUR Pallet (120x80cm)'
  },
  {
    value: ItemTypes.EUR_2_PALLET,
    text: 'EUR2 pallet (120x100cm)'
  },
  {
    value: ItemTypes.NOT_SELECTED,
    text: 'Select'
  },
  {
    value: ItemTypes.OTHER_PALLET,
    text: 'Other pallet'
  }
]

export enum NavigationFailureType {
  Redirected = 1,
  Aborted = 2,
  Cancelled = 3,
  Duplicated = 4
}

export enum QuoteStatusDescription {
  New = 'New',
  WaitingFeedback = 'Waiting for Feedback',
  WaitingForDecision = 'Waiting for Decision',
  Rejected = 'Rejected',
  AcceptedNoShipment = 'Accepted - No Shipment',
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Replaced = 'Replaced',
  OtherAccepted = 'Other Accepted',
  OtherRejected = 'Other Rejected',
  Expired = 'Expired'
}

export enum QuoteStatus {
  New = 'New',
  WaitingFeedback = 'WaitingFeedback',
  WaitingForDecision = 'WaitingForDecision',
  Rejected = 'Rejected',
  AcceptedNoShipment = 'AcceptedNoShipment',
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Replaced = 'Replaced',
  OtherAccepted = 'OtherAccepted',
  OtherRejected = 'OtherRejected',
  Expired = 'Expired'
}

export enum APIURL {
  PreLive = 'https://prelive-api.mydello.com',
  Dev = 'https://dev-api.mydello.com',
  PreLive2 = 'https://prelive2-api.mydello.com',
  Estonia = 'https://ee.api.internal.mydello.com',
  Latvia = 'https://lv.api.internal.mydello.com',
  Lithuania = 'https://lt.api.internal.mydello.com'
}

export const enviornments: Array<ISelectOption> = [
  {
    text: Regions.Local,
    value: APIURL.PreLive
  },
  {
    text: Regions.Dev,
    value: APIURL.Dev
  },
  {
    text: Regions.PreLive,
    value: APIURL.PreLive
  },
  {
    text: Regions.PreLive2,
    value: APIURL.PreLive2
  },
  {
    text: Regions.Estonia,
    value: APIURL.Estonia
  },
  {
    text: Regions.Latvia,
    value: APIURL.Latvia
  },
  {
    text: Regions.Lithuania,
    value: APIURL.Lithuania
  },
  {
    text: Regions.OtherEU,
    value: APIURL.Estonia
  }
]

export enum InquirySortOptionsValue {
  FastestFirst = 'transitTimePrognosedMin-asc',
  LowestFirst = 'sellTotal-asc',
  RecommendedFirst = 'recommended'
}

export enum RouteDirectionTypeEnum {
  ThirdParty,
  Import,
  Export,
  Domestic
}

export enum AddressBookEntryTypeEnum {
  Manual = 'Manual',
  Generated = 'Generated'
}

export enum INotificationOptionsEnum {
  IsSubscribedToTrackingOnlyDelay = 'isSubscribedToTrackingOnlyDelay',
  All = 'all',
  IsSubscribedToTrackingScheduleConfirmed = 'isSubscribedToTrackingScheduleConfirmed',
  IsSubscribedToTrackingPuDone = 'isSubscribedToTrackingPuDone',
  IsSubscribedToTrackingDepartedOrigin = 'isSubscribedToTrackingDepartedOrigin',
  IsSubscribedToTrackingArrivedDestination = 'isSubscribedToTrackingArrivedDestination',
  IsSubscribedToTrackingCustomsDone = 'isSubscribedToTrackingCustomsDone',
  IsSubscribedToTrackingDelivered = 'isSubscribedToTrackingDelivered',
  IsSubscribedToTrackingArrivedTsp = 'isSubscribedToTrackingArrivedTsp'
}

export enum GroupedFreightModeSubTypeNames {
  AirEconomy = 'Air economy',
  AirDirect = 'Air direct',
  OceanLessThanContainerLoad = 'Ocean less than container load',
  OceanFullContainerLoad = 'Ocean full container load',
  RailLessThanContainerLoad = 'Rail less than container load',
  RailFullContainerLoad = 'Rail full container load',
  Road = 'Road',
  CourierEconomy = 'Courier economy',
  CourierDirect = 'Courier direct'
}

export enum FreightModeCodeSubTypeName {
  AFRECO = 'AFR-ECO',
  AFREXP = 'AFR-EXP',
  OFRLCL = 'OFR-LCL',
  OFR20DC = 'OFR-20DC',
  OFR40DC = 'OFR-40DC',
  OFR40HC = 'OFR-40HC',
  OFROTHER = 'OFR-Other',
  RWLCL = 'RW-LCL',
  RW20DC = 'RW-20DC',
  RW40DC = 'RW-40DC',
  RW40HC = 'RW-40HC',
  RWOTHER = 'RW-Other',
  RFRLTL = 'RFR-LTL',
  RFRFTL = 'RFR-FTL',
  COREXP = 'COR-EXP',
  CORCON = 'COR-CON',
  CORWOR = 'COR-WOR'
}

export enum AccessibilityType {
  Group = 'Group',
  VendorCustomer = 'VendorCustomer'
}

export const RegionCountryCode = {
  [Regions.Local]: {
    value: 'EE'
  },
  [Regions.Dev]: {
    value: 'EE'
  },
  [Regions.PreLive]: {
    value: 'EE'
  },
  [Regions.PreLive2]: {
    value: 'EE'
  },
  [Regions.Estonia]: {
    value: 'EE'
  },
  [Regions.Latvia]: {
    value: 'LV'
  },
  [Regions.Lithuania]: {
    value: 'LT'
  },
  [Regions.OtherEU]: {
    value: 'EE'
  },
  [Regions.Sweden]: {
    value: 'EE'
  }
}

export enum ExemptSameOriginDestinationCountries {
  Sweden = 'Sweden',
  Poland = 'Poland'
}

export enum IPostType {
  GooglePlaces = 'GooglePlaces',
  Manual = 'Manual'
}

export const conversions = [
  // m
  {
    fromUnitName: 'm',
    toUnitName: 'ft',
    conversionRate: 3.28084
  },
  {
    fromUnitName: 'm',
    toUnitName: 'in',
    conversionRate: 39.3701
  },
  {
    fromUnitName: 'm',
    toUnitName: 'cm',
    conversionRate: 100
  },
  // ft
  {
    fromUnitName: 'ft',
    toUnitName: 'm',
    conversionRate: 0.3048
  },
  {
    fromUnitName: 'ft',
    toUnitName: 'in',
    conversionRate: 12
  },
  {
    fromUnitName: 'ft',
    toUnitName: 'cm',
    conversionRate: 30.48
  },
  //in
  {
    fromUnitName: 'in',
    toUnitName: 'm',
    conversionRate: 0.0254
  },
  {
    fromUnitName: 'in',
    toUnitName: 'ft',
    conversionRate: 0.0833333
  },
  {
    fromUnitName: 'in',
    toUnitName: 'cm',
    conversionRate: 2.54
  },
  //cm
  {
    fromUnitName: 'cm',
    toUnitName: 'm',
    conversionRate: 0.01
  },
  {
    fromUnitName: 'cm',
    toUnitName: 'ft',
    conversionRate: 0.0328084
  },
  {
    fromUnitName: 'cm',
    toUnitName: 'in',
    conversionRate: 0.393701
  }
]

export enum CargoReadyToLoadTimePeriodDefaultValue {
  FullDay = 'Full day'
}
