import API from '@/http/http-common'
import { ServiceResponse } from '@/services/service-response'

export class QuoteRouteService {
  /**
   *
   *
   * @param {string} solutionId
   * @return {*}
   * @memberof InquiryService
   */
  getRouteGateways(
    solutionId: string
  ): Promise<{ status: boolean; data: {} } | { status: boolean; data: any }> {
    return API.externalInstance.get(`/quote-route/${solutionId}/gateways`).then(
      res => {
        return { status: true, data: res.data }
      },
      () => {
        return { status: false, data: {} }
      }
    )
  }

  /**
   * activate the quote route
   * @param {string} routeId
   */
  async setQuoteRouteActive<T>(routeId: string): ServiceResponse<T> {
    try {
      const res = await API.externalInstance.post(
        `/quote-route/${routeId}/set-active`
      )
      return {
        status: true,
        data: res.data
      }
    } catch (e) {
      return { status: false, data: {} as T }
    }
  }
}
export default new QuoteRouteService()
