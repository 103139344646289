<template>
  <span
    v-if="name"
    class="icon"
    :class="{
      'is-disabled': disabled
    }"
  >
    <i :class="faClass" />
  </span>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class TrackingIcon extends Vue {
  @Prop({ type: String, required: true }) private name
  @Prop({ type: [Boolean, String] }) private clickable
  @Prop({ type: Boolean, default: false }) private disabled
  @Prop({ type: [Boolean, String] }) private regular

  get faClass() {
    if (!this.name) return []

    const classList: string[] = []
    classList.push(`fa-${this.name}`)
    classList.push(this.regular ? 'far' : 'fas')
    return classList
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.icon {
  color: var(--style-icon-color, inherit);

  &--clickable {
    cursor: pointer;
    color: inherit;
  }

  &.is-danger {
    color: $red;
  }

  &.is-primary {
    color: $yellow;
  }
}
</style>
