import Vue from 'vue'
import moment from 'moment'
import { momentSetting } from '@/utils/global-setting'

Vue.filter('formatDate', function(date) {
  if (!date) return '-'
  const dateObj = new Date(date)
  return moment(dateObj).format('DD.MM.YYYY')
})

Vue.filter('formatDateDDMM', function(date) {
  if (!date) return '-'
  const dateObj = new Date(date)
  return moment(dateObj).format('D. MMM')
})

Vue.filter('formatDateTimeUtc', function(date) {
  if (!date) return '-'
  const dateObj = new Date(date)
  return moment(dateObj).format(momentSetting.dateTime12Hours)
})

Vue.filter('formatDateTimeUtc24Hrs', function(date) {
  if (!date) return '-'
  const dateObj = new Date(date)
  return moment(dateObj).format(momentSetting.dateTime24Hours)
})
