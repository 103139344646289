import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators'
import { cloneDeep, orderBy, uniqBy } from 'lodash'

import {
  IAddress,
  ICargoReadyToLoadTimePeriod,
  IExtraFieldType,
  IIncoterm,
  IInquiry,
  IInquiryContact,
  IInquiryExtraFields,
  IInquiryFilters,
  IInquirySolution,
  IShipmentAttachment
} from '@/models'
import {
  attachRoutesToSolutions,
  filterByFreightModes,
  filterByPrice,
  filterByTransitDays,
  getDefaultExtraFieldType,
  getPremiumSortedSolutions,
  getSolutionsFreightModes,
  orderByRecommended,
  setFastestAndSlowestSolution,
  setFreightPremium,
  setLowestPriceSolution
} from './helpers'
import { InquiryService } from '@/services/inquiry/inquiry'
import {
  ContactType,
  IncotermCode,
  InquirySortOptionsValue,
  Regions,
  RouteDirectionTypeEnum
} from '@/utils/constants'
import {
  getMaxFromArray,
  getMinFromArray,
  getMinMaxFromArray,
  getPatch
} from '@/utils/global-setting'
import { NewInquiryFormModule, TemplateModule } from '..'

import router from '@/router'
import store from '@/store'
import { ExtraFieldTypesService } from '@/services/settings/extra-field-types'
import { getBaseURL } from '@/utils/cookies'
import { apiOptions } from '@/utils/select-options'
import { InquirySolutionService } from '@/services/inquiry/inquiry-solutions'
import { InquiryExtraFieldService } from '@/services/inquiry/inquiry-extra-field'
import { ShipmentContactService } from '@/services/shipment/contacts'
import { InquiryContactService } from '@/services/inquiry/inquiry-contacts'
import { InquiryAttachmentService } from '@/services/inquiry/inquiry-attachment'
import { CargoReadyToLoadService } from '@/services/settings/cargo-ready-to-load'
import { ServiceResponse } from '@/services/service-response'

type PostInquiryPayload = {
  inquiryPayload: unknown
  requestMethod: string
}

@Module({ dynamic: true, store, name: 'inquiry-solutions', namespaced: true })
class InquirySolutions extends VuexModule {
  private inquirySolutions: IInquirySolution[] = []
  private clonedSolutions: IInquirySolution[] = []
  private postedInquiry!: Partial<IInquiry>
  private showSolutions = false
  private selectedSolution = {}
  private showContactForm = false
  private blurSolutions = false
  private blurInquiryForm = false
  private blurContactForm = false
  private showInquiryDetails = false
  private fastestIndex!: number
  private slowestIndex!: number
  private freightModes!: string[]
  private shipperContactInfo!: Partial<IInquiryContact>
  private consigneeContactInfo!: Partial<IInquiryContact>
  private extraFields: IInquiryExtraFields[] = []
  private extraFieldTypes: IExtraFieldType[] = []
  private inquiryAttachments: IShipmentAttachment[] = []
  private cargoReadyToLoadTimePeriod: ICargoReadyToLoadTimePeriod[] = []
  private inquiryService = new InquiryService()
  private inquirySolutionService = new InquirySolutionService()
  private extraFieldTypeService = new ExtraFieldTypesService()
  private inquiryExtraFieldService = new InquiryExtraFieldService()
  private cargoReadyToLoadService = new CargoReadyToLoadService()
  private inquiryContactService = new InquiryContactService()
  private inquiryAttachmentService = new InquiryAttachmentService()
  private isContactFormValid = true
  private sort: string | null = InquirySortOptionsValue.LowestFirst
  private filters: any = {}
  private containerQty = 0
  private itemQty = 0
  private mostExpensiveSolution = 0
  private sortingOptions = [
    {
      value: InquirySortOptionsValue.FastestFirst,
      text: 'Time - From fastest'
    },
    { value: InquirySortOptionsValue.LowestFirst, text: 'Fare - Lowest first' }
  ]

  @Mutation
  SET_CONTAINER_QTY(postedInquiry: Partial<IInquiry>): void {
    this.containerQty =
      postedInquiry && postedInquiry.containers
        ? postedInquiry.containers.reduce(
            (prev, curr) => prev + curr.quantity,
            0
          )
        : 0
  }

  @Mutation
  SET_ITEM_QTY(postedInquiry: Partial<IInquiry>): void {
    this.itemQty =
      postedInquiry && postedInquiry.items
        ? postedInquiry.items.reduce((prev, curr) => prev + curr.quantity, 0)
        : 0
  }

  @Mutation
  SET_CONTACT_FORM_STATUS(status): void {
    this.isContactFormValid = status
  }

  @Mutation
  SET_SOLUTIONS(solutions: IInquirySolution[]): void {
    this.inquirySolutions = solutions
  }

  @Mutation
  SET_MOST_EXPENSIVE_SOLUTION(solutions: Array<IInquirySolution>) {
    if (solutions && solutions.length > 0) {
      this.mostExpensiveSolution = getMaxFromArray(solutions, 'sellTotal')
    } else {
      this.mostExpensiveSolution = 0
    }
  }

  @Mutation
  SET_CLONED_SOLUTIONS(solutions: IInquirySolution[]): void {
    this.clonedSolutions = solutions
  }
  @Mutation
  SET_POSTED_INQUIRY(postedInquiry): void {
    this.postedInquiry = postedInquiry
    // setting incoterm value which is present in new inquiry store
    NewInquiryFormModule.setIncoterm(postedInquiry.incotermId || '')
    NewInquiryFormModule.setIncotermAddress({
      address: postedInquiry.incotermAddress || ({} as IAddress),
      googleMapPlaceResult: {} as google.maps.places.PlaceResult
    })
  }

  @Mutation
  SET_SHOW_SOLUTIONS(show: boolean): void {
    this.showSolutions = show
  }

  @Mutation
  SET_BLUR_INQUIRY_FORM(blur: boolean): void {
    this.blurInquiryForm = blur
  }

  @Mutation
  TOGGLE_SHOW_DETAILS(): void {
    this.blurContactForm = !this.blurContactForm
    this.showInquiryDetails = !this.showInquiryDetails
  }

  @Mutation
  SET_FREIGHT_MODES(freightModes: string[]) {
    this.freightModes = freightModes
  }

  @Mutation
  SET_SHOW_CONTACT_FORM(open: boolean): void {
    this.showContactForm = open
  }

  @Mutation
  SET_BLUR_SOLUTIONS(blur: boolean): void {
    this.blurSolutions = blur
  }

  @Mutation
  SET_INQUIRY_CONTACTS(contacts: IInquiryContact[]): void {
    const shipper = contacts.find(
      (contact: IInquiryContact) => contact.type === ContactType.Shipper
    )
    const consignee = contacts.find(
      (contact: IInquiryContact) => contact.type === ContactType.Consignee
    )
    if (shipper) this.shipperContactInfo = shipper
    if (consignee) this.consigneeContactInfo = consignee
  }

  @Mutation
  SET_INQUIRY_EXTRA_FIELDS(fields: IInquiryExtraFields[]): void {
    this.extraFields = fields
  }

  @Mutation
  SET_EXTRA_FIELD_TYPES(fieldTypes: IExtraFieldType[]): void {
    this.extraFieldTypes = fieldTypes
  }

  @Mutation
  SET_INQUIRY_ATTACHMENTS(attachments: IShipmentAttachment[]): void {
    this.inquiryAttachments = uniqBy(attachments, 'id')
  }

  @Mutation
  SET_CARGO_READY_TO_LOAD_TIME_PERIOD(
    cargoReadyToLoadTimePeriod: ICargoReadyToLoadTimePeriod[]
  ): void {
    this.cargoReadyToLoadTimePeriod = [...cargoReadyToLoadTimePeriod]
  }

  @Mutation
  DELETE_ATTACHMENT(attachmentId: string): void {
    this.inquiryAttachments = this.inquiryAttachments.filter(
      attachment => attachment.id !== attachmentId
    )
  }

  @Mutation
  ADD_NEW_ATTACHMENT(attachment: IShipmentAttachment) {
    this.inquiryAttachments = [...this.inquiryAttachments, attachment]
  }

  @Mutation
  UPDATE_ATTACHMENT(updatedAttachment: IShipmentAttachment) {
    this.inquiryAttachments = this.inquiryAttachments.map(att =>
      att.id === updatedAttachment.id
        ? {
            ...att,
            title: updatedAttachment.title
          }
        : att
    )
  }

  @Mutation
  UPDATE_SORT(value: string | null) {
    this.sort = value
  }

  @Mutation UPDATE_FILTER(filters: {}) {
    this.filters = filters
  }

  @Mutation
  ADD_PREMIUM_IN_SORTING_OPTIONS() {
    this.sortingOptions.push({
      value: InquirySortOptionsValue.RecommendedFirst,
      text: 'Recommended'
    })
  }

  @Mutation
  RESET_STATE() {
    this.sortingOptions = [
      {
        value: InquirySortOptionsValue.FastestFirst,
        text: 'Time - From fastest'
      },
      {
        value: InquirySortOptionsValue.LowestFirst,
        text: 'Fare - Lowest first'
      }
    ]
    this.sort = InquirySortOptionsValue.LowestFirst
    this.filters = {}
    this.inquirySolutions = []
    this.clonedSolutions = []
    this.postedInquiry = {}
    this.showSolutions = false
    this.selectedSolution = {}
    this.showContactForm = false
    this.blurSolutions = false
    this.blurInquiryForm = false
    this.blurContactForm = false
    this.showInquiryDetails = false
    this.fastestIndex = 0
    this.slowestIndex = 0
    this.freightModes = []
    this.shipperContactInfo = {}
    this.consigneeContactInfo = {}
    this.extraFields = []
    this.extraFieldTypes = []
    this.inquiryAttachments = []
    this.cargoReadyToLoadTimePeriod = []
    this.isContactFormValid = true
  }

  @Action
  resetState() {
    this.RESET_STATE()
  }

  @Action({ rawError: true })
  async postInquiryAndGetSolutions({
    inquiryPayload,
    requestMethod
  }: PostInquiryPayload): Promise<ServiceResponse<IInquiry>> {
    this.SET_MOST_EXPENSIVE_SOLUTION([])
    this.SET_SOLUTIONS([])
    const response = await this.inquiryService.postInquiry<IInquiry>(
      inquiryPayload,
      requestMethod
    )
    const status = response.status
    let inquiry = response.data
    if (!status) {
      return response
    }

    const baseURL = getBaseURL()
    if (baseURL) {
      const incoterm: IIncoterm[] = store.getters['incoterms/getIncoterms']
      const region = apiOptions().find(x => x.value.api === baseURL)
        ?.text as Regions
      if (region === Regions.Latvia) {
        if (
          inquiry.routeDirectionType?.toString() ===
            RouteDirectionTypeEnum[RouteDirectionTypeEnum.Export] &&
          !inquiry.isIncotermSetManually
        ) {
          inquiry = await this.updateInquiryIncoterm({
            inquiry: inquiry,
            incoterm: incoterm,
            data: {
              incotermCode: IncotermCode.Cpt,
              address: inquiry.destinationAddress
            }
          })
        }
      } else if (region === Regions.Lithuania || region === Regions.PreLive2) {
        if (
          (inquiry.routeDirectionType?.toString() ===
            RouteDirectionTypeEnum[RouteDirectionTypeEnum.Import] ||
            inquiry.routeDirectionType?.toString() ===
              RouteDirectionTypeEnum[RouteDirectionTypeEnum.ThirdParty]) &&
          !inquiry.isIncotermSetManually
        ) {
          inquiry = await this.updateInquiryIncoterm({
            inquiry: inquiry,
            incoterm: incoterm,
            data: {
              incotermCode: IncotermCode.Fob,
              address: inquiry.originAddress
            }
          })
        } else if (
          inquiry.routeDirectionType?.toString() ===
            RouteDirectionTypeEnum[RouteDirectionTypeEnum.Export] &&
          !inquiry.isIncotermSetManually
        ) {
          inquiry = await this.updateInquiryIncoterm({
            inquiry: inquiry,
            incoterm: incoterm,
            data: {
              incotermCode: IncotermCode.Cpt,
              address: inquiry.destinationAddress
            }
          })
        }
      }
    }

    NewInquiryFormModule.SET_INQUIRY(inquiry)
    await this.publishInquiryAndGetSolution(inquiry)
    TemplateModule.toggleLoader(false)
    return response
  }

  @Action({ rawError: true })
  private async updateInquiryIncoterm(obj: {
    inquiry: IInquiry
    incoterm: IIncoterm[]
    data: { incotermCode: string; address: IAddress }
  }) {
    const modifyInquiry = cloneDeep(obj.inquiry)
    modifyInquiry.incotermId = obj.incoterm.find(
      x => x.code === obj.data.incotermCode
    )?.id
    modifyInquiry.incotermCode = obj.data.incotermCode
    modifyInquiry.incotermAddress = obj.data.address
    const path = getPatch(obj.inquiry, modifyInquiry)
    const { data: updatedInquiry } = await this.inquiryService.patchInquiry<
      IInquiry
    >(obj.inquiry.id, path)
    obj.inquiry = { ...updatedInquiry }
    return obj.inquiry
  }

  @Action({ rawError: true })
  private async publishInquiryAndGetSolution(inquiry: IInquiry) {
    if (inquiry.id) {
      let {
        // eslint-disable-next-line prefer-const
        status: solutionsStatus,
        data: solutions
      } = await this.inquirySolutionService.createInquirySolutions<
        IInquirySolution[]
      >(inquiry.id)
      if (!solutionsStatus) {
        return
      }
      solutions = setLowestPriceSolution(solutions)
      solutions = setFastestAndSlowestSolution(solutions)
      solutions = setFreightPremium(solutions)
      const freightModes = getSolutionsFreightModes(solutions)
      // will fix it just a quick fix, it city is null replace it with county
      solutions = await attachRoutesToSolutions(
        solutions,
        inquiry.originAddress.city
          ? inquiry.originAddress.city
          : inquiry.originAddress.county,
        inquiry.destinationAddress.city
          ? inquiry.destinationAddress.city
          : inquiry.destinationAddress.county
      )
      this.SET_POSTED_INQUIRY(inquiry)
      this.SET_CONTAINER_QTY(inquiry)
      this.SET_ITEM_QTY(inquiry)
      this.SET_MOST_EXPENSIVE_SOLUTION(solutions)
      this.SET_SOLUTIONS(solutions)
      this.SET_CLONED_SOLUTIONS(solutions)
      this.SET_FREIGHT_MODES(freightModes)
      const hasRates = this.inquirySolutions.find(x => x.sellTotal > 0)
      if (hasRates && !inquiry.isCargoHazardous) {
        this.SET_BLUR_INQUIRY_FORM(true)
      }
      this.sortSolutions({ sortByParameter: this.sort, applyFilter: false })
      this.setDefaultPremiumSolutionAtFirstPlace()
    }
  }

  @Action({ rawError: true })
  async updateInquiry(payload: IInquiry) {
    this.SET_MOST_EXPENSIVE_SOLUTION([])
    this.SET_SOLUTIONS([])
    TemplateModule.toggleLoader(true)
    const { status, data: inquiry } = await this.inquiryService.updateInquiry<
      IInquiry
    >(payload)
    if (!status) {
      return
    }
    this.SET_POSTED_INQUIRY(inquiry)
    this.SET_CONTAINER_QTY(inquiry)
    this.SET_ITEM_QTY(inquiry)
    TemplateModule.toggleLoader(false)
  }

  @Action({ rawError: false })
  async fetchExtraFieldTypes() {
    const {
      status,
      data: extraFieldTypes
    } = await this.extraFieldTypeService.getExtraFieldType<IExtraFieldType[]>()
    if (status) {
      this.SET_EXTRA_FIELD_TYPES(extraFieldTypes)
    }
  }

  @Action({ rawError: true })
  async fetchExtraFieldsAndTypes(inquiryId: string) {
    const {
      data: extraFieldTypes
    } = await this.extraFieldTypeService.getExtraFieldType<IExtraFieldType[]>()
    this.SET_EXTRA_FIELD_TYPES(extraFieldTypes)

    let {
      data: extraFields
    } = await this.inquiryExtraFieldService.getInquiryExtraFields<
      IInquiryExtraFields[]
    >(inquiryId)
    if (extraFields.length === 0) {
      const fieldType = getDefaultExtraFieldType(this.getExtraFieldTypes)
      extraFields = [
        {
          extraFieldTypeId: fieldType.id,
          extraFieldTypeName: fieldType.name,
          id: fieldType.id,
          value: '',
          new: true
        }
      ]
    }

    this.SET_INQUIRY_EXTRA_FIELDS(extraFields)
  }

  @Action({ rawError: true })
  async fetchCargoReadyToLoadTimePeriod() {
    const {
      status,
      data
    } = await this.cargoReadyToLoadService.getCargoReadyToLoadTimePeriod<
      Array<ICargoReadyToLoadTimePeriod>
    >()
    if (status && data.length > 0) {
      this.SET_CARGO_READY_TO_LOAD_TIME_PERIOD(data)
    }
  }

  @Action({ rawError: true })
  async fetchInquiryExtraFields(inquiryId: string) {
    const {
      status,
      data: extraFields
    } = await this.inquiryExtraFieldService.getInquiryExtraFields<
      IInquiryExtraFields[]
    >(inquiryId)
    if (status) this.SET_INQUIRY_EXTRA_FIELDS(extraFields)
  }

  @Action({ rawError: true })
  async fetchInquiryContacts(inquiryId: string) {
    const {
      status,
      data: contacts
    } = await this.inquiryContactService.getInquiryContacts<IInquiryContact[]>(
      inquiryId
    )
    if (status) {
      this.SET_INQUIRY_CONTACTS(contacts)
    }
  }

  @Action({ rawError: true })
  async fillInquiryContactsByShipmentId(shipmentId: string) {
    const {
      status,
      data
    } = await new ShipmentContactService().getShipmentContacts<
      IInquiryContact[]
    >(shipmentId)
    if (status) {
      this.SET_INQUIRY_CONTACTS(data)
    }
  }

  @Action({ rawError: true })
  async searchSolutions(inquiry: IInquiry): Promise<void> {
    if (inquiry.id) {
      let {
        // eslint-disable-next-line prefer-const
        status,
        data: solutions
      } = await this.inquirySolutionService.getInquirySolutions<
        IInquirySolution[]
      >(inquiry.id)
      if (status) {
        solutions = setLowestPriceSolution(solutions)
        solutions = setFastestAndSlowestSolution(solutions)
        solutions = setFreightPremium(solutions)
        const freightModes = getSolutionsFreightModes(solutions)
        // will fix it just a quick fix, it city is null replace it with county
        solutions = await attachRoutesToSolutions(
          solutions,
          inquiry.originAddress.city
            ? inquiry.originAddress.city
            : inquiry.originAddress.county,
          inquiry.destinationAddress.city
            ? inquiry.destinationAddress.city
            : inquiry.destinationAddress.county
        )
        this.SET_POSTED_INQUIRY(inquiry)
        this.SET_CONTAINER_QTY(inquiry)
        this.SET_ITEM_QTY(inquiry)
        this.SET_MOST_EXPENSIVE_SOLUTION(solutions)
        this.SET_SOLUTIONS(solutions)
        this.SET_CLONED_SOLUTIONS(solutions)
        this.SET_FREIGHT_MODES(freightModes)
        const hasRates = this.inquirySolutions.find(x => x.sellTotal > 0)
        if (hasRates && !inquiry.isCargoHazardous) {
          this.SET_BLUR_INQUIRY_FORM(true)
        }
        this.SET_SHOW_SOLUTIONS(true)
        this.sortSolutions({ sortByParameter: this.sort, applyFilter: false })
        this.setDefaultPremiumSolutionAtFirstPlace()
      } else {
        router.push('/new-inquiry')
      }
    }
  }

  @Action({ rawError: true })
  sortSolutions({
    sortByParameter,
    applyFilter
  }: {
    sortByParameter: string | null
    applyFilter: boolean
  }) {
    if (sortByParameter) {
      const [sortingProperty, sortingOrder] = sortByParameter.split('-')
      /**
       * Filter out those who have sell price zero after that find the lowest one
       */
      const solutionWithZeroSellTotal = this.clonedSolutions.filter(
        x => x.sellTotal === 0
      )

      const solutionWithOutZeroSellTotal = this.clonedSolutions.filter(
        x => x.sellTotal
      )

      let sortedSolutions: Array<IInquirySolution> = []

      if (sortByParameter === InquirySortOptionsValue.LowestFirst) {
        sortedSolutions = orderBy(
          solutionWithOutZeroSellTotal,
          [sortingProperty],
          [sortingOrder as boolean | 'asc' | 'desc']
        )
      } else if (sortByParameter === InquirySortOptionsValue.RecommendedFirst) {
        sortedSolutions = orderByRecommended(solutionWithOutZeroSellTotal)
      } else {
        sortedSolutions = setFreightPremium(
          setFastestAndSlowestSolution(solutionWithOutZeroSellTotal)
        )
      }
      //can be possible that the data is already filtered
      let concatSolution = [...sortedSolutions]
      if (applyFilter) {
        concatSolution = sortedSolutions.filter(
          (solution: IInquirySolution) => {
            return (
              filterByPrice(this.filters, solution, sortedSolutions) &&
              filterByTransitDays(this.filters, solution, sortedSolutions) &&
              filterByFreightModes(this.filters, solution)
            )
          }
        )
      }
      concatSolution = [...concatSolution, ...solutionWithZeroSellTotal]
      this.SET_MOST_EXPENSIVE_SOLUTION(concatSolution)
      this.SET_SOLUTIONS(concatSolution)
    } else {
      this.SET_SOLUTIONS(this.clonedSolutions)
      //can be possible that the data is already filtered
      const concatSolution = this.clonedSolutions.filter(
        (solution: IInquirySolution) => {
          return (
            filterByPrice(this.filters, solution, this.clonedSolutions) &&
            filterByTransitDays(this.filters, solution, this.clonedSolutions) &&
            filterByFreightModes(this.filters, solution)
          )
        }
      )
      this.SET_MOST_EXPENSIVE_SOLUTION(concatSolution)
      this.SET_SOLUTIONS(concatSolution)
    }
  }

  @Action({ rawError: true })
  filterSolutions(filterParams: IInquiryFilters): void {
    const filteredSolutions = this.clonedSolutions.filter(
      (solution: IInquirySolution) => {
        return (
          filterByPrice(filterParams, solution, this.clonedSolutions) &&
          filterByTransitDays(filterParams, solution, this.clonedSolutions) &&
          filterByFreightModes(filterParams, solution)
        )
      }
    )
    const solutionsWithNoPrice = this.clonedSolutions.filter(
      solution => solution.sellTotal === 0
    )
    const concatSolution = [...filteredSolutions, ...solutionsWithNoPrice]
    this.SET_MOST_EXPENSIVE_SOLUTION(this.clonedSolutions)
    this.SET_SOLUTIONS(concatSolution)
    // For sorting (sort after filteration)
    if (this.sort) {
      const [sortingProperty, sortingOrder] = this.sort.split('-')

      const solutionWithOutZeroSellTotal = concatSolution.filter(
        x => x.sellTotal
      )

      let sortedSolutions = orderBy(
        solutionWithOutZeroSellTotal,
        [sortingProperty],
        [sortingOrder as boolean | 'asc' | 'desc']
      )
      sortedSolutions = [...sortedSolutions, ...solutionsWithNoPrice]
      this.SET_SOLUTIONS(sortedSolutions)
    }
  }

  @Action
  async fetchExtraFields(inquiryId: string): Promise<void> {
    const response = await this.inquiryExtraFieldService.getInquiryExtraFields<
      IInquiryExtraFields[]
    >(inquiryId)
    if (response.status) {
      if (response.data.length === 0) {
        const fieldType = getDefaultExtraFieldType(this.getExtraFieldTypes)
        response.data = [
          {
            extraFieldTypeId: fieldType.id,
            extraFieldTypeName: fieldType.name,
            id: fieldType.id,
            value: '',
            new: true
          }
        ]
      }
      this.SET_INQUIRY_EXTRA_FIELDS(response.data)
    }
  }

  @Action
  async fetchAttachments(inquiryId: string): Promise<void> {
    this.SET_INQUIRY_ATTACHMENTS([])
    const {
      data: attachments
    } = await this.inquiryAttachmentService.getInquiryAttachments<
      IShipmentAttachment[]
    >(inquiryId)
    this.SET_INQUIRY_ATTACHMENTS(attachments)
  }

  @Action
  async setDefaultPremiumSolutionAtFirstPlace() {
    // sorted it in ascending order
    const premiumSortedSolutions = getPremiumSortedSolutions(
      this.clonedSolutions
    )

    if (premiumSortedSolutions && premiumSortedSolutions.length > 0) {
      const orderedSolutions = [
        ...premiumSortedSolutions,
        ...this.clonedSolutions.filter(x => !x.isPremium)
      ]
      this.SET_SOLUTIONS(orderedSolutions)
      this.ADD_PREMIUM_IN_SORTING_OPTIONS()
      this.UPDATE_SORT(InquirySortOptionsValue.RecommendedFirst)
    }
  }

  @Action
  async addAttachment(attachment) {
    this.ADD_NEW_ATTACHMENT(attachment)
  }

  @Action
  async updateAttachment(attachment) {
    this.UPDATE_ATTACHMENT(attachment)
  }

  @Action
  async deleteAttachment({
    inquiryId,
    attachmentId
  }: {
    inquiryId: string
    attachmentId: string
  }): Promise<void> {
    try {
      await this.inquiryAttachmentService.deleteInquiryAttachment<void>(
        inquiryId,
        attachmentId
      )
      this.DELETE_ATTACHMENT(attachmentId)
    } catch (err) {
      console.log(err)
    }
  }

  @Action
  toggleBlurInquiryForm(blur: boolean): void {
    this.SET_BLUR_INQUIRY_FORM(blur)
  }

  @Action
  toggleBlurInquirySolutions(blur: boolean): void {
    this.SET_BLUR_SOLUTIONS(blur)
  }

  @Action
  toggleShowInquiry(show: boolean): void {
    this.SET_SHOW_SOLUTIONS(show)
  }

  @Action
  toggleShowInquiryDetails() {
    this.TOGGLE_SHOW_DETAILS()
  }

  @Action
  toggleContactFormStatus(status: boolean) {
    this.SET_CONTACT_FORM_STATUS(status)
  }

  get getInquirySolutions(): IInquirySolution[] {
    return this.inquirySolutions
  }

  get getPostedInquiry(): Partial<IInquiry> {
    return this.postedInquiry
  }

  get totalInquirySolutions(): number {
    return this.inquirySolutions.length
  }

  get getShowSolutions(): boolean {
    return this.showSolutions
  }

  get getBlurInquiryForm(): boolean {
    return this.blurInquiryForm
  }

  get getPriceRange(): [number, number] {
    return getMinMaxFromArray(this.inquirySolutions, 'sellTotal')
  }

  get getTransitDaysRange(): [number, number] {
    return [
      getMinFromArray(this.inquirySolutions, 'transitTimePrognosedMin'),
      getMaxFromArray(this.inquirySolutions, 'transitTimePrognosedMdn')
    ]
  }

  get getMostExpensiveSolution(): number {
    return this.mostExpensiveSolution
  }

  get getSlowestIndex(): number {
    return this.slowestIndex
  }

  get getFastestIndex(): number {
    return this.fastestIndex
  }

  get getShipperInfo(): Partial<IInquiryContact> {
    return this.shipperContactInfo
  }

  get getConsigneeInfo(): Partial<IInquiryContact> {
    return this.consigneeContactInfo
  }

  get getInquiryExtraFields(): IInquiryExtraFields[] {
    return this.extraFields
  }

  get getExtraFieldTypes(): IExtraFieldType[] {
    return this.extraFieldTypes
  }

  get getCompanyName(): string {
    return this.postedInquiry.companyName || ''
  }

  get getContactFormStatus(): boolean {
    return this.isContactFormValid
  }

  get getShipperCompanyCustomerReferenceId(): string | null | undefined {
    return this.shipperContactInfo.companyCustomerReferenceId
  }

  get getConsigneeCompanyCustomerReferenceId(): string | null | undefined {
    return this.consigneeContactInfo.companyCustomerReferenceId
  }

  get getPremiumSortedSolutions(): Array<IInquirySolution> {
    return getPremiumSortedSolutions(this.clonedSolutions)
  }

  get getSortingOptions() {
    return this.sortingOptions
  }
}

export const InquirySolutionsModule = getModule(InquirySolutions)
