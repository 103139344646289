<template>
  <div class="header">
    <app-mobile-nav></app-mobile-nav>
    <b-link class="mobile_logo" to="/">
      <img src="../../assets/images/menu-logo.svg" />
    </b-link>

    <div class="container">
      <!-- Home view -->
      <app-home-header
        v-if="$route.name === 'Home' || $route.name === 'My Shipment'"
      />

      <!-- Invoices view -->
      <app-invoice-header v-if="$route.name === 'Invoices'" />

      <!-- Settings view -->
      <app-settings-header v-if="isRouteSettings" />
      <!-- New inquery view hearder -->
      <app-new-inquery-header
        v-if="
          $route.name === 'NewInquiry' ||
            $route.name === 'CreateInquiry' ||
            $route.name === 'UpdateInquiry' ||
            $route.name === 'ShipmentInquiry'
        "
      />

      <!-- Information view -->
      <app-information-header v-if="isRouteInformation" />

      <!-- New inquery view hearder -->
      <app-inquiry-header v-if="$route.name === 'Inquiry'" />

      <!-- New inquery view hearder -->
      <app-shipment-details
        v-if="
          $route.name === 'Details' ||
            $route.name === 'Messages' ||
            $route.name === 'Attachments' ||
            $route.name === 'Quotes Invoices'
        "
      />
      <!-- Waiting for approval -->
      <app-waiting-for-approval v-if="$route.name === 'WaitingForApproval'" />

      <h1
        v-if="
          $route.name === 'Company Registration' ||
            $route.name === 'Confirm Email'
        "
        class="pageTitle font-light text-gray-darker"
      >
        Sign Up
      </h1>

      <app-terms-and-condition
        v-if="$route.name === 'Information'"
      ></app-terms-and-condition>

      <app-my-report-header
        v-if="$route.name === 'My Reports'"
      ></app-my-report-header>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import InvoiceHeader from './InvoiceHeader.vue'
import NewInqueryHeader from './NewInquiryHeader.vue'
import InquiryHeader from './InquiryHeader.vue'
import InformationHeader from './InformationHeader.vue'
import SettingsHeader from './SettingsHeader.vue'
import ShipmentDetails from './ShipmentDetails.vue'
import HomeHeader from './HomeHeader.vue'
import MyReportHeader from './MyReportHeader.vue'
import WaitingForApproval from './WaitingForApprovalHeader.vue'
import TermsAndConditionHeader from './TermsAndConditionHeader.vue'
import { mixins } from 'vue-class-component'
import { MobileDetectingMixin } from '@/mixins'
import MobileNav from '../nav/MobileNav.vue'

@Component({
  components: {
    appInvoiceHeader: InvoiceHeader,
    appInformationHeader: InformationHeader,
    appSettingsHeader: SettingsHeader,
    appNewInqueryHeader: NewInqueryHeader,
    appInquiryHeader: InquiryHeader,
    appHomeHeader: HomeHeader,
    appShipmentDetails: ShipmentDetails,
    appTermsAndCondition: TermsAndConditionHeader,
    appMobileNav: MobileNav,
    appWaitingForApproval: WaitingForApproval,
    appMyReportHeader: MyReportHeader
  }
})
export default class Header extends mixins(MobileDetectingMixin) {
  public active = false

  get isRouteInformation() {
    const path = this.$route.path.match(
      /information/gi
    ) as RegExpMatchArray | null
    const isPathCorrect = path === null ? false : true
    return isPathCorrect
  }

  get isRouteSettings() {
    const path = this.$route.path.match(/settings/gi) as RegExpMatchArray | null
    const isPathCorrect = path === null ? false : true
    return isPathCorrect
  }
}
</script>
